/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving and saving a drugDispensation.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';



/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDrugDispensationState {
    drugDispensation: Dtos.DrugDispensation | null;
    loadState: IRequestState<ResponseStatus>;
    saveState: IRequestState<ResponseStatus>;
    deleteState: IRequestState<ResponseStatus>;
};

export interface IDrugDispensationStore {
    drugDispensation: IDrugDispensationState;
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

const initialDrugDispensationState: IDrugDispensationState = {
    drugDispensation: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    },
    deleteState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DrugDispensationReducer extends ImmerReducer<IDrugDispensationState> {
    public loadById(id: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(drugDispensation?: Dtos.DrugDispensation) {
        this.draftState.drugDispensation = drugDispensation ?? null;
        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus: ResponseStatus) {
        this.draftState.drugDispensation = null;
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public save(form: Dtos.DrugDispensation) {
        this.draftState.saveState = {
            state: RequestState.Pending
        };
    }

    public saveSuccess(drugDispensation?: Dtos.DrugDispensation) {
        this.draftState.drugDispensation = drugDispensation ?? null;
        this.draftState.saveState = {
            state: RequestState.Success
        };
    }

    public saveFailure(responseStatus: ResponseStatus) {
        this.draftState.drugDispensation = null;
        this.draftState.saveState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDrugDispensationState };
    }

    public deleteDispensation(drugDispensation: Dtos.DrugDispensation) {
        this.draftState.deleteState = {
            state: RequestState.Pending
        };
    }

    public deleteSuccess(drugDispensation?: Dtos.DrugDispensation) {
        this.draftState.drugDispensation = null;
        this.draftState.deleteState = {
            state: RequestState.Success
        };
    }

    public deleteFailure(responseStatus: ResponseStatus) {
        this.draftState.deleteState = {
            state: RequestState.Failure,
            responseStatus
        };
    }
};

export const drugDispensationActions = createActionCreators(DrugDispensationReducer);
export const drugDispensationReducer = createReducerFunction(DrugDispensationReducer, initialDrugDispensationState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDrugDispensationApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.GetDrugDispensationById({
            id
        }));
    },
    save: (drugDispensation?: Dtos.DrugDispensation) => {
        return client.post(new Dtos.SetDrugDispensation({
            drugDispensation
        }));
    },
    deleteDispensation: (drugDispensation?: Dtos.DrugDispensation) => {
        return client.post(new Dtos.DeleteDrugDispensation({
            drugDispensation
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDrugDispensationLogic = (api: ReturnType<typeof createDrugDispensationApi>) => {
    const logic = {
        loadById: createLogic<IDrugDispensationState, {}, undefined, string, ReturnType<typeof drugDispensationActions.loadById>>({
            type: drugDispensationActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload;

                try {
                    const response = await api.loadById(id);

                    dispatch(drugDispensationActions.loadSuccess(
                        response.drugDispensation
                    ));
                }
                catch (error: any) {
                    dispatch(drugDispensationActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        save: createLogic<IDrugDispensationState, {}, undefined, string, ReturnType<typeof drugDispensationActions.save>>({
            type: drugDispensationActions.save.type,
            process: async ({ action }, dispatch, done) => {
                const drugDispensation = action.payload;

                try {
                    const response = await api.save(drugDispensation);

                    dispatch(drugDispensationActions.saveSuccess(
                        response.drugDispensation
                    ));
                }
                catch (error: any) {
                    dispatch(drugDispensationActions.saveFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        deleteDispensation: createLogic<IDrugDispensationState, {}, undefined, string, ReturnType<typeof drugDispensationActions.deleteDispensation>>({
            type: drugDispensationActions.deleteDispensation.type,
            process: async ({ action }, dispatch, done) => {
                const form = action.payload;
                try {
                    const response = await api.deleteDispensation(form);
                    dispatch(drugDispensationActions.deleteSuccess(
                        form
                    ));
                }
                catch (error: any) {
                    dispatch(drugDispensationActions.deleteFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadById,
        logic.save,
        logic.deleteDispensation
    ];
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDrugDispensationSelector: TypedUseSelectorHook<IDrugDispensationStore> = useSelector;

export const drugDispensationSelectors = {
    selectDrugDispensation: (state: IDrugDispensationStore) => state.drugDispensation.drugDispensation,
    selectLoadState: (state: IDrugDispensationStore) => state.drugDispensation.loadState,
    selectSaveState: (state: IDrugDispensationStore) => state.drugDispensation.saveState,
    selectDeleteState: (state: IDrugDispensationStore) => state.drugDispensation.deleteState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDrugDispensationReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDrugDispensationApi(client);

    const logic = createDrugDispensationLogic(api);

    reducerRegistry.register('drugDispensation', drugDispensationReducer as Reducer, logic as any);
};

export default registerDrugDispensationReducer;