import * as Dtos from '../api/dtos';

export interface IDrugShipmentDepotBatchSummary {
    depotBatchId: number;
    drugUnits: number;
    drugUnitsUsed: number;
    drugUnitsDamaged: number;
    drugUnitsDestroyed: number;
    drugUnitsLost: number;
    drugUnitsQuarantined: number;
    drugUnitsTransferred: number;
    drugRemaining: number;
    batchNo: string;
};

export const getDrugUnavailable = (drugShipmentDepotBatch: Dtos.DrugShipmentDepotBatch): number => {

    return (drugShipmentDepotBatch.drugUnitsDamaged!! +
        drugShipmentDepotBatch.drugUnitsDestroyed!! +
        drugShipmentDepotBatch.drugUnitsLost!! +
        drugShipmentDepotBatch.drugUnitsQuarantined!! +
        drugShipmentDepotBatch.drugUnitsUsed!! +
        drugShipmentDepotBatch.drugUnitsTransferred!!);
};

export const getDrugRemaining = (drugShipmentDepotBatch: Dtos.DrugShipmentDepotBatch): number => {
    return drugShipmentDepotBatch.drugUnits!! - getDrugUnavailable(drugShipmentDepotBatch);
};

export const getDrugQuarantined = (drugShipmentDepotBatch: Dtos.DrugShipmentDepotBatch): number => {
    return drugShipmentDepotBatch.drugUnitsQuarantined ?? 0;
};

export const getDrugDestroyed = (drugShipmentDepotBatch: Dtos.DrugShipmentDepotBatch): number => {
    return drugShipmentDepotBatch.drugUnitsDestroyed ?? 0;
};

export const createDrugShipmentDepotBatchSummary = (drugShipmentDepotBatch: Dtos.DrugShipmentDepotBatch): IDrugShipmentDepotBatchSummary => {
    const drugShipmentDepotBatchSummary: IDrugShipmentDepotBatchSummary = Object.assign({},
        {
            depotBatchId: drugShipmentDepotBatch.depotBatchId!!,
            drugUnits: drugShipmentDepotBatch.drugUnits!!,
            drugUnitsUsed: drugShipmentDepotBatch.drugUnitsUsed!!,
            drugUnitsDamaged: drugShipmentDepotBatch.drugUnitsDamaged!!,
            drugUnitsDestroyed: drugShipmentDepotBatch.drugUnitsDestroyed!!,
            drugUnitsLost: drugShipmentDepotBatch.drugUnitsLost!!,
            drugUnitsQuarantined: drugShipmentDepotBatch.drugUnitsQuarantined!!,
            drugUnitsTransferred: drugShipmentDepotBatch.drugUnitsTransferred!!,
            drugRemaining: drugShipmentDepotBatch.drugRemaining!!,
            batchNo: drugShipmentDepotBatch.depotBatch.batch.batchNo!!
        });

    drugShipmentDepotBatchSummary.drugRemaining = getDrugRemaining(drugShipmentDepotBatch);

    return drugShipmentDepotBatchSummary;
}

export const sumDrugShipmentDepotBatches = (firstDrugShipmentDepotBatch: IDrugShipmentDepotBatchSummary,
    secondDrugShipmentDepotBatch: IDrugShipmentDepotBatchSummary): IDrugShipmentDepotBatchSummary => {
    firstDrugShipmentDepotBatch.drugUnits += secondDrugShipmentDepotBatch.drugUnits;
    firstDrugShipmentDepotBatch.drugUnitsUsed += secondDrugShipmentDepotBatch.drugUnitsUsed;
    firstDrugShipmentDepotBatch.drugUnitsDamaged += secondDrugShipmentDepotBatch.drugUnitsDamaged;
    firstDrugShipmentDepotBatch.drugUnitsDestroyed += secondDrugShipmentDepotBatch.drugUnitsDestroyed;
    firstDrugShipmentDepotBatch.drugUnitsLost += secondDrugShipmentDepotBatch.drugUnitsLost;
    firstDrugShipmentDepotBatch.drugUnitsQuarantined += secondDrugShipmentDepotBatch.drugUnitsQuarantined;
    firstDrugShipmentDepotBatch.drugUnitsTransferred += secondDrugShipmentDepotBatch.drugUnitsTransferred;
    firstDrugShipmentDepotBatch.drugRemaining += secondDrugShipmentDepotBatch.drugRemaining;

    return firstDrugShipmentDepotBatch;
};