/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains types to help create and mutate function types.
 * --------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import pluralize from 'pluralize';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
    asyncDebounce,
    IFormContext,
    IFormState,
    useSnackbar,
    InstitutionContext,
    IValidationError,
    ValidationErrorType,
    IFormValidationError,
    OnlinePatientManagementContext,
    pascalToCameCasePropertyPath,
    SnackbarVariant,
    IDtoClass,
    PatientsContext,
    IFormActions
} from '@ngt/opms';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/

import * as Dtos from '../api/dtos';

import { ScreeningLog } from '../api/screeningLog';
import ScreeningLogContext from '../context/ScreeningLogContext';
import { useScreeningLogs } from './useScreeningLogs';
import { fromPairs } from 'lodash-es';
import { DateTime } from 'luxon';
import { IScreeningLogFormAndSubmitType } from '../components/ScreeningLogDialog';

/*
* ---------------------------------------------------------------------------------
* Interfaces
* ---------------------------------------------------------------------------------
*/

export interface IUseScreeningLogFormOptions<TScreeningLog extends ScreeningLog = ScreeningLog> {
    formType?: IDtoClass<TScreeningLog>;
    afterFormSave?: (screeningLog?: TScreeningLog | null, form?: TScreeningLog | null) => void;
    onCancel?: () => void;
    readOnly?: boolean;
    createSuccessMessage?: (form: IScreeningLogFormAndSubmitType) => void;
}

const errorVariantMapping: Record<ValidationErrorType, SnackbarVariant> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Ineligible]: 'ineligible',
    [ValidationErrorType.Normal]: 'error',
    [ValidationErrorType.StratificationFailure]: 'stratification-failure',
    [ValidationErrorType.Critical]: 'critical'
}

const errorTextMapping: Record<ValidationErrorType, string> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Ineligible]: 'ineligibility warning',
    [ValidationErrorType.Normal]: 'error',
    [ValidationErrorType.StratificationFailure]: 'stratification failure',
    [ValidationErrorType.Critical]: 'critical error'
}

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/

const useScreeningLogForm = <TScreeningLog extends ScreeningLog = ScreeningLog>({
    formType,
    afterFormSave,
    onCancel,
    readOnly,
    createSuccessMessage
}: IUseScreeningLogFormOptions<TScreeningLog>) => {
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const { institution, loadState: institutionLoadState, actions: institutionActions } = React.useContext(InstitutionContext);

    const { screeningLog, loadState, saveState, actions } = React.useContext(ScreeningLogContext);

    const [screeningLogs, screeningLogsLoadState, screeningLogsActions] = useScreeningLogs<TScreeningLog>(institution?.id, false);

    const { patients, actions: patientsActions } = React.useContext(PatientsContext);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const onFormCancel = React.useCallback(() => {
        if (onCancel) {
            onCancel();
        }

        actions.clear();

    }, [history, institution, onCancel]);

    const onFormSubmit = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TScreeningLog, IValidationError>) => {
        const outcome = formActions?.getFieldValue('outcome');
        const status = outcome == Dtos.ScreeningOutcome.ProceedToRegistration ? Dtos.ScreeningStatus.Successful :
            outcome == Dtos.ScreeningOutcome.ScreenFail ? Dtos.ScreeningStatus.Failed : undefined;

        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'submit');
            formActions?.setFieldValue('status', status);
        }
    }, []);

    const onFormSave = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TScreeningLog, IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'save');
            formActions?.setFieldValue('status', Dtos.ScreeningStatus.Pending);
        }
    }, []);

    const onFormDelete = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TScreeningLog, IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'delete');
        }
    }, []);

    // override the allow submit function so that submit is only blocked when there is a critical error.
    const allowSubmit = React.useCallback(async ({ errors, values }: IFormState<TScreeningLog, IValidationError>, formActions: IFormActions<TScreeningLog, IValidationError>) => {
        if (!errors) { 
            return true;
        }

        return !Object.keys(errors).some(key => errors[key] && errors[key].some(e => e.type === ValidationErrorType.Normal)) || values.status == Dtos.ScreeningStatus.Pending;
    }, []);

    const onValidate = React.useCallback(async (formState: IFormState<TScreeningLog, IValidationError>) => {
        // Send request to server.
        const response = await onlinePatientManagement
            .serviceStackClient
            .post(new Dtos.ScreeningLogPostValidate({ screeningLog: formState.values }));

        // parse errors into a format the form understands.
        const groupErrors = response.validationResult?.errors?.reduce((a: Record<string, IFormValidationError[]>, b: IFormValidationError) => {
            const propertyName = pascalToCameCasePropertyPath(b.property)

            if (!a[propertyName]) {
                a[propertyName] = [];
            }

            a[propertyName].push(b);

            return a;
        }, {}) ?? {};

        return groupErrors;

    }, [onlinePatientManagement.serviceStackClient, pascalToCameCasePropertyPath]);

    // debounce validation functions to reduce calls to the server and associate lag.
    const debouncedValidate = React.useMemo(() => {
        return asyncDebounce(onValidate, 500);
    }, [onValidate]);

    const onFormSubmitValidationFailure = React.useCallback(async ({ errors, values }: IFormState<TScreeningLog, IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Error
                    </AlertTitle>
                    An error occurred while attempting to validate the screening log.
                </>,
                { variant: 'critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e.type === ValidationErrorType.Normal) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Screening Log Not Saved
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and try again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);

    const onFormSubmitFailure = React.useCallback(async ({ values, errors }: IFormState<TScreeningLog, IValidationError>) => {
        const { submitType, ...form } = values as any;

        enqueueSnackbar(
            <>
                <AlertTitle>
                    Error
                </AlertTitle>
                An error occurred while attempting to {submitType} the screening log.
            </>,
            { variant: 'critical' }
        );
    }, []);

    const handleSubmit = React.useCallback(async ({ values, errors }: IFormState<TScreeningLog, IValidationError>) => {
        const { submitType, ...form } = values as any;

        const screeningLogForm = form as TScreeningLog;

        //close dialog
        if (onCancel) {
            onCancel();
        }

        //add institution when creating a new screening log
        if (!values.id) {
            institutionActions.load();
            values.institutionId = institution?.id;
        }

        var result;

        switch(submitType){
            case('save'):
                result = await actions?.asyncSave(values as TScreeningLog);
                break;
            case ('submit'):
                result = await actions?.asyncSave(values as TScreeningLog);
                break;
            case('delete'):
                result = await actions?.asyncDelete(values as TScreeningLog);
                break;
        }

        patientsActions?.load();
        screeningLogsActions?.load();
      
        const allErrors = Object
            .keys(errors)
            .reduce((array: IValidationError[], key: string) => {
                const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                    return [...propertyArray, e]
                }, [])

                return [...array, ...propertyErrors]
            }, [])
            .filter(error => error.type as number > ValidationErrorType.Warning);

        if (submitType == 'save' || submitType == 'submit') {
            const maxErrorType = allErrors.reduce((maxError: ValidationErrorType | undefined, error) => (error.type ?? 0) > (maxError ?? 0) ? error.type : maxError, undefined);

            if (maxErrorType) {
                const scopedErrors = allErrors.filter(e => e.type === maxErrorType);

                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Screening Log Saved
                        </AlertTitle>
                        The screening log was successfully saved but contained {scopedErrors.length} {pluralize(errorTextMapping[maxErrorType], scopedErrors.length)}.
                    </>,
                    { variant: errorVariantMapping[maxErrorType] }
                );
            }
            else {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Screening Log Saved
                        </AlertTitle>
                        {
                            createSuccessMessage ?
                                createSuccessMessage({ submitType: submitType, form: result as unknown as TScreeningLog })
                                :
                                `Screening log was successfully saved.`
                        }

                    </>,
                    { variant: 'success' }
                );
            }
        } else if (submitType == 'delete') {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Screening Log Deleted
                    </AlertTitle>
                    The screening log was successfully deleted.
                </>,
                { variant: 'success' }
            );
        }

        if (submitType === 'save' || submitType === 'submit') {
            if (afterFormSave) {
                afterFormSave(screeningLog as unknown as TScreeningLog, result as unknown as TScreeningLog);
            }
            ("\nAS\n");
        }

    }, [institution, afterFormSave, history, actions?.asyncSave, onCancel, patientsActions])
    
    return {
        handleSubmit,
        onFormCancel,
        onFormSave,
        onFormSubmit,
        onFormDelete,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate: debouncedValidate,
        allowSubmit
    }
}

/*
* ---------------------------------------------------------------------------------
* Default Exports
* ---------------------------------------------------------------------------------
*/
export default useScreeningLogForm;

