/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use drug shipment documents.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    ResponseStatus
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';

import {
    drugShipmentDocumentActions,
    useDrugShipmentDocumentsSelector,
    drugShipmentDocumentSelectors,
    IDrugShipmentDocumentStore
} from '../store/modules/drugshipmentdocuments';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseDrugShipmentDocumentsActions {
    loadByDrugShipmentId: BoundActionCreator<typeof drugShipmentDocumentActions.loadByDrugShipmentId>;
    clear: BoundActionCreator<typeof drugShipmentDocumentActions.clear>;
}

export const useDrugShipmentDocuments = (): [Dtos.DrugShipmentDocument[] | null, IRequestState<ResponseStatus>, IUseDrugShipmentDocumentsActions] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {

        const loadByDrugShipmentId = (drugShipmentId: number) => drugShipmentDocumentActions.loadByDrugShipmentId(drugShipmentId);
        loadByDrugShipmentId.type = drugShipmentDocumentActions.loadByDrugShipmentId.type;

        const clear = () => drugShipmentDocumentActions.clear();
        clear.type = drugShipmentDocumentActions.clear.type;

        return bindActionCreators({
            loadByDrugShipmentId,
            clear
        }, dispatch);
    }, [drugShipmentDocumentActions, dispatch]);

    const drugShipmentDocumentsSelector = React.useCallback((state: IDrugShipmentDocumentStore) => {
        return drugShipmentDocumentSelectors.selectDrugShipmentDocuments(state);
    }, [drugShipmentDocumentSelectors.selectDrugShipmentDocuments]);

    const loadStateSelector = React.useCallback((state: IDrugShipmentDocumentStore) => {
        return drugShipmentDocumentSelectors.selectLoadState(state);
    }, [drugShipmentDocumentSelectors.selectLoadState]);

    const drugShipmentDocuments = useDrugShipmentDocumentsSelector(drugShipmentDocumentsSelector);

    const loadState = useDrugShipmentDocumentsSelector(loadStateSelector);

    return [
        drugShipmentDocuments as Dtos.DrugShipmentDocument[] | null,
        loadState,
        actions
    ];
};