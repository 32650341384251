export const selectDistinctObjects = <T>(arrayToReduce: T[],
    matchFunction: (reduceObject: T, returnObject: T) => boolean): T[] => {
    const arrayToReturn: T[] = [];

    arrayToReduce.forEach(x => {
        if (!arrayToReturn.some(y => matchFunction(x, y))) {
            arrayToReturn.push(x);
        }
    });

    return arrayToReturn;
}