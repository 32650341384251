/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the patient reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualPatientState {
    patient: Dtos.IPatient | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
    saveState: IRequestState<Dtos.ResponseStatus>;
}

export interface IPatientState {
    byId: Record<number, IIndividualPatientState>;
    byStudyNumber: Record<string, IIndividualPatientState>;
}

export interface IPatientStore {
    patient: IPatientState;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all patient reducer state changes and actions. 
 */
export class PatientReducer extends ImmerReducer<IPatientState>
{
    public loadById(id: number) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualPatientState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number, patient?: Dtos.IPatient) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualPatientState };
        }

        this.draftState.byId[id].patient = patient ? patient : null;

        this.draftState.byId[id].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualPatientState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public saveById(id: number, patient?: Dtos.IPatient) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualPatientState };
        }

        this.draftState.byId[id].saveState = {
            state: RequestState.Pending
        }
    }

    public saveByIdSuccess(id: number, patient?: Dtos.IPatient) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualPatientState };
        }

        this.draftState.byId[id].patient = patient ? patient : null;

        this.draftState.byId[id].saveState = {
            state: RequestState.Success
        };
    }

    public saveByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualPatientState };
        }

        this.draftState.byId[id].saveState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public loadByStudyNumber(studyNumber: string) {
        if (!this.draftState.byStudyNumber[studyNumber]) {
            this.draftState.byStudyNumber[studyNumber] = { ...initialIndividualPatientState };
        }

        this.draftState.byStudyNumber[studyNumber].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByStudyNumberSuccess(studyNumber: string, patient?: Dtos.IPatient) {
        if (!this.draftState.byStudyNumber[studyNumber]) {
            this.draftState.byStudyNumber[studyNumber] = { ...initialIndividualPatientState };
        }

        this.draftState.byStudyNumber[studyNumber].patient = patient ? patient : null;

        this.draftState.byStudyNumber[studyNumber].loadState = {
            state: RequestState.Success
        };
    }

    public loadByStudyNumberFailure(studyNumber: string, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byStudyNumber[studyNumber]) {
            this.draftState.byStudyNumber[studyNumber] = { ...initialIndividualPatientState };
        }

        this.draftState.byStudyNumber[studyNumber].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public saveByStudyNumber(studyNumber: string, patient?: Dtos.IPatient) {
        if (!this.draftState.byStudyNumber[studyNumber]) {
            this.draftState.byStudyNumber[studyNumber] = { ...initialIndividualPatientState };
        }

        this.draftState.byStudyNumber[studyNumber].saveState = {
            state: RequestState.Pending
        }
    }

    public saveByStudyNumberSuccess(studyNumber: string, patient?: Dtos.IPatient) {
        if (!this.draftState.byStudyNumber[studyNumber]) {
            this.draftState.byStudyNumber[studyNumber] = { ...initialIndividualPatientState };
        }

        this.draftState.byStudyNumber[studyNumber].patient = patient ? patient : null;

        this.draftState.byStudyNumber[studyNumber].saveState = {
            state: RequestState.Success
        };
    }

    public saveByStudyNumberFailure(studyNumber: string, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byStudyNumber[studyNumber]) {
            this.draftState.byStudyNumber[studyNumber] = { ...initialIndividualPatientState };
        }

        this.draftState.byStudyNumber[studyNumber].saveState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number) {
        if (this.draftState.byId[id]) {
            delete this.draftState.byId[id];
        }
    }

    public clearByStudyNumber(studyNumber: string) {
        if (this.draftState.byStudyNumber[studyNumber]) {
            delete this.draftState.byStudyNumber[studyNumber];
        }
    }

    public clearAll() {
        this.draftState = { ...initialPatientState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualPatientState: IIndividualPatientState = {
    patient: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    }
};

export const initialPatientState: IPatientState = {
    byStudyNumber: {},
    byId: {}
}

export const patientActions = createActionCreators(PatientReducer);
export const patientReducer = createReducerFunction(PatientReducer, initialPatientState);

const createPatientApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.PatientGetSingleById({ id }));
    },
    loadByStudyNumber: (studyNumber: string) => {
        return client.get(new Dtos.PatientGetSingleByStudyNumber({ studyNumber }));
    },
    save: (patient?: Dtos.IPatient) => {
        return client.post(new Dtos.PatientPostSave({ patient }))
    }
});

export const createPatientLogic = (api: ReturnType<typeof createPatientApi>) => {
    const logics = {
        loadById: createLogic<IPatientStore, {}, undefined, string, ReturnType<typeof patientActions.loadById>>({
            type: patientActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadById(action.payload);

                    dispatch(patientActions.loadByIdSuccess(
                        action.payload,
                        response.patient
                    ));
                }
                catch (error: any) {
                    dispatch(patientActions.loadByIdFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByStudyNumber: createLogic<any, {}, undefined, string, ReturnType<typeof patientActions.loadByStudyNumber>>({
            type: patientActions.loadByStudyNumber.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadByStudyNumber(action.payload);
                    dispatch(patientActions.loadByStudyNumberSuccess(
                        action.payload,
                        response.patient
                    ));
                }
                catch (error: any) {
                    dispatch(patientActions.loadByStudyNumberFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        saveById: createLogic<IPatientStore, {}, undefined, string, ReturnType<typeof patientActions.saveById>>({
            type: patientActions.saveById.type,
            process: async ({ action }, dispatch, done) => {
                const [id, patient] = action.payload;

                try {
                    const response = await api.save(patient);

                    dispatch(patientActions.saveByIdSuccess(
                        id,
                        response.patient
                    ));
                }
                catch (error: any) {
                    dispatch(patientActions.saveByIdFailure(id, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        saveByStudyNumber: createLogic<IPatientStore, {}, undefined, string, ReturnType<typeof patientActions.saveByStudyNumber>>({
            type: patientActions.saveByStudyNumber.type,
            process: async ({ action }, dispatch, done) => {
                const [studyNumber, patient] = action.payload;

                try {
                    const response = await api.save(patient);

                    dispatch(patientActions.saveByStudyNumberSuccess(
                        studyNumber,
                        response.patient
                    ));
                }
                catch (error: any) {
                    dispatch(patientActions.saveByStudyNumberFailure(studyNumber, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadByStudyNumber,
        logics.loadById,
        logics.saveById,
        logics.saveByStudyNumber
    ]
}

export const usePatientSelector: TypedUseSelectorHook<IPatientStore> = useSelector;

export const patientSelectors = {
    patientById: (state: IPatientStore, id: number) => state.patient.byId[id]?.patient ?? null,
    loadStateById: (state: IPatientStore, id: number) => state.patient.byId[id]?.loadState ?? initialIndividualPatientState.loadState,
    saveStateById: (state: IPatientStore, id: number) => state.patient.byId[id]?.saveState ?? initialIndividualPatientState.saveState,
    patientByStudyNumber: (state: IPatientStore, studyNumber: string) => state.patient.byStudyNumber[studyNumber]?.patient ?? null,
    loadStateByStudyNumber: (state: IPatientStore, studyNumber: string) => state.patient.byStudyNumber[studyNumber]?.loadState ?? initialIndividualPatientState.loadState,
    saveStateByStudyNumber: (state: IPatientStore, studyNumber: string) => state.patient.byStudyNumber[studyNumber]?.saveState ?? initialIndividualPatientState.saveState
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerPatientReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPatientApi(client);

    const logic = createPatientLogic(api);

    reducerRegistry.register('patient', patientReducer as Reducer, logic as any);
};

export default registerPatientReducer;