/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 * NewtonGreen Technologies Pty.Ltd.
 * Level 4, 175 Scott St.
 * Newcastle, NSW, 2300
    * Australia
    *
 * E - mail: support@newtongreen.com
 * Tel: (02) 4925 5288
    * Fax: (02) 4925 3068
        *
 * All Rights Reserved.
 * ---------------------------------------------------------------------------------
 * /

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the sae reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';
import { ScreeningSummary } from '../api/screeningSummary'

/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualScreeningSummaryState {
    screeningSummary: ScreeningSummary | null;
    loadState: IRequestState<ResponseStatus>;
    saveState: IRequestState<ResponseStatus>;
};

export interface IScreeningSummaryState {
    byContext: Record<string, IIndividualScreeningSummaryState>;
};

export interface IScreeningSummaryStore {
    screeningSummary: IScreeningSummaryState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualScreeningSummaryState: IIndividualScreeningSummaryState = {
    screeningSummary: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    }
};

export const initialSaeState: IScreeningSummaryState = {
    byContext: {}
}

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdContext = (id?: number | null) => {
    return `${(id ?? 'new')}`;
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class ScreeningSummaryReducer extends ImmerReducer<IScreeningSummaryState> {
    public load(id: number) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningSummaryState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(id: number, screeningSummary?: ScreeningSummary) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningSummaryState };
        }

        this.draftState.byContext[context].screeningSummary = screeningSummary ?? null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(id: number, responseStatus?: ResponseStatus) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningSummaryState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public save(id?: number, screeningSummary?: ScreeningSummary) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningSummaryState };
        }

        this.draftState.byContext[context].saveState = {
            state: RequestState.Pending
        };
    }

    public saveSuccess(id?: number, screeningSummary?: ScreeningSummary) {
        let context = createIdContext(id);

        // if newly created, must clean up the 'new' context
        if (!id && this.draftState.byContext[context]) {
            delete this.draftState.byContext[context]
            context = createIdContext(screeningSummary?.id);
        }

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningSummaryState };
        }

        this.draftState.byContext[context].screeningSummary = screeningSummary ?? null;

        this.draftState.byContext[context].saveState = {
            state: RequestState.Success
        };
    }

    public saveFailure(id?: number, responseStatus?: ResponseStatus) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningSummaryState };
        }

        this.draftState.byContext[context].saveState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(id: number) {
        const context = createIdContext(id);

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialSaeState };
    }
}

export const screeningSummaryActions = createActionCreators(ScreeningSummaryReducer);
export const screeningSummaryReducer = createReducerFunction(ScreeningSummaryReducer, initialSaeState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createScreeningSummaryApi = (client: JsonServiceClient) => ({
    load: (id: number) => {
        if(id == null || id == undefined){
            return null;
        }
        const request: Dtos.ScreeningSummaryGetSingleById = new Dtos.ScreeningSummaryGetSingleById({
            id
        });
        return client.get(request);
    },
    save: (screeningSummary?: ScreeningSummary) => {
        const request: Dtos.ScreeningSummaryPostSave = new Dtos.ScreeningSummaryPostSave({
            screeningSummary
        });
        return client.post(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createScreeningSummaryLogic = (api: ReturnType<typeof createScreeningSummaryApi>) => {
    const logic = {
        load: createLogic<IScreeningSummaryStore, {}, undefined, string, ReturnType<typeof screeningSummaryActions.load>>({
            type: screeningSummaryActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload as unknown as number;

                try {
                    let response: Dtos.ScreeningSummarySingleResponse | null = null;

                    response = await api.load(id);

                    dispatch(screeningSummaryActions.loadSuccess(
                        id,
                        response?.screeningSummary
                    ));
                }
                catch (error: any) {
                    dispatch(screeningSummaryActions.loadFailure(
                        id,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        }),
        save: createLogic<IScreeningSummaryStore, {}, undefined, string, ReturnType<typeof screeningSummaryActions.save>>({
            type: screeningSummaryActions.save.type,
            process: async ({ action }, dispatch, done) => {
                const [id, screeningSummary] = action.payload;

                try {
                    let response: Dtos.ScreeningSummarySingleResponse | null = null;

                    response = await api.save(screeningSummary);

                    dispatch(screeningSummaryActions.saveSuccess(
                        id,
                        response.screeningSummary
                    ));
                }
                catch (error: any) {
                    dispatch(screeningSummaryActions.saveFailure(
                        id,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load,
        logic.save
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useScreeningSummarySelector: TypedUseSelectorHook<IScreeningSummaryStore> = useSelector;

export const screeningSummarySelectors = {
    screeningSummary: (state: IScreeningSummaryStore, id?: number) => {
        const context = createIdContext(id);

        return state.screeningSummary?.byContext[context]?.screeningSummary ?? initialIndividualScreeningSummaryState.screeningSummary;
    },
    loadState: (state: IScreeningSummaryStore, id?: number) => {
        const context = createIdContext(id);

        return state.screeningSummary?.byContext[context]?.loadState ?? initialIndividualScreeningSummaryState.loadState;
    },
    saveState: (state: IScreeningSummaryStore, id?: number) => {
        const context = createIdContext(id);

        return state.screeningSummary?.byContext[context]?.saveState ?? initialIndividualScreeningSummaryState.saveState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerScreeningSummaryReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createScreeningSummaryApi(client);

    const logic = createScreeningSummaryLogic(api);

    reducerRegistry.register('screeningSummary', screeningSummaryReducer as Reducer, logic as any);
};

export default registerScreeningSummaryReducer;
