import { PharmacyStatus } from '../api/dtos';

export const getPharmacyStatusText = (pharmacyStatus?: PharmacyStatus): string => {
    if (!pharmacyStatus) {
        return 'Unknown';
    }

    switch (pharmacyStatus) {
        case PharmacyStatus.Open:
            return 'Open';
        case PharmacyStatus.Closed:
            return 'Closed';
        case PharmacyStatus.Inactive:
            return 'Inactive';
        default:
            return 'Unknown';
    }
};