/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving and saving a depotBatch.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';




/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDepotBatchState {
    depotBatch: Dtos.DepotBatch | null;
    loadState: IRequestState<ResponseStatus>;
    saveState: IRequestState<ResponseStatus>;
};

export interface IDepotBatchStore {
    depotBatch: IDepotBatchState;
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialDepotBatchState: IDepotBatchState = {
    depotBatch: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DepotBatchReducer extends ImmerReducer<IDepotBatchState> {
    public loadById(id: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(depotBatch?: Dtos.DepotBatch) {
        this.draftState.depotBatch = depotBatch ?? null;
        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus: ResponseStatus) {
        this.draftState.depotBatch = null;
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public save(form: Dtos.DepotBatch) {
        this.draftState.saveState = {
            state: RequestState.Pending
        };
    }

    public saveSuccess(depotBatch?: Dtos.DepotBatch) {
        this.draftState.depotBatch = depotBatch ?? null;
        this.draftState.saveState = {
            state: RequestState.Success
        };
    }

    public saveFailure(responseStatus: ResponseStatus) {
        this.draftState.depotBatch = null;
        this.draftState.saveState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDepotBatchState };
    }
};

export const depotBatchActions = createActionCreators(DepotBatchReducer);
export const depotBatchReducer = createReducerFunction(DepotBatchReducer, initialDepotBatchState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDepotBatchApi = (client: JsonServiceClient) => ({
    load: (id: number) => {
        return client.get(new Dtos.GetDepotBatchById({
            id
        }));
    },
    save: (depotBatch?: Dtos.DepotBatch) => {
        return client.post(new Dtos.SetDepotBatch({
            depotBatch
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDepotBatchLogic = (api: ReturnType<typeof createDepotBatchApi>) => {
    const logic = {
        load: createLogic<IDepotBatchStore, {}, undefined, string, ReturnType<typeof depotBatchActions.loadById>>({
            type: depotBatchActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload;

                try {
                    const response = await api.load(id);

                    dispatch(depotBatchActions.loadSuccess(
                        response.depotBatch
                    ));
                }
                catch (error: any) {
                    dispatch(depotBatchActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        save: createLogic<IDepotBatchState, {}, undefined, string, ReturnType<typeof depotBatchActions.save>>({
            type: depotBatchActions.save.type,
            process: async ({ action }, dispatch, done) => {
                const depotBatch = action.payload;

                try {
                    const response = await api.save(depotBatch);

                    dispatch(depotBatchActions.saveSuccess(
                        response.depotBatch
                    ));
                }
                catch (error: any) {
                    dispatch(depotBatchActions.saveFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }
    return [
        logic.load,
        logic.save
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDepotBatchSelector: TypedUseSelectorHook<IDepotBatchStore> = useSelector;

export const depotBatchSelectors = {
    selectDepotBatch: (state: IDepotBatchStore) => state.depotBatch.depotBatch,
    selectLoadState: (state: IDepotBatchStore) => state.depotBatch.loadState,
    selectSaveState: (state: IDepotBatchStore) => state.depotBatch.saveState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDepotBatchReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDepotBatchApi(client);

    const logic = createDepotBatchLogic(api);

    reducerRegistry.register('depotBatch', depotBatchReducer as Reducer, logic as any);
};

export default registerDepotBatchReducer;