/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving pharmacies.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IPharmacyState {
    pharmacy: Dtos.Pharmacy | null;
    loadState: IRequestState<ResponseStatus>;
    saveState: IRequestState<ResponseStatus>;
    quarantineStockState: IRequestState<ResponseStatus>;
    actionQuarantinedStockState: IRequestState<ResponseStatus>;
};

export interface IPharmacyStore {
    pharmacy: IPharmacyState;
};

export interface IQuarantinePharmacyStockForm {
    pharmacyId: number;
    ids: number[];
};

export interface IQuarantineRemoveQuarantinedStockForm {
    pharmacyId: number;
    batchActions: Record<number, number>;
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

const initialPharmacyState: IPharmacyState = {
    pharmacy: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    },
    quarantineStockState: {
        state: RequestState.None
    },
    actionQuarantinedStockState: {
        state: RequestState.None
    }
};


/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class PharmacyReducer extends ImmerReducer<IPharmacyState> {
    public loadById(id: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadByInstCode(institutionCode: string) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(pharmacy?: Dtos.Pharmacy) {
        this.draftState.pharmacy = pharmacy ? pharmacy : null;
        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus: ResponseStatus) {
        this.draftState.pharmacy = null;
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public save(form: Dtos.Pharmacy) {
        this.draftState.saveState = {
            state: RequestState.Pending
        };
    }

    public saveSuccess(pharmacy?: Dtos.Pharmacy) {
        this.draftState.pharmacy = pharmacy ? pharmacy : null;
        this.draftState.saveState = {
            state: RequestState.Success
        };
    }

    public saveFailure(responseStatus: ResponseStatus) {
        this.draftState.pharmacy = null;
        this.draftState.saveState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public quarantineStock(form: IQuarantinePharmacyStockForm) {
        this.draftState.quarantineStockState = {
            state: RequestState.Pending
        };
    }

    public quarantineStockSuccess(form: IQuarantinePharmacyStockForm) {
        this.draftState.quarantineStockState = {
            state: RequestState.Success
        };
    }

    public quarantineStockFailure(responseStatus: ResponseStatus) {
        this.draftState.quarantineStockState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public actionQuarantinedStock(form: IQuarantineRemoveQuarantinedStockForm) {
        this.draftState.actionQuarantinedStockState = {
            state: RequestState.Pending
        };
    }

    public actionQuarantinedStockSuccess(form: IQuarantineRemoveQuarantinedStockForm) {
        this.draftState.actionQuarantinedStockState = {
            state: RequestState.Success
        };
    }

    public actionQuarantinedStockFailure(responseStatus: ResponseStatus) {
        this.draftState.actionQuarantinedStockState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialPharmacyState };
    }
};

export const pharmacyActions = createActionCreators(PharmacyReducer);
export const pharmacyReducer = createReducerFunction(PharmacyReducer, initialPharmacyState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createPharmacyApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.GetPharmacyById({
            id
        }));
    },
    loadByInstCode: (institutionCode: string) => {
        return client.get(new Dtos.GetPharmacyByInstitutionCode({
            institutionCode
        }));
    },
    save: (pharmacy?: Dtos.Pharmacy) => {
        return client.post(new Dtos.SetPharmacy({
            pharmacy
        }));
    },
    quarantineStock: (form: IQuarantinePharmacyStockForm) => {
        return client.post(new Dtos.QuarantinePharmacyBatches({
            pharmacyId: form.pharmacyId,
            ids: form.ids
        }));
    },
    actionQuarantinedStock: (form: IQuarantineRemoveQuarantinedStockForm) => {
        return client.post(new Dtos.ActionQuarantinedPharmacyBatches({
            pharmacyId: form.pharmacyId,
            batchActions: form.batchActions
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createPharmacyLogic = (api: ReturnType<typeof createPharmacyApi>) => {
    const logic = {
        loadById: createLogic<IPharmacyState, {}, undefined, string, ReturnType<typeof pharmacyActions.loadById>>({
            type: pharmacyActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload;

                try {
                    const response = await api.loadById(id);

                    dispatch(pharmacyActions.loadSuccess(
                        response.pharmacy
                    ));
                }
                catch (error: any) {
                    dispatch(pharmacyActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByInstCode: createLogic<IPharmacyState, {}, undefined, string, ReturnType<typeof pharmacyActions.loadByInstCode>>({
            type: pharmacyActions.loadByInstCode.type,
            process: async ({ action }, dispatch, done) => {
                const institutionCode = action.payload;

                try {
                    const response = await api.loadByInstCode(institutionCode);

                    dispatch(pharmacyActions.loadSuccess(
                        response.pharmacy
                    ));
                }
                catch (error: any) {
                    dispatch(pharmacyActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        save: createLogic<IPharmacyState, {}, undefined, string, ReturnType<typeof pharmacyActions.save>>({
            type: pharmacyActions.save.type,
            process: async ({ action }, dispatch, done) => {
                const pharmacy = action.payload;

                try {
                    const response = await api.save(pharmacy);

                    dispatch(pharmacyActions.saveSuccess(
                        response.pharmacy
                    ));
                }
                catch (error: any) {
                    dispatch(pharmacyActions.saveFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        quarantineStock: createLogic<IPharmacyState, {}, undefined, string, ReturnType<typeof pharmacyActions.quarantineStock>>({
            type: pharmacyActions.quarantineStock.type,
            process: async ({ action }, dispatch, done) => {
                const formData = action.payload;

                try {
                    const response = await api.quarantineStock(formData);

                    dispatch(pharmacyActions.quarantineStockSuccess(
                        formData
                    ));
                }
                catch (error: any) {
                    dispatch(pharmacyActions.quarantineStockFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        actionQuarantinedStock: createLogic<IPharmacyState, {}, undefined, string, ReturnType<typeof pharmacyActions.actionQuarantinedStock>>({
            type: pharmacyActions.actionQuarantinedStock.type,
            process: async ({ action }, dispatch, done) => {
                const formData = action.payload;

                try {
                    const response = await api.actionQuarantinedStock(formData);

                    dispatch(pharmacyActions.actionQuarantinedStockSuccess(
                        formData
                    ));
                }
                catch (error: any) {
                    dispatch(pharmacyActions.actionQuarantinedStockFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }
    return [
        logic.loadById,
        logic.loadByInstCode,
        logic.save,
        logic.quarantineStock,
        logic.actionQuarantinedStock
    ];
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const usePharmacySelector: TypedUseSelectorHook<IPharmacyStore> = useSelector;

export const pharmacySelectors = {
    selectPharmacy: (state: IPharmacyStore) => state.pharmacy.pharmacy,
    selectLoadState: (state: IPharmacyStore) => state.pharmacy.loadState,
    selectSaveState: (state: IPharmacyStore) => state.pharmacy.saveState,
    selectQuarantineStockState: (state: IPharmacyStore) => state.pharmacy.quarantineStockState,
    selectActionQuarantinedStockState: (state: IPharmacyStore) => state.pharmacy.actionQuarantinedStockState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerPharmacyReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPharmacyApi(client);

    const logic = createPharmacyLogic(api);

    reducerRegistry.register('pharmacy', pharmacyReducer as Reducer, logic as any);
};

export default registerPharmacyReducer;