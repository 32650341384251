/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the patient summary component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * React
 */
import * as React from 'react';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';

import Grid from '@material-ui/core/Grid';

import { PatientInformationCard } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';


/*
 * ---------------------------------------------------------------------------------
 * interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDepotInformationProps {
    depot?: Dtos.Depot | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    item: {
        display: 'flex',

        '& > *': {
            flex: '1 1 auto'
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


const DepotInformation: React.FunctionComponent<IDepotInformationProps> = ({
    depot
}) => {

    const theme = useTheme();
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <PatientInformationCard
                    color={theme.palette.primary.main}
                    icon={faBuilding}
                    title="Depot"
                    text={depot?.depotName}
                />
            </Grid>
        </Grid>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default DepotInformation;
