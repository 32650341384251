import React from "react";
import {
    IFormState,
    IValidationError,
    useSnackbar,
    ValidationErrorType,
    asyncDebounce,
} from "@ngt/opms";
import AlertTitle from '@material-ui/lab/AlertTitle';
import * as Yup from 'yup';
import { capitalise } from "../utilities/capitalise";

export interface IUseModalOptions<TEntity extends object,
    TValidationEntity extends object> {
    entityName: string;
    asyncSave: (form: TEntity) => Promise<TEntity | undefined>;
    validation?: Yup.ObjectSchema<TValidationEntity>;
    onValidate?: (formState: IFormState<TEntity, IValidationError>) => Promise<Record<string, IValidationError[]>>;
    onFormSave?: () => void;
    onFormSaveError?: () => void;
}

const useModal = <TEntity extends object,
    TValidationEntity extends object>({
    entityName,
    onFormSave,
    validation,
    onValidate,
    asyncSave,
    onFormSaveError
    }: IUseModalOptions<TEntity, TValidationEntity>) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = React.useCallback(async ({ values, errors }: IFormState<TEntity, IValidationError>) => {

        await asyncSave(values);

        const allErrors = Object
            .keys(errors)
            .reduce((array: IValidationError[], key: string) => {
                const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                    return [...propertyArray, e]
                }, [])

                return [...array, ...propertyErrors]
            }, []);

        if (!allErrors || allErrors.length === 0) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                    {
                        `${capitalise(entityName)}`
                    }
                    </AlertTitle>
                    {
                        `The ${entityName.toLowerCase()} was successfully saved.`
                    }
                </>,
                { variant: 'success' }
            );

            if (onFormSave) {
                onFormSave();
            }
        }

    }, [history, asyncSave]);

    const onYupValidate = validation ?
        React.useCallback(async (formState: IFormState<TEntity, IValidationError>) => {
            try {
                await validation
                    .validate(formState.values, {
                        abortEarly: false,
                        context: formState.values
                    });

                return {};
            }

            catch (err: any) {
                const typedError: Yup.ValidationError = err;

                const groupErrors = typedError.inner?.reduce((a: Record<string, IValidationError[]>, b: Yup.ValidationError) => {
                    const propertyName = (b.path);

                    if (!a[propertyName]) {
                        a[propertyName] = [];
                    }

                    a[propertyName].push({
                        message: b.message,
                        detailedMessage: b.message,
                        type: ValidationErrorType.Normal,
                        code: ''
                    });

                    return a;
                }, {}) ?? {};

                return groupErrors;
            }
        }, []) :
        undefined;

    const debouncedValidate = (onYupValidate || onValidate) ?
        React.useMemo(() => {
            if (onValidate) {
                return asyncDebounce(onValidate, 500);
            }

            if (onYupValidate) {
                return asyncDebounce(onYupValidate, 500);
            }
            
        }, [onYupValidate, onValidate]) :
        undefined;

    const onFormSubmitFailure = React.useCallback(async () => {
        if (onFormSaveError) {
            onFormSaveError();
        } else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {
                            `${capitalise(entityName)} Not Saved`
                        }
                    </AlertTitle>
                    {
                        `An error occurred while attempting to save the ${entityName.toLowerCase()}.`
                    }
                </>,
                { variant: 'critical' }
            );
        }
    }, [onFormSaveError]);

    return {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    }
}

export default useModal;