/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving drugShipmentDocuments.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';



/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDrugShipmentDocumentState {
    drugShipmentDocuments: Dtos.DrugShipmentDocument[] | null;
    loadState: IRequestState<ResponseStatus>;

};

export interface IDrugShipmentDocumentStore {
    drugShipmentDocuments: IDrugShipmentDocumentState;
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

const initialDrugShipmentDocumentState: IDrugShipmentDocumentState = {
    drugShipmentDocuments: null,
    loadState: {
        state: RequestState.None
    },
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DrugShipmentDocumentReducer extends ImmerReducer<IDrugShipmentDocumentState> {
    public loadByDrugShipmentId(drugShipmentId: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(drugShipmentDocuments?: Dtos.DrugShipmentDocument[]) {
        this.draftState.drugShipmentDocuments = drugShipmentDocuments ?? null;
        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus: ResponseStatus) {
        this.draftState.drugShipmentDocuments = null;
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDrugShipmentDocumentState };
    }
};

export const drugShipmentDocumentActions = createActionCreators(DrugShipmentDocumentReducer);
export const drugShipmentDocumentReducer = createReducerFunction(DrugShipmentDocumentReducer, initialDrugShipmentDocumentState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDrugShipmentDocumentApi = (client: JsonServiceClient) => ({
    loadByDrugShipmentId: (drugShipmentId: number) => {
        return client.get(new Dtos.GetDrugShipmentDocumentsGetByShipmentId({
            drugShipmentId
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDrugShipmentDocumentLogic = (api: ReturnType<typeof createDrugShipmentDocumentApi>) => {
    const logic = {
        loadByDrugShipmentId: createLogic<IDrugShipmentDocumentStore, {}, undefined, string, ReturnType<typeof drugShipmentDocumentActions.loadByDrugShipmentId>>({
            type: drugShipmentDocumentActions.loadByDrugShipmentId.type,
            process: async ({ action }, dispatch, done) => {
                const drugShipmentId = action.payload;

                try {
                    const response = await api.loadByDrugShipmentId(drugShipmentId);

                    dispatch(drugShipmentDocumentActions.loadSuccess(
                        response.documents
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentDocumentActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadByDrugShipmentId,
    ];
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDrugShipmentDocumentsSelector: TypedUseSelectorHook<IDrugShipmentDocumentStore> = useSelector;

export const drugShipmentDocumentSelectors = {
    selectDrugShipmentDocuments: (state: IDrugShipmentDocumentStore) => state.drugShipmentDocuments.drugShipmentDocuments,
    selectLoadState: (state: IDrugShipmentDocumentStore) => state.drugShipmentDocuments.loadState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDrugShipmentDocumentReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDrugShipmentDocumentApi(client);

    const logic = createDrugShipmentDocumentLogic(api);

    reducerRegistry.register('drugShipmentDocuments', drugShipmentDocumentReducer as Reducer, logic as any);
};

export default registerDrugShipmentDocumentReducer;