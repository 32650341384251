/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the batch management page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Theme, makeStyles } from '@material-ui/core/styles';

/**
 * Used to format text.
 */
import Typography from '@material-ui/core/Typography';

import { RequestState, IRequestState } from '@ngt/request-utilities';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, MenuItem } from '@material-ui/core';

import { Column } from 'material-table';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DateTime } from 'luxon';

import * as Yup from 'yup';

import { Field, Form, Text, DatePicker, Select, Checkbox, CollapsibleTable, ResponseStatus, SubmitButton } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import * as Dtos from '../api/dtos';
import { useDrugs } from '../hooks/useDrugs';
import { useBatches } from '../hooks/useBatches';
import { useBatch, IUseBatchActions } from '../hooks/useBatch';
import DrugBreadcrumbs from '../components/DrugBreadcrumbs';
import useModal from '../hooks/useModal';
import { useLoading } from '../hooks/useLoading';
import { convertStringToDate } from '../utilities/convertStringToDate';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


interface IBatchManagementProps {
}

interface IBatchModalProps {
    batch: Dtos.Batch | null;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    drugs: Dtos.Drug[] | null;
    drugLoadState: IRequestState<ResponseStatus>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    batchActions: IUseBatchActions;
}

interface IBatchForm extends Partial<Dtos.Batch> {
}

interface IBatchFormProps {
    drugs: Dtos.Drug[] | null;
    drugLoadState: IRequestState<ResponseStatus>;
    setModalOpen: (open: boolean) => void;
    onFormSave?: () => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    buttonSet: {
        paddingBottom: theme.spacing(1),
        textAlign: 'right'
    },
    dialogContent: {
        padding: '0'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Hooks
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const useBatchColumns = (batches: Dtos.Batch[] | null) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.Batch>> = [
            {
                title: 'Batch Number',
                field: 'batchNo'
            },
            {
                title: 'Drug Type',
                field: 'drugName',
                render: b => b.drug.drugName
            },
            {
                field: 'dateExpiration',
                title: 'Date of Expiration',
                render: batch => batch.dateExpiration ?
                    DateTime.fromISO(batch.dateExpiration).toFormat('dd/MM/yyyy') :
                    null
            }
        ]

        return cols;
    }, [batches]);

    return columns as any;
};

const validation = Yup.object().shape<IBatchForm>({
    drugId: Yup.number().required(),
    batchNo: Yup.string().required(),
    dateExpiration: Yup.string().required(),
    available: Yup.boolean()
});

const BatchForm: React.FunctionComponent<IBatchFormProps> = ({
    drugs,
    drugLoadState,
    setModalOpen
}) => {
    const classes = useStyles();

    const onCloseClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setModalOpen(false);
    }, [setModalOpen]);

    const drugItems = React.useMemo(() => {
        if (!drugs || drugs.length === 0) {
            return [];
        }
        else {
            return drugs.map(drug => {
                return <MenuItem
                    key={drug.id}
                    value={drug.id}
                >
                    {drug.drugName}
                </MenuItem>;
            });
        }
    }, [drugs]);

    return (
        <>
            <DialogContent
                className={classes.dialogContent}
            >
                {
                    drugLoadState && drugLoadState.state && drugLoadState.state === RequestState.Pending ?
                        <CircularProgress /> :
                        <Field
                            name="drugId"
                            component={Select}
                            label="Drug"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            {drugItems}
                        </Field>
                }
                <Field
                    name="batchNo"
                    component={Text}
                    label="Batch Number"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="dateExpiration"
                    component={DatePicker}
                    label="Expiry Date"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    parse={convertStringToDate}
                />
                <Field
                    name="available"
                    component={Checkbox}
                    label="Available"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary" autoFocus>
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const BatchModal: React.FunctionComponent<IBatchModalProps> = ({
    batch,
    modalOpen,
    setModalOpen,
    drugs,
    drugLoadState,
    onCloseClick,
    onFormSave,
    batchActions
}) => {

    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'Batch',
        validation: validation,
        asyncSave: batchActions.asyncSave,
        onFormSave: onFormSave
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">
                    Save Batch
                </DialogTitle>
                <Form
                    initialValues={batch}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onValidate={debouncedValidate}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <BatchForm
                        drugs={drugs}
                        drugLoadState={drugLoadState}
                        setModalOpen={setModalOpen}
                    />
                </Form>
            </Dialog>
        </>
    );
}

const BatchManagement: React.FunctionComponent<IBatchManagementProps> = () => {
    const classes = useStyles();

    const [drugs, drugLoadState, drugActions] = useDrugs();

    const [batches, batchesLoadState, batchesActions] = useBatches();

    const [batch, batchLoadState, , batchActions] = useBatch();

    const [modalOpen, setModalOpen] = React.useState(false);

    const [batchForm, setBatch] = React.useState<Dtos.Batch | null>(null);

    React.useEffect(() => {
        drugActions.load();

        return () => {
            drugActions.clear();
        }
    }, []);

    React.useEffect(() => {
        batchesActions.load();

        return () => {
            batchesActions.clear();
        }
    }, []);

    React.useEffect(() => {
        if (batchLoadState.state === RequestState.Success) {
            setBatch(batch);

            setModalOpen(true);
        }
    }, [batchLoadState.state]);

    const onFormSave = React.useCallback(() => {
        setModalOpen(false);
        batchActions.clear();
        batchesActions.load();
    }, []);

    const addBatchClick = React.useCallback(() => {
        setBatch(new Dtos.Batch({ drugId: 1, available: true }));

        setModalOpen(true);
    }, [setModalOpen]);

    const onRowClick = React.useCallback((event?: React.MouseEvent,
        rowData?: Dtos.Batch) => {
        if (rowData &&
            rowData.id) {
            batchActions.clear();
            batchActions.load(rowData.id);
        }
    }, []);

    const batchColumns = useBatchColumns(batches);

    const batchesLoading = useLoading(batches,
        batchesLoadState);

    //this is here for general cleanup in case something is loaded into redux and never reset
    React.useEffect(() => {
        return () => {
            batchActions.clear();
        }
    }, []);

    return (
        <>
            <BatchModal
                batch={batchForm}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                drugs={drugs}
                drugLoadState={drugLoadState}
                onFormSave={onFormSave}
                batchActions={batchActions}
            />
            <DrugBreadcrumbs
                showInstitution={true}
            />
            <div
                className={classes.container}
            >
                <Typography
                    variant="h1"
                    color="secondary"
                >
                    Batches
                </Typography>
                <div
                    className={classes.buttonSet}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={addBatchClick}
                    >
                        Add Batch
                    </Button>
                </div>
                <CollapsibleTable
                    title="Batch"
                    loading={batchesLoading}
                    data={batches ?? []}
                    columns={batchColumns}
                    onRowClick={onRowClick}
                />
            </div>
        </>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default BatchManagement;