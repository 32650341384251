/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the saes reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';

import { Patient } from '../api/patient';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualSaesState {
    saes: Array<Dtos.Sae<Dtos.ISaeForm, Patient>> | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface ISaesState {
    byContext: Record<string, IIndividualSaesState>;
};

export interface ISaesStore {
    saes: ISaesState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualSaesState: IIndividualSaesState = {
    saes: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialSaesState: ISaesState = {
    byContext: {}
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class SaesReducer extends ImmerReducer<ISaesState> {
    public load(institutionCode?: string) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaesState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(institutionCode?: string, saes?: Array<Dtos.Sae<Dtos.ISaeForm, Patient>>) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaesState };
        }

        this.draftState.byContext[context].saes = saes ? saes : null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(institutionCode?: string, responseStatus?: ResponseStatus) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaesState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(institutionCode?: string) {
        const context = `${institutionCode}`;

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialSaesState };
    }
};

export const saesActions = createActionCreators(SaesReducer);
export const saesReducer = createReducerFunction(SaesReducer, initialSaesState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createSaesApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.SaeGetCollection());
    },
    loadByInstitutionCode: (institutionCode?: string) => {
        const request: Dtos.SaeGetCollectionByInstitutionCode = new Dtos.SaeGetCollectionByInstitutionCode();
        request.institutionCode = institutionCode!!;

        return client.get(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createSaesLogic = (api: ReturnType<typeof createSaesApi>) => {
    const logic = {
        load: createLogic<ISaesStore, {}, undefined, string, ReturnType<typeof saesActions.load>>({
            type: saesActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const institutionCode = action.payload as unknown as string;

                try {
                    let response: Dtos.SaeCollectionResponse | null = null;

                    if (institutionCode) {
                        response = await api.loadByInstitutionCode(institutionCode);
                    } else {
                        response = await api.load();
                    }

                    dispatch(saesActions.loadSuccess(
                        institutionCode,
                        response.saes
                    ));
                }
                catch (error: any) {
                    dispatch(saesActions.loadFailure(
                        institutionCode,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useSaesSelector: TypedUseSelectorHook<ISaesStore> = useSelector;

export const saesSelectors = {
    saes: (state: ISaesStore, institutionCode?: string) => {
        const context = `${institutionCode}`;

        return state.saes?.byContext[context]?.saes ?? initialIndividualSaesState.saes;
    },
    loadState: (state: ISaesStore, institutionCode?: string) => {
        const context = `${institutionCode}`;

        return state.saes?.byContext[context]?.loadState ?? initialIndividualSaesState.loadState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerSaesReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createSaesApi(client);

    const logic = createSaesLogic(api);

    reducerRegistry.register('saes', saesReducer as Reducer, logic as any);
};

export default registerSaesReducer;