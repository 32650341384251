/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving drugs.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';




/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDrugsState {
    drugs: Dtos.Drug[] | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IDrugsStore {
    drugs: IDrugsState;
};

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

const initialDrugsState: IDrugsState = {
    drugs: null,
    loadState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DrugsReducer extends ImmerReducer<IDrugsState> {
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(drugs?: Dtos.Drug[]) {
        this.draftState.drugs = drugs ?? null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDrugsState };
    }
};

export const drugsActions = createActionCreators(DrugsReducer);
export const drugsReducer = createReducerFunction(DrugsReducer, initialDrugsState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDrugsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.GetDrugs());
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDrugsLogic = (api: ReturnType<typeof createDrugsApi>) => {
    const logic = {
        load: createLogic<IDrugsStore, {}, undefined, string, ReturnType<typeof drugsActions.load>>({
            type: drugsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(drugsActions.loadSuccess(
                        response.drugs
                    ));
                }
                catch (error: any) {
                    dispatch(drugsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }
    return [
        logic.load
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDrugsSelector: TypedUseSelectorHook<IDrugsStore> = useSelector;

export const drugsSelectors = {
    drugs: (state: IDrugsStore) => state.drugs.drugs,
    loadState: (state: IDrugsStore) => state.drugs.loadState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDrugsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDrugsApi(client);

    const logic = createDrugsLogic(api);

    reducerRegistry.register('drugs', drugsReducer as Reducer, logic as any);
};

export default registerDrugsReducer;