/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typings
 */
import * as Dtos from '../../api/dtos';

/*
 * Used to type form.
 */
import Form, { IFormProps } from './Form';

import OnlinePatientManagementContext from '../../contexts/OnlinePatientManagementContext';

import IDtoRequestClass from '../../utilities/IDtoRequestClass';

import { pascalToCameCasePropertyPath } from '../../utilities/pascalToCamelCase';

import IDtoClass from '../../utilities/IDtoClass';

import { IFormState, IFormAllowSubmit, IFormActions, IFormSubmitValidationFailed } from '../../form/Form';

import asyncDebounce from '../../utilities/asyncDebounce';
import useLookupsByFormDefinitionId from '../../hooks/utility/useLookupsByFormDefinitionId';
import useFormDefinitionById from '../../hooks/configuration/useFormDefinitionById';
import FormDefinitionContext from '../../contexts/configuration/FormDefinitionContext';
import { IFormLabel } from '../../contexts/form/LabelsContext';
import FormContext from '../../contexts/data/FormContext';
import EventDefinitionContext from '../../contexts/configuration/EventDefinitionContext';
import InstitutionContext from '../../contexts/data/InstitutionContext';
import PatientContext from '../../contexts/data/PatientContext';
import EventContext from '../../contexts/data/EventContext';
import useAsyncFunction from '../../hooks/useAsyncFunction';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import SubmitButton from './SubmitButton';
import { IFormContext } from '../../form/contexts/FormContext';
import LookupsContext from '../../contexts/utility/LookupsContext';
import usePatientForm from '../../hooks/form/usePatientForm';
import useIsMobile from '../../hooks/useIsMobile';
import useSnackbar from '../../hooks/useSnackbar';
import { FieldErrorOption } from '../../contexts/form/FormOptionsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitCalculatedTypes<T> = Omit<T, 'onValidate' | 'allowSubmit' | 'initialValues' | 'lookups' | 'labels'>

/**
 * This interface defines the properties for the CrfForm component.
 */
export interface ICrfDialogFormProps<Type extends Dtos.IForm> extends OmitCalculatedTypes<IFormProps<Type>> {
    formType: IDtoClass<Type>;
    afterFormSave?: (institution?: Dtos.IInstitution | null, patient?: Dtos.IPatient | null, event?: Dtos.IEvent | null, form?: Type | null) => void;
    afterFormSaveAndReturn?: (institution?: Dtos.IInstitution | null, patient?: Dtos.IPatient | null, event?: Dtos.IEvent | null, form?: Type | null) => void;
    onCancel?: () => void;
    hideButtons?: boolean;
    hideSubmit?: boolean;
    hideCancel?: boolean;
    fieldErrors?: FieldErrorOption;
    canEdit?: boolean;
    allowSubmit?: (formState: IFormState<Type, Dtos.IValidationError>, formActions: IFormActions<Type, Dtos.IValidationError>, allowSubmit: IFormAllowSubmit<Type, Dtos.IValidationError> | null) => Promise<boolean>;
    onFormSubmitValidationFailure?: (formState: IFormState<Type, Dtos.IValidationError>, validationError: boolean, onFormSubmitValidationFailure: IFormSubmitValidationFailed<Type, Dtos.IValidationError> | null) => Promise<void>;
    dialogTitle?: string;
    ignoreReadOnly?: boolean;
}



/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    padding: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(1,3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    },
    formTitle: {
        padding: theme.spacing(3),
        fontSize: '1.5rem',
        fontWeight: 500
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */


/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const CrfDialogForm = <Type extends Dtos.IForm>({
    formType,
    afterFormSave,
    afterFormSaveAndReturn,
    onCancel,
    children,
    hideButtons,
    hideCancel,
    hideSubmit,
    canEdit,
    allowSubmit,
    fieldErrors,
    onFormSubmitValidationFailure,
    dialogTitle,
    ignoreReadOnly,
    ...formProps
}: ICrfDialogFormProps<Type>) => {
    const classes = useStyles();

    const {
        form,
        patient,
        labels,
        lookups,
        handleSubmit,
        allowSubmit: allowSubmitBase,
        onFormCancel,
        onFormSubmit,
        onFormSubmitAndReturn,
        validate,
        formName,
        readOnly,
        onFormSubmitFailure,
        onFormSubmitValidationFailure: onFormSubmitValidationFailureBase
    } = usePatientForm({ formType, afterFormSave, afterFormSaveAndReturn, onCancel, readOnly: !canEdit });

    const isMobile = useIsMobile();

    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    const combinedAllowSubmit = React.useCallback(async (formState: IFormState<Type, Dtos.IValidationError>, formActions: IFormActions<Type, Dtos.IValidationError>) => {
        if (allowSubmit) {
            return await allowSubmit(formState, formActions, allowSubmitBase);
        }

        return await allowSubmitBase(formState, formActions);
    }, [allowSubmit, allowSubmitBase])

    const combinedOnFormSubmitValidationFailure = React.useCallback(async (formState: IFormState<Type, Dtos.IValidationError>, validationError: boolean) => {
        if (onFormSubmitValidationFailure) {
            return await onFormSubmitValidationFailure(formState, validationError, onFormSubmitValidationFailureBase);
        }

        return await onFormSubmitValidationFailureBase(formState, validationError);
    }, [onFormSubmitValidationFailure, onFormSubmitValidationFailureBase])

    const readOnlyToUse = React.useMemo(() => {
        return !!ignoreReadOnly ? !canEdit : readOnly
    }, [readOnly, ignoreReadOnly, canEdit]);

    return (
        <Form
            {...formProps}
            initialValues={form ?? {} as any}
            onValidate={validate}
            allowSubmit={combinedAllowSubmit}
            fieldErrors={fieldErrors ?? (!!form?.id ? 'always' : 'default')}
            onSubmit={handleSubmit}
            labels={labels}
            lookups={lookups}
            readOnly={readOnlyToUse}
            onSubmitFailed={onFormSubmitFailure}
            onSubmitValidationFailed={combinedOnFormSubmitValidationFailure}
        >
            <Typography
                className={classes.formTitle}
                color="secondary"
            >
                {!!dialogTitle ? dialogTitle : formName}
            </Typography>
            {children}
            {
                !hideButtons && !readOnlyToUse && (
                    <div
                        className={classes.buttonGroup}
                    >
                        {
                            !hideCancel && (
                                <Button
                                    variant="text"
                                    color="secondary"
                                    onClick={onFormCancel}
                                >
                                    Cancel
                                </Button>
                            )
                        }
                        {
                            !!patient && !hideSubmit && !isMobile && (
                                <SubmitButton
                                    variant="text"
                                    color="primary"
                                    onClick={onFormSubmit}
                                >
                                    Submit
                                </SubmitButton>
                            )
                        }
                    </div>
                )
            }
            {
                !hideButtons && readOnlyToUse && (
                    <div
                        className={classes.buttonGroup}
                    >
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={onFormCancel}
                        >
                            Back
                        </Button>
                    </div>
                )
            }
        </Form>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default CrfDialogForm;