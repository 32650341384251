/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the drug dispensation delete modal component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, Theme, makeStyles } from '@material-ui/core';

import { SubmitButton, Form, Field, Numeric } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IUseDrugDispensationActions } from '../hooks/useDrugDispensation';
import useModal from '../hooks/useModal';

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogContent: {
        padding: '0'
    },
    hidden: {
        display: 'none'
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugDispensationDeleteModalProps {
    drugDispensation: Dtos.DrugDispensation | null;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onFormClose?: () => void;
    onFormSave?: () => void;
    drugDispensationActions: IUseDrugDispensationActions;
}

const DrugDispensationDeleteModal: React.FunctionComponent<IDrugDispensationDeleteModalProps> = ({
    drugDispensation,
    modalOpen,
    setModalOpen,
    onFormClose,
    onFormSave,
    drugDispensationActions
}) => {
    const classes = useStyles();

    const initialValues = Object.assign({}, { id: drugDispensation });

    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'delete dispensation',
        asyncSave: drugDispensationActions.asyncDelete,
        onFormSave: onFormSave
    });
    const onCloseClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setModalOpen(false);
    }, [setModalOpen]);

    const dialogText = `This will delete the drug dispensation and add the drugs back to the batch. Do you wish to continue?`;

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Drug Dispensation
                </DialogTitle>
                <Form
                    initialValues={initialValues.id}
                    onSubmit={handleSubmit}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <DialogContent>
                        {dialogText}
                        <div
                            className={classes.hidden}
                        >
                            <Field
                                name="id"
                                component={Numeric}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCloseClick} color="primary">
                            Cancel
                        </Button>
                        <SubmitButton variant="contained" color="primary">
                            OK
                        </SubmitButton>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    );
}

export default DrugDispensationDeleteModal;