/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving and saving a depot.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';




/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IIndividualDepotState {
    depot: Dtos.Depot | null;
    loadState: IRequestState<ResponseStatus>;
    saveState: IRequestState<ResponseStatus>;
};

export interface IDepotState {
    byContext: Record<string, IIndividualDepotState>;
};

export interface IDepotStore {
    depot: IDepotState;
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualDepotState: IIndividualDepotState = {
    depot: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    }
};

export const initialDepotState: IDepotState = {
    byContext: {}
};

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdContext = (id?: number | null) => {
    return `${(id ?? 'new')}`;
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DepotReducer extends ImmerReducer<IDepotState> {
    public load(id: number) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualDepotState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(id: number, depot?: Dtos.Depot) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualDepotState };
        }

        this.draftState.byContext[context].depot = depot ?? null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(id: number, responseStatus?: ResponseStatus) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualDepotState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public save(id?: number, depot?: Dtos.Depot) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualDepotState };
        }

        this.draftState.byContext[context].saveState = {
            state: RequestState.Pending
        };
    }

    public saveSuccess(id?: number, depot?: Dtos.Depot) {
        let context = createIdContext(id);

        // if newly created, must clean up the 'new' context
        if (!id && this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
            context = createIdContext(depot?.id);
        }

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualDepotState };
        }

        this.draftState.byContext[context].depot = depot ?? null;

        this.draftState.byContext[context].saveState = {
            state: RequestState.Success
        };
    }

    public saveFailure(id?: number, responseStatus?: ResponseStatus) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualDepotState };
        }

        this.draftState.byContext[context].saveState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(id: number) {
        const context = createIdContext(id);

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }
};

export const depotActions = createActionCreators(DepotReducer);
export const depotReducer = createReducerFunction(DepotReducer, initialDepotState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDepotApi = (client: JsonServiceClient) => ({
    load: (id: number) => {
        return client.get(new Dtos.GetDepotById({
            id
        }));
    },
    save: (depot?: Dtos.Depot) => {
        return client.post(new Dtos.SetDepot({
            depot
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDepotLogic = (api: ReturnType<typeof createDepotApi>) => {
    const logic = {
        load: createLogic<IDepotStore, {}, undefined, string, ReturnType<typeof depotActions.load>>({
            type: depotActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload;

                try {
                    const response = await api.load(id);

                    dispatch(depotActions.loadSuccess(
                        id,
                        response.depot
                    ));
                }
                catch (error: any) {
                    dispatch(depotActions.loadFailure(id,
                        error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        save: createLogic<IDepotState, {}, undefined, string, ReturnType<typeof depotActions.save>>({
            type: depotActions.save.type,
            process: async ({ action }, dispatch, done) => {
                const [id, depot] = action.payload;

                try {
                    const response = await api.save(depot);

                    dispatch(depotActions.saveSuccess(
                        id,
                        response.depot
                    ));
                }
                catch (error: any) {
                    dispatch(depotActions.saveFailure(id,
                        error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }
    return [
        logic.load,
        logic.save
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDepotSelector: TypedUseSelectorHook<IDepotStore> = useSelector;

export const depotSelectors = {
    depot: (state: IDepotStore, id?: number) => {
        const context = createIdContext(id);

        return state.depot?.byContext[context]?.depot ?? initialIndividualDepotState.depot;
    },
    loadState: (state: IDepotStore, id?: number) => {
        const context = createIdContext(id);

        return state.depot?.byContext[context]?.loadState ?? initialIndividualDepotState.loadState;
    },
    saveState: (state: IDepotStore, id?: number) => {
        const context = createIdContext(id);

        return state.depot?.byContext[context]?.saveState ?? initialIndividualDepotState.saveState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDepotReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDepotApi(client);

    const logic = createDepotLogic(api);

    reducerRegistry.register('depot', depotReducer as Reducer, logic as any);
};

export default registerDepotReducer;