/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use drug shipment depot batches.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    ResponseStatus
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';

import {
    drugShipmentDepotBatchesActions,
    useDrugShipmentDepotBatchesSelector,
    drugShipmentDepotBatchesSelectors,
    IDrugShipmentDepotBatchesStore
} from '../store/modules/drugshipmentdepotbatches';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseDrugShipmentDepotBatchesActions {
    load: BoundActionCreator<typeof drugShipmentDepotBatchesActions.load>;
    loadByPharmacyId: BoundActionCreator<typeof drugShipmentDepotBatchesActions.loadByPharmacyId>;
    loadByInstCode: BoundActionCreator<typeof drugShipmentDepotBatchesActions.loadByInstCode>;
    clear: BoundActionCreator<typeof drugShipmentDepotBatchesActions.clear>;
}

export const useDrugShipmentDepotBatches = (): [Dtos.DrugShipmentDepotBatch[] | null, IRequestState<ResponseStatus>, IUseDrugShipmentDepotBatchesActions] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {
        const load = () => drugShipmentDepotBatchesActions.load();
        load.type = drugShipmentDepotBatchesActions.load.type;

        const loadByPharmacyId = (pharmacyId: number) => drugShipmentDepotBatchesActions.loadByPharmacyId(pharmacyId);
        loadByPharmacyId.type = drugShipmentDepotBatchesActions.loadByPharmacyId.type;

        const loadByInstCode = (instCode: string) => drugShipmentDepotBatchesActions.loadByInstCode(instCode);
        loadByInstCode.type = drugShipmentDepotBatchesActions.loadByInstCode.type;

        const clear = () => drugShipmentDepotBatchesActions.clear();
        clear.type = drugShipmentDepotBatchesActions.clear.type;

        return bindActionCreators({
            load,
            loadByPharmacyId,
            loadByInstCode,
            clear
        }, dispatch);
    }, [drugShipmentDepotBatchesActions, dispatch]);

    const drugShipmentDepotBatchesSelector = React.useCallback((state: IDrugShipmentDepotBatchesStore) => {
        return drugShipmentDepotBatchesSelectors.drugShipmentDepotBatches(state);
    }, [drugShipmentDepotBatchesSelectors.drugShipmentDepotBatches]);

    const loadStateSelector = React.useCallback((state: IDrugShipmentDepotBatchesStore) => {
        return drugShipmentDepotBatchesSelectors.loadState(state);
    }, [drugShipmentDepotBatchesSelectors.loadState]);

    const drugShipmentDepotBatches = useDrugShipmentDepotBatchesSelector(drugShipmentDepotBatchesSelector);

    const loadState = useDrugShipmentDepotBatchesSelector(loadStateSelector);

    return [
        drugShipmentDepotBatches as Dtos.DrugShipmentDepotBatch[] | null,
        loadState,
        actions
    ];
};