
/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

import { PatientInformationFn } from "@ngt/opms";
import React from "react";

export interface IDrugManagementContext {
    baseUrl: string;
    defaultUnit: string;
    randomisedStatus: number;
    treatments: string[];
    allowEditConsignmentNumber: boolean;
    consignmentNumberLabel: string;
    maxDrugsPerDispensation?: number;
    patientCaption?: PatientInformationFn;
    allowDrugShipmentPharmacistSelection?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const DrugManagementContext = React.createContext<IDrugManagementContext>({
    baseUrl: '/drug-management',
    defaultUnit: '',
    randomisedStatus: -1,
    treatments: ['','Arm A','Arm B'],
    allowEditConsignmentNumber: true,
    consignmentNumberLabel: 'Consignment Number',
    maxDrugsPerDispensation: undefined,
    patientCaption: undefined,
    allowDrugShipmentPharmacistSelection: false
} as IDrugManagementContext);

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default DrugManagementContext;