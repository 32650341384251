/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the drug shipment table buttons component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { createReportUrl, ReportExportFormat } from '../utilities/createReportUrl';
import { IUseDrugShipmentActions } from '../hooks/useDrugShipment';
import { IUseDepotBatchesActions } from '../hooks/useDepotBatches';
import { IReportEnvironment } from '../interfaces/IReportEnvironment';
import { DrugReportResolverFn } from '../function/DrugReportResolver';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugShipmentTableButtonsProps {
    drugShipment: Dtos.DrugShipment;
    shipmentStatus: Dtos.ShipmentStatus;
    drugShipmentActions: IUseDrugShipmentActions;
    depotBatchesActions: IUseDepotBatchesActions;
    setDrugShipmentStatus: React.Dispatch<React.SetStateAction<Dtos.ShipmentStatus | null>>;
    setDeleteDrugShipmentId: React.Dispatch<React.SetStateAction<number | null>>;
    setDrugShipmentDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    canAdministerDrugManagement: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    buttonSet: {
        textAlign: 'right'
    },
    button: {
        marginLeft: theme.spacing(3),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const DrugShipmentTableButtons: React.FunctionComponent<IDrugShipmentTableButtonsProps> = ({
    drugShipment,
    shipmentStatus,
    drugShipmentActions,
    depotBatchesActions,
    setDrugShipmentStatus,
    setDeleteDrugShipmentId,
    setDrugShipmentDeleteModalOpen,
    canAdministerDrugManagement
}) => {
    const classes = useStyles();

    const onReceivedClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();

        setDrugShipmentStatus(Dtos.ShipmentStatus.Available);
        depotBatchesActions.loadByDrugShipmentId(drugShipment.id!!);
        drugShipmentActions.clear();
        drugShipmentActions.load(drugShipment.id!!);
    }, [drugShipment.id!!]);

    const onOrderFormClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
    }, []);

    const orderFormLink = React.useMemo(() => {
        return `/drug-management-api/drug-shipment-order-form/id/${drugShipment.id}`;
    }, [drugShipment]);

    const onOrderClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();

        setDrugShipmentStatus(Dtos.ShipmentStatus.Ordered);
        drugShipmentActions.clear();
        drugShipmentActions.load(drugShipment.id!!);
    }, [drugShipment.id!!]);

    const onDeleteClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();

        setDeleteDrugShipmentId(drugShipment.id!!);
        setDrugShipmentDeleteModalOpen(true);
    }, [drugShipment.id!!]);

    const onShipClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();

        setDrugShipmentStatus(Dtos.ShipmentStatus.InTransit);
        drugShipmentActions.clear();
        drugShipmentActions.load(drugShipment.id!!);
    }, [drugShipment.id!!]);

    const onLostClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();

        setDrugShipmentStatus(Dtos.ShipmentStatus.Lost);
        drugShipmentActions.clear();
        drugShipmentActions.load(drugShipment.id!!);
    }, [drugShipment.id!!]);

    const onQuarantinedClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();

        setDrugShipmentStatus(Dtos.ShipmentStatus.QuarantinedFromTransit);
        drugShipmentActions.clear();
        drugShipmentActions.load(drugShipment.id!!);
    }, [drugShipment.id!!]);

    if (shipmentStatus === Dtos.ShipmentStatus.New &&
        canAdministerDrugManagement) {
        return <>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                size="small"
                href={orderFormLink}
                onClick={onOrderFormClick}
            >
                Order Form
            </Button>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onOrderClick}
                size="small"
            >
                Order
            </Button>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onDeleteClick}
                size="small"
            >
                Delete
            </Button>
        </>;
    }
    else if (shipmentStatus === Dtos.ShipmentStatus.Ordered &&
        canAdministerDrugManagement) {
        return <>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                size="small"
                href={orderFormLink}
                onClick={onOrderFormClick}
            >
                Order Form
            </Button>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onShipClick}
                size="small"
            >
                Shipped
            </Button>
        </>;
    }
    else if (shipmentStatus === Dtos.ShipmentStatus.InTransit) {
        return <>
            {
                canAdministerDrugManagement &&
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="small"
                    href={orderFormLink}
                    onClick={onOrderFormClick}
                >
                    Order Form
                </Button>
            }
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onReceivedClick}
                size="small"
            >
                Received
            </Button>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onQuarantinedClick}
                size="small"
            >
                Quarantined
            </Button>
            {
                canAdministerDrugManagement &&
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={onLostClick}
                    size="small"
                >
                    Lost
                </Button>
            }
        </>;
    }
    else if (shipmentStatus === Dtos.ShipmentStatus.QuarantinedFromTransit) {
        return <>
            {
                canAdministerDrugManagement &&
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="small"
                    href={orderFormLink}
                    onClick={onOrderFormClick}
                >
                    Order Form
                </Button>
            }
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onReceivedClick}
                size="small"
            >
                Received
            </Button>
        </>;
    }
    else if (shipmentStatus == Dtos.ShipmentStatus.Available &&
        canAdministerDrugManagement) {
        return <Button
            variant="contained"
            color="primary"
            className={classes.button}
            size="small"
            href={orderFormLink}
            onClick={onOrderFormClick}
        >
            Order Form
        </Button>
    }

    return null;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default DrugShipmentTableButtons;