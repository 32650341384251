
/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

import React from "react";

export interface ISaeSearchContext {
    patientStudyNumber: string | undefined;
    setPatientStudyNumber: (patientStudyNumber: string | undefined) => void;
    saeNumber: string | undefined;
    setSaeNumber: (patientStudyNumber: string | undefined) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */
const SaeSearchContext = React.createContext<ISaeSearchContext>({
    patientStudyNumber: undefined,
    setPatientStudyNumber: () => { },
    saeNumber: undefined,
    setSaeNumber: () => { },
});

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeSearchContext;