/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use a drug dispensation by the id
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    ResponseStatus,
    useAsyncFunction,
    BoundActionCreator,
    TypedFunction,
    bindActionCreatorsWithType
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

import {
    patientDrugsActions,
    usePatientDrugsSelector,
    patientDrugsSelectors,
    IPatientDrugsStore,
    IPatientDrugsForm
} from '../store/modules/patientdrugs';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUsePatientDrugsActions {
    load: BoundActionCreator<typeof patientDrugsActions.loadById>;
    clear: BoundActionCreator<typeof patientDrugsActions.clear>;
    save: BoundActionCreator<typeof patientDrugsActions.save>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<typeof patientDrugsActions.save>>, Promise<IPatientDrugsForm | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const usePatientDrugs = (): [
    Dtos.PatientDrug[] | null,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IUsePatientDrugsActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncSave = useAsyncFunction(patientDrugsActions.save, patientDrugsActions.saveSuccess, patientDrugsActions.saveFailure);

    const actions: IUsePatientDrugsActions = React.useMemo(() => {
        const load = (id: number) => patientDrugsActions.loadById(id);
        load.type = patientDrugsActions.loadById.type;

        const clear = () => patientDrugsActions.clear();
        clear.type = patientDrugsActions.clear.type;

        const save = (form: IPatientDrugsForm) => patientDrugsActions.save(form);
        save.type = patientDrugsActions.save.type;

        const asyncSave = async (form: IPatientDrugsForm) => {
            const savedPatientDrugs = await unboundAsyncSave(form);

            return savedPatientDrugs;
        };

        return {
            ...bindActionCreatorsWithType({
                load,
                clear,
                save,
            }, dispatch),
            asyncSave
        };
    }, [patientDrugsActions, dispatch, unboundAsyncSave]);

    const patientDrugsSelector = React.useCallback((state: IPatientDrugsStore) => {
        return patientDrugsSelectors.selectPatientDrugs(state)
    }, [patientDrugsSelectors.selectPatientDrugs]);

    const loadStateSelector = React.useCallback((state: IPatientDrugsStore) => {
        return patientDrugsSelectors.selectLoadState(state)
    }, [patientDrugsSelectors.selectLoadState]);

    const saveStateSelector = React.useCallback((state: IPatientDrugsStore) => {
        return patientDrugsSelectors.selectSaveState(state)
    }, [patientDrugsSelectors.selectSaveState]);

    const patientDrugs = usePatientDrugsSelector(patientDrugsSelector);

    const loadState = usePatientDrugsSelector(loadStateSelector);

    const saveState = usePatientDrugsSelector(saveStateSelector);

    return [
        patientDrugs,
        loadState,
        saveState,
        actions
    ];
}