/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the sae reviewers reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';

/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualSaeReviewersState {
    reviewers: Record<number, string>;
    loadState: IRequestState<ResponseStatus>;
}

export interface ISaeReviewersState {
    byContext: Record<string, IIndividualSaeReviewersState>;
}

export interface ISaeReviewersStore {
    reviewers: ISaeReviewersState;
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualSaeReviewersState: IIndividualSaeReviewersState = {
    reviewers: {},
    loadState: {
        state: RequestState.None
    },
};

export const initialSaeReviewersState: ISaeReviewersState = {
    byContext: {}
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class SaeReviewersReducer extends ImmerReducer<ISaeReviewersState> {
    public load(institutionCode: string, roles: number[]) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeReviewersState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        }
    }

    public loadSuccess(institutionCode: string, reviewers: Record<number, string>) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeReviewersState };
        }

        this.draftState.byContext[context].reviewers = reviewers;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(institutionCode: string, responseStatus: ResponseStatus) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeReviewersState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(institutionCode: string) {
        const context = `${institutionCode}`;

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialSaeReviewersState };
    }
}

export const saeReviewersActions = createActionCreators(SaeReviewersReducer);
export const saeReviewersReducer = createReducerFunction(SaeReviewersReducer, initialSaeReviewersState);


/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createSaeReviewersApi = (client: JsonServiceClient) => ({
    load: (institutionCode: string, roles: number[]) => {
        return client.get(new Dtos.GetInstitutionPersonnelNames({
            institutionCode,
            roles
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createSaeReviewersLogic = (api: ReturnType<typeof createSaeReviewersApi>) => {
    const logic = {
        load: createLogic<ISaeReviewersStore, {}, undefined, string, ReturnType<typeof saeReviewersActions.load>>({
            type: saeReviewersActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const [institutionCode, roles] = action.payload;

                try {
                    let response: Dtos.InstitutionPersonnelNamesResponse | null = null;

                    response = await api.load(institutionCode, roles);

                    dispatch(saeReviewersActions.loadSuccess(
                        institutionCode,
                        response?.personnelNames ?? {}
                    ));
                }
                catch (error: any) {
                    dispatch(saeReviewersActions.loadFailure(
                        institutionCode,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        }),
    }

    return [
        logic.load
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useSaeReviewersSelector: TypedUseSelectorHook<ISaeReviewersStore> = useSelector;

export const saeReviewersSelectors = {
    reviewers: (state: ISaeReviewersStore, institutionCode: string) => {
        const context = `${institutionCode}`;

        return state.reviewers?.byContext[context]?.reviewers ?? initialIndividualSaeReviewersState.reviewers;
    },
    loadState: (state: ISaeReviewersStore, institutionCode: string) => {
        const context = `${institutionCode}`;

        return state.reviewers?.byContext[context]?.loadState ?? initialIndividualSaeReviewersState.loadState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerSaeReviewersReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createSaeReviewersApi(client);

    const logic = createSaeReviewersLogic(api);

    reducerRegistry.register('reviewers', saeReviewersReducer as Reducer, logic as any);
};

export default registerSaeReviewersReducer;


