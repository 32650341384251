/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the depot dialog component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { RequestState, IRequestState } from '@ngt/request-utilities';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, MenuItem, Theme, makeStyles } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import * as Yup from 'yup';

import { Field, Form, Text, Select, ResponseStatus, SubmitButton } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { IUseDepotActions } from '../hooks/useDepot';
import useModal from '../hooks/useModal';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDepotModalProps {
    depot: Dtos.Depot | null;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    orderForms: Dtos.OrderForm[] | null;
    orderFormLoadState: IRequestState<ResponseStatus>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    depotActions: IUseDepotActions;
}

interface IDepotFormProps {
    orderForms: Dtos.OrderForm[] | null;
    orderFormLoadState: IRequestState<ResponseStatus>;
    setModalOpen: (open: boolean) => void;
}

interface IDepotForm extends Partial<Dtos.Depot> {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogContent: {
        padding: '0'
    }
}));

const validation = Yup.object().shape<IDepotForm>({
    depotName: Yup.string().required(),
    address: Yup.string().required(),
    email: Yup.string().required(),
    contactName: Yup.string().required(),
    phoneNo: Yup.string().required(),
    orderFormId: Yup.number().required()
});

const DepotForm: React.FunctionComponent<IDepotFormProps> = ({
    orderForms,
    orderFormLoadState,
    setModalOpen
}) => {
    const classes = useStyles();

    const onCloseClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setModalOpen(false);
    }, [setModalOpen]);

    const orderFormItems = React.useMemo(() => {
        if (!orderForms || orderForms.length === 0) {
            return [];
        }
        else {
            return orderForms.map(orderForm => {
                return <MenuItem
                    key={orderForm.id}
                    value={orderForm.id}
                >
                    {orderForm.orderFormName}
                </MenuItem>;
            });
        }
    }, [orderForms]);

    return (
        <>
            <DialogContent
                className={classes.dialogContent}
            >
                <Field
                    name="depotName"
                    component={Text}
                    label="Depot Name"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="address"
                    component={Text}
                    label="Address"
                    multiline={true}
                    rows={4}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="email"
                    component={Text}
                    label="Email"
                    multiline={true}
                    rows={2}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    helperText="Separate multiple addresses with a semicolon"
                />
                <Field
                    name="contactName"
                    component={Text}
                    label="Contact Name"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="phoneNo"
                    component={Text}
                    label="Phone Number"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                {
                    orderFormLoadState && orderFormLoadState.state && orderFormLoadState.state === RequestState.Pending ?
                        <CircularProgress /> :
                        <Field
                            name="orderFormId"
                            component={Select}
                            label="Order Form"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            nullOption={false}
                        >
                            {orderFormItems}
                        </Field>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary">
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const DepotModal: React.FunctionComponent<IDepotModalProps> = ({
    depot,
    modalOpen,
    setModalOpen,
    orderForms,
    orderFormLoadState,
    onCloseClick,
    depotActions,
    onFormSave
}) => {

    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'depot',
        validation: validation,
        asyncSave: depotActions.asyncSave,
        onFormSave: onFormSave
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="lg"
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Save Depot
                </DialogTitle>
                <Form
                    initialValues={depot}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onValidate={debouncedValidate}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <DepotForm
                        orderForms={orderForms}
                        orderFormLoadState={orderFormLoadState}
                        setModalOpen={setModalOpen}
                    />
                </Form>
            </Dialog>
        </>
    );
}

export default DepotModal;