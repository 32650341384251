/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use drugDispensations.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    ResponseStatus
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { drugDispensationsActions, useDrugDispensationsSelector, drugDispensationsSelectors, IDrugDispensationsStore } from '../store/modules/drugdispensations';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseDrugDispensationsActions {
    load: BoundActionCreator<typeof drugDispensationsActions.load>;
    loadByPatientId: BoundActionCreator<typeof drugDispensationsActions.loadByPatientId>;
    clear: BoundActionCreator<typeof drugDispensationsActions.clear>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useDrugDispensations = (autoLoad?: boolean): [
    Dtos.DrugDispensation[] | null,
    IRequestState<ResponseStatus>,
    IUseDrugDispensationsActions
] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {
        const load = () => drugDispensationsActions.load();
        load.type = drugDispensationsActions.load.type;

        const loadByPatientId = (patientId: number) => drugDispensationsActions.loadByPatientId(patientId);
        loadByPatientId.type = drugDispensationsActions.loadByPatientId.type;

        const clear = () => drugDispensationsActions.clear();
        clear.type = drugDispensationsActions.clear.type;

        return bindActionCreators({
            load,
            loadByPatientId,
            clear
        }, dispatch);
    }, [drugDispensationsActions, dispatch]);

    const drugDispensationsSelector = React.useCallback((state: IDrugDispensationsStore) => {
        return drugDispensationsSelectors.drugDispensations(state)
    }, [drugDispensationsSelectors.drugDispensations]);

    const loadStateSelector = React.useCallback((state: IDrugDispensationsStore) => {
        return drugDispensationsSelectors.loadState(state)
    }, [drugDispensationsSelectors.loadState]);

    const drugDispensations = useDrugDispensationsSelector(drugDispensationsSelector);

    const loadState = useDrugDispensationsSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad]);

    return [
        drugDispensations as Dtos.DrugDispensation[] | null,
        loadState,
        actions
    ];
};

