/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use a depotBatch by the id
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    IPatient,
    ResponseStatus,
    useAsyncFunction,
    BoundActionCreator,
    TypedFunction,
    bindActionCreatorsWithType,
    OmitFirstArg
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

import {
    depotBatchActions,
    useDepotBatchSelector,
    depotBatchSelectors,
    IDepotBatchStore
} from '../store/modules/depotbatch';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseDepotBatchActions {
    load: BoundActionCreator<typeof depotBatchActions.loadById>;
    clear: BoundActionCreator<typeof depotBatchActions.clear>;
    save: BoundActionCreator<OmitFirstArg<typeof depotBatchActions.save>>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<OmitFirstArg<typeof depotBatchActions.save>>>, Promise<Dtos.DepotBatch | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useDepotBatch = (): [
    Dtos.DepotBatch | null,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IUseDepotBatchActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncSave = useAsyncFunction(depotBatchActions.save, depotBatchActions.saveSuccess, depotBatchActions.saveFailure);

    const actions: IUseDepotBatchActions = React.useMemo(() => {
        const load = (id: number) => depotBatchActions.loadById(id);
        load.type = depotBatchActions.loadById.type;

        const clear = () => depotBatchActions.clear();
        clear.type = depotBatchActions.clear.type;

        const save = (depotBatch: Dtos.DepotBatch) => depotBatchActions.save(depotBatch);
        save.type = depotBatchActions.save.type;

        const asyncSave = async (depotBatch?: Dtos.DepotBatch) => {
            const savedDepotBatch = await unboundAsyncSave(depotBatch);

            return savedDepotBatch;
        };

        return {
            ...bindActionCreatorsWithType({
                load,
                clear,
                save,
            }, dispatch),
            asyncSave
        };
    }, [depotBatchActions, dispatch, unboundAsyncSave]);

    const depotBatchSelector = React.useCallback((state: IDepotBatchStore) => {
        return depotBatchSelectors.selectDepotBatch(state)
    }, [depotBatchSelectors.selectDepotBatch]);

    const loadStateSelector = React.useCallback((state: IDepotBatchStore) => {
        return depotBatchSelectors.selectLoadState(state)
    }, [depotBatchSelectors.selectLoadState]);

    const saveStateSelector = React.useCallback((state: IDepotBatchStore) => {
        return depotBatchSelectors.selectSaveState(state)
    }, [depotBatchSelectors.selectSaveState]);

    const depotBatch = useDepotBatchSelector(depotBatchSelector);

    const loadState = useDepotBatchSelector(loadStateSelector);

    const saveState = useDepotBatchSelector(saveStateSelector);

    return [
        depotBatch,
        loadState,
        saveState,
        actions
    ];
}