/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component for the select icon.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

/*
 * Used to identify request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * Used for basic foundation of the application
 */
import { Grid } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to type master groups
 */
import * as Dtos from '../../api/dtos';

/*
 * Used to consistently identify and name the all master groups option.
 */
import { ALL_INSTITUTIONS_CODE, ALL_INSTITUTIONS } from '../../constants/institution';

/*
 * Used to consistently identify and name the all master groups option.
 */
import { ALL_COUNTRIES_CODE, ALL_COUNTRIES } from '../../constants/country';

/*
 * Used to consistently identify and name the all collaborating groups option.
 */
import { ALL_COLLABORATING_GROUPS, ALL_COLLABORATING_GROUPS_CODE } from '../../constants/collaboratingGroup';

/*
 * Used to consistently identify and name the all master groups option.
 */
import { ALL_MASTER_GROUPS, ALL_MASTER_GROUPS_CODE } from '../../constants/masterGroup';

/*
 * Used for basic selector logic.
 */
import CodeSelector from './CodeSelector';

/*
 * Used to retrieve master groups from context.
 */
import MasterGroupsContext from '../../contexts/data/MasterGroupsContext';

/*
 * Used to retrieve collaborating groups from context.
 */
import CollaboratingGroupsContext from '../../contexts/data/CollaboratingGroupsContext';

/*
 * Used to retrieve countries from context.
 */
import CountriesContext from '../../contexts/data/CountriesContext';

/*
 * Used to retrieve institutions from context.
 */
import InstitutionsContext from '../../contexts/data/InstitutionsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface ITrialContextSelectorProps {
    masterGroups?: Dtos.IMasterGroup[] | null;
    collaboratingGroups?: Dtos.ICollaboratingGroup[] | null;
    countries?: Dtos.ICountry[] | null;
    institutions?: Dtos.IInstitution[] | null;
    masterGroupsLoading?: boolean | null;
    collaboratingGroupsLoading?: boolean | null;
    countriesLoading?: boolean | null;
    institutionsLoading?: boolean | null;
    masterGroupCode?: string | null;
    collaboratingGroupCode?: string | null;
    countryCode?: string | null;
    institutionCode?: string | null;
    allowAllMasterGroups?: boolean | null;
    allowAllCollaboratingGroups?: boolean | null;
    allowAllCountries?: boolean | null;
    allowAllInstitutions?: boolean | null;
    hideMasterGroups?: boolean | null;
    hideCollaboratingGroups?: boolean | null;
    hideCountries?: boolean | null;
    hideInstitutions?: boolean | null;
    onChange?: (masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, institutionCode?: string | null) => void;
    sm?: boolean | "auto" | 1 | 2 | 3 |4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
}

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const TrialContextSelector: React.FunctionComponent<ITrialContextSelectorProps> = ({
    masterGroups,
    collaboratingGroups,
    countries,
    institutions,
    masterGroupsLoading,
    collaboratingGroupsLoading,
    countriesLoading,
    institutionsLoading,
    onChange,
    collaboratingGroupCode,
    masterGroupCode,
    countryCode,
    institutionCode,
    allowAllCollaboratingGroups,
    allowAllCountries,
    allowAllInstitutions,
    allowAllMasterGroups,
    hideCollaboratingGroups,
    hideCountries,
    hideInstitutions,
    hideMasterGroups,
    sm,
    children
}) => {

    const { masterGroups: contextMasterGroups, loadState: masterGroupsLoadState } = React.useContext(MasterGroupsContext);
    const { collaboratingGroups: contextCollaboratingGroups, loadState: collaboratingGroupsLoadState } = React.useContext(CollaboratingGroupsContext);
    const { countries: contextCountries, loadState: countriesLoadState } = React.useContext(CountriesContext);
    const { institutions: contextInstitutions, loadState: institutionsLoadState } = React.useContext(InstitutionsContext);

    const masterGroupsToUse = masterGroups === undefined ? contextMasterGroups : masterGroups;
    const collaboratingGroupsToUse = collaboratingGroups === undefined ? contextCollaboratingGroups : collaboratingGroups;
    const countriesToUse = countries === undefined ? contextCountries : countries;
    const institutionsToUse = institutions === undefined ? contextInstitutions : institutions;

    const masterGroupsLoadingToUse = masterGroups === undefined && masterGroupsLoading === undefined ?
        masterGroupsLoadState.state === RequestState.None || masterGroupsLoadState.state === RequestState.Pending :
        masterGroupsLoading;
    const collaboratingGroupsLoadingToUse = collaboratingGroups === undefined && collaboratingGroupsLoading === undefined ?
        collaboratingGroupsLoadState.state === RequestState.None || collaboratingGroupsLoadState.state === RequestState.Pending :
        collaboratingGroupsLoading;
    const countriesLoadingToUse = countries === undefined && countriesLoading === undefined ?
        countriesLoadState.state === RequestState.None || countriesLoadState.state === RequestState.Pending :
        countriesLoading;
    const institutionsLoadingToUse = institutions === undefined && institutionsLoading === undefined ?
        institutionsLoadState.state === RequestState.None || institutionsLoadState.state === RequestState.Pending :
        institutionsLoading;

    const onUpdate = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {

        let checkedMasterGroup: Dtos.IMasterGroup | null = null;
        let checkedCollaboratingGroup: Dtos.ICollaboratingGroup | null = null;
        let checkedCountry: Dtos.ICountry | null = null;
        let checkedInstitution: Dtos.IInstitution | null = null;

        if (newMasterGroupCode &&
            newMasterGroupCode != ALL_MASTER_GROUPS_CODE) {
            checkedMasterGroup = masterGroupsToUse?.find(mg => mg.code === newMasterGroupCode) ?? null;
        }

        if (newCollaboratingGroupCode &&
            newCollaboratingGroupCode != ALL_COLLABORATING_GROUPS_CODE) {
            checkedCollaboratingGroup = collaboratingGroupsToUse?.find(cg =>
                cg.code === newCollaboratingGroupCode &&
                (checkedMasterGroup === null || checkedMasterGroup.id === cg.masterGroupId)
            ) ?? null;
        }

        if (newCountryCode &&
            newCountryCode != ALL_COUNTRIES_CODE) {
            checkedCountry = countriesToUse?.filter(c =>
                institutionsToUse?.some(i =>
                    i.countryId === c.id &&
                    (checkedMasterGroup === null || checkedMasterGroup.id === i.masterGroupId) &&
                    (checkedCollaboratingGroup === null || checkedCollaboratingGroup.id === i.collaboratingGroupId)
                )
            ).find(c =>
                c.code === newCountryCode
            ) ?? null;
        }

        if (newInstitutionCode &&
            newInstitutionCode != ALL_INSTITUTIONS_CODE) {
            checkedInstitution = institutionsToUse?.find(i =>
                i.code === newInstitutionCode &&
                (checkedMasterGroup === null || checkedMasterGroup.id === i.masterGroupId) &&
                (checkedCollaboratingGroup === null || checkedCollaboratingGroup.id === i.collaboratingGroupId) &&
                (checkedCountry === null || checkedCountry.id === i.countryId)
            ) ?? null;
        }

        if (onChange) {
            onChange(checkedMasterGroup?.code ?? null, checkedCollaboratingGroup?.code ?? null, checkedCountry?.code ?? null, checkedInstitution?.code ?? null);
        }
    }, [masterGroupsToUse, collaboratingGroupsToUse, countriesToUse, institutionsToUse, ALL_MASTER_GROUPS_CODE])

    const onMasterGroupChange = React.useCallback((code?: string | null) => {
        onUpdate(code, collaboratingGroupCode, countryCode, institutionCode);
    }, [onUpdate, collaboratingGroupCode, countryCode, institutionCode]);

    const onCollaboratingGroupChange = React.useCallback((code?: string | null) => {
        onUpdate(masterGroupCode, code, countryCode, institutionCode);
    }, [onUpdate, masterGroupCode, countryCode, institutionCode]);

    const onCountryChange = React.useCallback((code?: string | null) => {
        onUpdate(masterGroupCode, collaboratingGroupCode, code, institutionCode);
    }, [onUpdate, collaboratingGroupCode, masterGroupCode, institutionCode]);

    const onInstitutionChange = React.useCallback((code?: string | null) => {
        onUpdate(masterGroupCode, collaboratingGroupCode, countryCode, code);
    }, [onUpdate, collaboratingGroupCode, countryCode, masterGroupCode]);

    return (
        <Grid
            container
            spacing={3}
        >
            {
                hideMasterGroups !== true && (
                    <Grid
                        item
                        xs={12}
                        sm={sm ?? 6}
                    >
                        <CodeSelector
                            inputId="masterGroup"
                            label="Master Group"
                            onChange={onMasterGroupChange as any}
                            selection={masterGroupCode}
                            loading={masterGroupsLoadingToUse}
                            allSelectionAllowed={allowAllMasterGroups}
                            allSelectionLabel={ALL_MASTER_GROUPS}
                            allSelectionValue={ALL_MASTER_GROUPS_CODE}
                            items={masterGroupsToUse}
                        />
                    </Grid>
                )
            }
            {
                hideCollaboratingGroups !== true && (
                    <Grid
                        item
                        xs={12}
                        sm={sm ?? 6}
                    >
                        <CodeSelector
                            inputId="collaboratingGroup"
                            label="Collaborating Group"
                            onChange={onCollaboratingGroupChange as any}
                            selection={collaboratingGroupCode}
                            loading={collaboratingGroupsLoadingToUse}
                            allSelectionAllowed={allowAllCollaboratingGroups}
                            allSelectionLabel={ALL_COLLABORATING_GROUPS}
                            allSelectionValue={ALL_COLLABORATING_GROUPS_CODE}
                            items={collaboratingGroupsToUse}
                        />
                    </Grid>
                )
            }
            {
                hideCountries !== true && (
                    <Grid
                        item
                        xs={12}
                        sm={sm ?? 6}
                    >
                        <CodeSelector
                            inputId="country"
                            label="Country"
                            onChange={onCountryChange as any}
                            selection={countryCode}
                            loading={countriesLoadingToUse}
                            allSelectionAllowed={allowAllCountries}
                            allSelectionLabel={ALL_COUNTRIES}
                            allSelectionValue={ALL_COUNTRIES_CODE}
                            items={countriesToUse}
                        />
                    </Grid>
                )
            }
            {
                hideInstitutions !== true && (
                    <Grid
                        item
                        xs={12}
                        sm={sm ?? 6}
                    >
                        <CodeSelector
                            inputId="institution"
                            label="Institutions"
                            onChange={onInstitutionChange as any}
                            selection={institutionCode}
                            loading={institutionsLoadingToUse}
                            allSelectionAllowed={allowAllInstitutions}
                            allSelectionLabel={ALL_INSTITUTIONS}
                            allSelectionValue={ALL_INSTITUTIONS_CODE}
                            items={institutionsToUse}
                        />
                    </Grid>
                )
            }
            {
                children
            }
        </Grid>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default TrialContextSelector;
