/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use a drugShipment by the id
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    ResponseStatus,
    useAsyncFunction,
    BoundActionCreator,
    TypedFunction,
    bindActionCreatorsWithType
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

import {
    drugShipmentActions,
    useDrugShipmentSelector,
    drugShipmentSelectors,
    IDrugShipmentStore,
    IDeleteDrugShipmentForm
} from '../store/modules/drugshipment';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseDrugShipmentActions {
    load: BoundActionCreator<typeof drugShipmentActions.loadById>;
    clear: BoundActionCreator<typeof drugShipmentActions.clear>;
    save: BoundActionCreator<typeof drugShipmentActions.save>;
    setStatus: BoundActionCreator<typeof drugShipmentActions.setStatus>;
    delete: BoundActionCreator<typeof drugShipmentActions.deleteById>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<typeof drugShipmentActions.save>>, Promise<Dtos.DrugShipment | undefined>>;
    asyncSetStatus: TypedFunction<Parameters<BoundActionCreator<typeof drugShipmentActions.setStatus>>, Promise<Dtos.DrugShipment | undefined>>;
    asyncDelete: TypedFunction<Parameters<BoundActionCreator<typeof drugShipmentActions.deleteById>>, Promise<IDeleteDrugShipmentForm | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useDrugShipment = (): [
    Dtos.DrugShipment | null,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IUseDrugShipmentActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncSave = useAsyncFunction(drugShipmentActions.save, drugShipmentActions.saveSuccess, drugShipmentActions.saveFailure);

    const unboundAsyncSetStatus = useAsyncFunction(drugShipmentActions.setStatus, drugShipmentActions.setStatusSuccess, drugShipmentActions.setStatusFailure);

    const unboundAsyncDelete = useAsyncFunction(drugShipmentActions.deleteById, drugShipmentActions.deleteSuccess, drugShipmentActions.deleteFailure);

    const actions: IUseDrugShipmentActions = React.useMemo(() => {
        const load = (id: number) => drugShipmentActions.loadById(id);
        load.type = drugShipmentActions.loadById.type;

        const clear = () => drugShipmentActions.clear();
        clear.type = drugShipmentActions.clear.type;

        const save = (drugShipment: Dtos.DrugShipment) => drugShipmentActions.save(drugShipment);
        save.type = drugShipmentActions.save.type;

        const setStatus = (drugShipment: Dtos.DrugShipment) => drugShipmentActions.setStatus(drugShipment);
        setStatus.type = drugShipmentActions.setStatus.type;

        const deleteById = (form: IDeleteDrugShipmentForm) => drugShipmentActions.deleteById(form);
        deleteById.type = drugShipmentActions.deleteById.type;

        const asyncSave = async (drugShipment?: Dtos.DrugShipment) => {
            const savedDrugShipment = await unboundAsyncSave(drugShipment);

            return savedDrugShipment;
        };

        const asyncSetStatus = async (drugShipment?: Dtos.DrugShipment) => {
            const returnedDrugShipment = await unboundAsyncSetStatus(drugShipment);

            return returnedDrugShipment;
        };

        const asyncDelete = async (form: IDeleteDrugShipmentForm) => {
            await unboundAsyncDelete(form);

            return form;
        };

        return {
            ...bindActionCreatorsWithType({
                load,
                clear,
                save,
                setStatus,
                deleteById
            }, dispatch),
            asyncSave,
            asyncSetStatus,
            asyncDelete
        };
    }, [drugShipmentActions, dispatch, unboundAsyncSave, unboundAsyncSetStatus, unboundAsyncDelete]);

    const drugShipmentSelector = React.useCallback((state: IDrugShipmentStore) => {
        return drugShipmentSelectors.selectDrugShipment(state)
    }, [drugShipmentSelectors.selectDrugShipment]);

    const loadStateSelector = React.useCallback((state: IDrugShipmentStore) => {
        return drugShipmentSelectors.selectLoadState(state)
    }, [drugShipmentSelectors.selectLoadState]);

    const saveStateSelector = React.useCallback((state: IDrugShipmentStore) => {
        return drugShipmentSelectors.selectSaveState(state)
    }, [drugShipmentSelectors.selectSaveState]);

    const setStatusStateSelector = React.useCallback((state: IDrugShipmentStore) => {
        return drugShipmentSelectors.selectSetStatusState(state)
    }, [drugShipmentSelectors.selectSetStatusState]);

    const deleteStateSelector = React.useCallback((state: IDrugShipmentStore) => {
        return drugShipmentSelectors.selectDeleteState(state)
    }, [drugShipmentSelectors.selectDeleteState]);

    const drugShipment = useDrugShipmentSelector(drugShipmentSelector);

    const loadState = useDrugShipmentSelector(loadStateSelector);

    const saveState = useDrugShipmentSelector(saveStateSelector);

    const setStatusState = useDrugShipmentSelector(setStatusStateSelector);

    const deleteState = useDrugShipmentSelector(deleteStateSelector);

    return [
        drugShipment,
        loadState,
        saveState,
        setStatusState,
        deleteState,
        actions
    ];
}