/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving drugShipmentDepotBatches.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';




/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDrugShipmentDepotBatchesState {
    drugShipmentDepotBatches: Dtos.DrugShipmentDepotBatch[] | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IDrugShipmentDepotBatchesStore {
    drugShipmentDepotBatches: IDrugShipmentDepotBatchesState;
};

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

const initialDrugShipmentDepotBatchesState: IDrugShipmentDepotBatchesState = {
    drugShipmentDepotBatches: null,
    loadState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DrugShipmentDepotBatchesReducer extends ImmerReducer<IDrugShipmentDepotBatchesState> {
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(drugShipmentDepotBatches?: Dtos.DrugShipmentDepotBatch[]) {
        this.draftState.drugShipmentDepotBatches = drugShipmentDepotBatches ?? null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadByPharmacyId(pharmacyId: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadByInstCode(instCode: string) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadFailure(responseStatus?: ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDrugShipmentDepotBatchesState };
    }
};

export const drugShipmentDepotBatchesActions = createActionCreators(DrugShipmentDepotBatchesReducer);
export const drugShipmentDepotBatchesReducer = createReducerFunction(DrugShipmentDepotBatchesReducer, initialDrugShipmentDepotBatchesState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDrugShipmentDepotBatchesApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.GetDrugShipmentDepotBatches());
    },
    loadByPharmacyId: (pharmacyId: number) => {
        return client.get(new Dtos.GetDrugShipmentDepotBatchesByPharmacyId({
            pharmacyId
        }));
    },
    loadByInstCode: (instCode: string) => {
        return client.get(new Dtos.GetDrugShipmentDepotBatchesByInstitutionCode({
            instCode
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDrugShipmentDepotBatchesLogic = (api: ReturnType<typeof createDrugShipmentDepotBatchesApi>) => {
    const logic = {
        load: createLogic<IDrugShipmentDepotBatchesStore, {}, undefined, string, ReturnType<typeof drugShipmentDepotBatchesActions.load>>({
            type: drugShipmentDepotBatchesActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(drugShipmentDepotBatchesActions.loadSuccess(
                        response.drugShipmentDepotBatches
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentDepotBatchesActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByPharmacyId: createLogic<IDrugShipmentDepotBatchesState, {}, undefined, string, ReturnType<typeof drugShipmentDepotBatchesActions.loadByPharmacyId>>({
            type: drugShipmentDepotBatchesActions.loadByPharmacyId.type,
            process: async ({ action }, dispatch, done) => {
                const pharmacyId = action.payload;

                try {
                    const response = await api.loadByPharmacyId(pharmacyId);

                    dispatch(drugShipmentDepotBatchesActions.loadSuccess(
                        response.drugShipmentDepotBatches
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentDepotBatchesActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByInstCode: createLogic<IDrugShipmentDepotBatchesState, {}, undefined, string, ReturnType<typeof drugShipmentDepotBatchesActions.loadByInstCode>>({
            type: drugShipmentDepotBatchesActions.loadByInstCode.type,
            process: async ({ action }, dispatch, done) => {
                const instCode = action.payload;

                try {
                    const response = await api.loadByInstCode(instCode);

                    dispatch(drugShipmentDepotBatchesActions.loadSuccess(
                        response.drugShipmentDepotBatches
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentDepotBatchesActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.load,
        logic.loadByPharmacyId,
        logic.loadByInstCode
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDrugShipmentDepotBatchesSelector: TypedUseSelectorHook<IDrugShipmentDepotBatchesStore> = useSelector;

export const drugShipmentDepotBatchesSelectors = {
    drugShipmentDepotBatches: (state: IDrugShipmentDepotBatchesStore) => state.drugShipmentDepotBatches.drugShipmentDepotBatches,
    loadState: (state: IDrugShipmentDepotBatchesStore) => state.drugShipmentDepotBatches.loadState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDrugShipmentDepotBatchesReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDrugShipmentDepotBatchesApi(client);

    const logic = createDrugShipmentDepotBatchesLogic(api);

    reducerRegistry.register('drugShipmentDepotBatches', drugShipmentDepotBatchesReducer as Reducer, logic as any);
};

export default registerDrugShipmentDepotBatchesReducer;