/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the drug shipment table buttons component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugDispensationDeleteButtonsProps {
    drugDispensation: Dtos.DrugDispensation | null;
    setDrugDispensationDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setDrugDispensationDelete: React.Dispatch<React.SetStateAction<Dtos.DrugDispensation | null>>
    canAdministerDrugManagement: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    button: {
        marginLeft: theme.spacing(20),
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const DrugDispensationTableButtons: React.FunctionComponent<IDrugDispensationDeleteButtonsProps> = ({
    drugDispensation,
    setDrugDispensationDeleteModalOpen,
    setDrugDispensationDelete,
    canAdministerDrugManagement
}) => {
    const classes = useStyles();
    
    const onDeleteClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();

        setDrugDispensationDelete(drugDispensation!!);
        setDrugDispensationDeleteModalOpen(true);
    }, [drugDispensation!!]);

    if (canAdministerDrugManagement) {
        return <>
            <IconButton 
            onClick={onDeleteClick}
            className={classes.button}
            size="small">    
                <FontAwesomeIcon icon={faTrash}/>
            </IconButton>
        </>;
    }

    return null;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default DrugDispensationTableButtons;