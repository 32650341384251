/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to get the form context.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import FieldContext, { IFieldContext } from '../contexts/FieldContext';

import { IFieldState, IFieldSubscription } from '../Form';

import useRelatedField from './useRelatedField';
import { IInputActions } from '../components/Input';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useRelatedInput = <TValue = any, TError = any>(path: string, subscription?: Partial<IFieldSubscription>, autoRegister?: boolean): { state: IFieldState<TValue, TError>, actions: IInputActions<TValue, TError>} => {
    const relatedField = useRelatedField<TValue, TError>(path, subscription, autoRegister);

    const actions: IInputActions<TValue, TError> = React.useMemo(() => {
        return {
            blur: () => {
                relatedField.context.setFocused(false);
            },
            clean: () => {
                relatedField.context.setDirty(false);
            },
            dirty: () => {
                relatedField.context.setDirty(true);
            },
            focus: () => {
                relatedField.context.setFocused(true);
            },
            touch: () => {
                relatedField.context.setTouched(true);
            },
            untouch: () => {
                relatedField.context.setTouched(false);
            },
            update: (newValue: TValue | null | undefined, silent ?: boolean) => {
                relatedField.context.setValue(newValue, silent);
            }
        }
    }, [relatedField.context.setValue, relatedField.context.setFocused, relatedField.context.setTouched, relatedField.context.setDirty]);

    const relatedInput = React.useMemo(() => {
        return {
            state: relatedField.state,
            actions
        };
    }, [relatedField.state, actions])

    return relatedInput;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useRelatedInput;