/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the quarantine pharmacy stock modal component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, Theme, makeStyles, Grid, Checkbox } from '@material-ui/core';

import { SubmitButton, Form } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from 'src/api/dtos';
import { IUsePharmacyActions } from '../hooks/usePharmacy';
import useModal from '../hooks/useModal';
import { selectDistinctObjects } from '../utilities/selectDistinctObjects';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    caption: {
        paddingBottom: theme.spacing(2)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IQuarantinePharmacyStockModalProps {
    pharmacyId: number;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    pharmacyActions: IUsePharmacyActions;
    availableDepotBatches: Dtos.DrugShipmentDepotBatch[] | null;
}

interface IQuarantinePharmacyStockFormProps {
    value: { pharmacyId: number, ids: number[] };
    pharmacyId: number;
    setValue: (value: { pharmacyId: number, ids: number[] }) => void
    setModalOpen: (open: boolean) => void;
    availableDepotBatches: Dtos.DrugShipmentDepotBatch[] | null;
}

const QuarantinePharmacyStockForm: React.FunctionComponent<IQuarantinePharmacyStockFormProps> = ({
    value,
    setValue,
    setModalOpen,
    availableDepotBatches
}) => {
    //todo - convert this form to use fields rather than using checkbox click events
    const classes = useStyles();

    const onCloseClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setModalOpen(false);
    }, [setModalOpen]);

    const distinctBatches = React.useMemo(() => !availableDepotBatches || availableDepotBatches.length === 0 ?
                [] :
                selectDistinctObjects(availableDepotBatches.map(dsdb => dsdb.depotBatch.batch),
                (reduceObject, returnObject) => reduceObject.id === returnObject.id),
                [availableDepotBatches]);

    const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const id = parseInt(event.target.value);

        if (isNaN(id)) {
            return;
        }

        if (event.target.checked) {
            if (!value.ids.includes(id)) {
                const batchIdsCopy = value.ids;
                batchIdsCopy.push(id);

                setValue({ ...value, ids: batchIdsCopy });
            }

        } else {
            const batchIdsCopy = value.ids.filter(x => x !== id);

            setValue({ ...value, ids: batchIdsCopy });
        }

    }, [value, setValue]);

    return (
        <>
            <DialogContent
            >
                <div
                    className={classes.caption}
                >
                    Warning: The selected batches will be marked as &quot;Quarantined&quot;. Select confirm to continue.
                </div>
                <Grid container>
                    <Grid item xs={4}>
                        <strong>Batch Number</strong>
                    </Grid>
                    <Grid item xs={4}>
                        <strong>Drug Name</strong>
                    </Grid>
                    <Grid item xs={4}>
                        <strong>Quarantine</strong>
                    </Grid>
                    {
                        distinctBatches !== null && 
                        distinctBatches.map((db, idx) => (
                            <Grid container key={idx} alignItems="center">
                                <Grid item xs={4}>
                                    {db.batchNo}
                                </Grid>
                                <Grid item xs={4}>
                                    {db.drug.drugName}
                                </Grid>
                                <Grid item xs={4}>
                                    <Checkbox value={db.id} onChange={e => handleChange(e)} />
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary">
                    Confirm
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const QuarantinePharmacyStockModal: React.FunctionComponent<IQuarantinePharmacyStockModalProps> = ({
    pharmacyId,
    modalOpen,
    setModalOpen,
    onCloseClick,
    onFormSave,
    pharmacyActions,
    availableDepotBatches
}) => {

    const initialValues = Object.assign({}, { pharmacyId: pharmacyId, ids: [] as number[] });

    const [value, setValue] = React.useState(initialValues);

    const {
        handleSubmit,
        onFormSubmitFailure
    } = useModal({
        entityName: 'batch',
        asyncSave: pharmacyActions.asyncQuarantineStock,
        onFormSave: onFormSave
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Quarantine Pharmacy Stock
                </DialogTitle>
                <Form
                    initialValues={value}
                    onSubmit={handleSubmit}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <QuarantinePharmacyStockForm
                        value={value}
                        setValue={v => setValue(v)}
                        pharmacyId={pharmacyId}
                        setModalOpen={setModalOpen}
                        availableDepotBatches={availableDepotBatches}
                    />
                </Form>
            </Dialog>
        </>
    );
}

export default QuarantinePharmacyStockModal;