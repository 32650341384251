
/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { ScreeningLog } from '../api/screeningLog';
import { Theme, makeStyles, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog, Typography } from '@material-ui/core';
import ScreeningLogForm from './ScreeningLogForm';
import ScreeningLogContext from '../context/ScreeningLogContext';
import useScreeningLogForm from '../hooks/useScreeningLogForm';
import { IFormLabel } from '@ngt/opms';
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export enum ScreeningLogDialogMode {
    cancel = 0,
    create = 1,
    update = 2,
    delete = 3
}

export interface IScreeningLogFormAndSubmitType<TScreeningLog extends ScreeningLog = ScreeningLog> {
    form: TScreeningLog;
    submitType: "save" | "submit" | "delete";
}

interface IScreeningLogDialogProps<TScreeningLog extends ScreeningLog = ScreeningLog> {
    mode?: ScreeningLogDialogMode,
    formCancel?: () => void,
    formFieldGroup?: any,
    formLabels?: IFormLabel[],
    formCreateSuccessfulSaveMessage?: (form: IScreeningLogFormAndSubmitType) => string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogTitle: {
        color: theme.palette.secondary.main,
        padding: theme.spacing(2.5, 3),
        fontSize: '1.5rem !important',
        fontWeight: 500,
        borderBottom: '1px solid rgb(224, 224, 224)'
    },
    dialogContent: {
        padding: 0
    },
    deleteText: {
        padding: theme.spacing(2),
        fontSize: '1.15rem',
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ScreeningLogDialog = ({
    mode,
    formCancel,
    formFieldGroup,
    formLabels,
    formCreateSuccessfulSaveMessage
}: IScreeningLogDialogProps) => {

    const classes = useStyles();

    const { screeningLog } = React.useContext(ScreeningLogContext);

    const title = React.useMemo(() => {
        return `${mode === ScreeningLogDialogMode.delete ? 'Delete' : ''} Screening Log`.trim();
    }, [mode, screeningLog]);

    const showDialog = React.useMemo(() => {
        return mode ?? ScreeningLogDialogMode.cancel > ScreeningLogDialogMode.cancel ? true : false;
    }, [mode]);

    const {
        handleSubmit,
        onFormCancel,
        onFormSave,
        onFormSubmit,
        onFormDelete,
        //onFormPreReg,
        //onFormFail,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate,
        allowSubmit
    } = useScreeningLogForm({ formType: undefined, afterFormSave: undefined, onCancel: formCancel, readOnly: false, createSuccessMessage: formCreateSuccessfulSaveMessage });

    return (
        <>
            {
                !!mode && <Dialog open={showDialog} onClose={undefined} aria-labelledby="form-dialog-title" fullWidth={true} scroll="body" maxWidth="md">
                    <Typography
                        variant="h4"
                        color="primary"
                        className={classes.dialogTitle}
                    >
                        {title}
                    </Typography>
                    <DialogContent className={classes.dialogContent}>
                        {
                            mode === ScreeningLogDialogMode.delete && (
                                <Typography className={classes.deleteText}>
                                    Are you sure you want to delete the following screening log?
                                </Typography>    
                            )
                        }
                        <ScreeningLogForm
                            allowSubmit={allowSubmit}
                            formDisable={
                                mode === ScreeningLogDialogMode.delete ||
                                (
                                    screeningLog?.outcome == Dtos.ScreeningOutcome.ProceedToRegistration &&
                                    (screeningLog?.status ?? Dtos.ScreeningStatus.Pending) != Dtos.ScreeningStatus.Pending 
                                )
                            }
                            formLabels={formLabels}
                            formFieldGroup={formFieldGroup}
                            formMode={mode}
                            onFormSubmit={onFormSubmit}
                            handleSubmit={handleSubmit}
                            onFormSave={onFormSave}
                            onFormDelete={onFormDelete}
                            onFormCancel={onFormCancel}
                            onSubmitFailed={onFormSubmitFailure}
                            onSubmitValidationFailure={onFormSubmitValidationFailure}
                            validate={validate}
                        />
                    </DialogContent>
                </Dialog>

            }
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningLogDialog;