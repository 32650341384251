/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving and saving a batch.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';




/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IBatchState {
    batch: Dtos.Batch | null;
    loadState: IRequestState<ResponseStatus>;
    saveState: IRequestState<ResponseStatus>;
};

export interface IBatchStore {
    batch: IBatchState;
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialBatchState: IBatchState = {
    batch: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class BatchReducer extends ImmerReducer<IBatchState> {
    public loadById(id: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(batch?: Dtos.Batch) {
        this.draftState.batch = batch ?? null;
        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus: ResponseStatus) {
        this.draftState.batch = null;
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public save(form: Dtos.Batch) {
        this.draftState.saveState = {
            state: RequestState.Pending
        };
    }

    public saveSuccess(batch?: Dtos.Batch) {
        this.draftState.batch = batch ?? null;
        this.draftState.saveState = {
            state: RequestState.Success
        };
    }

    public saveFailure(responseStatus: ResponseStatus) {
        this.draftState.batch = null;
        this.draftState.saveState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialBatchState };
    }
};

export const batchActions = createActionCreators(BatchReducer);
export const batchReducer = createReducerFunction(BatchReducer, initialBatchState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createBatchApi = (client: JsonServiceClient) => ({
    load: (id: number) => {
        return client.get(new Dtos.GetBatchById({
            id
        }));
    },
    save: (batch?: Dtos.Batch) => {
        
        return client.post(new Dtos.SetBatch({
            batch
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createBatchLogic = (api: ReturnType<typeof createBatchApi>) => {
    const logic = {
        load: createLogic<IBatchStore, {}, undefined, string, ReturnType<typeof batchActions.loadById>>({
            type: batchActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload;

                try {
                    const response = await api.load(id);

                    dispatch(batchActions.loadSuccess(
                        response.batch
                    ));
                }
                catch (error: any) {
                    dispatch(batchActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        save: createLogic<IBatchState, {}, undefined, string, ReturnType<typeof batchActions.save>>({
            type: batchActions.save.type,
            process: async ({ action }, dispatch, done) => {
                const batch = action.payload;
                
                try {
                    const response = await api.save(batch);

                    dispatch(batchActions.saveSuccess(
                        response.batch
                    ));
                }
                catch (error: any) {
                    dispatch(batchActions.saveFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }
    return [
        logic.load,
        logic.save
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useBatchSelector: TypedUseSelectorHook<IBatchStore> = useSelector;

export const batchSelectors = {
    selectBatch: (state: IBatchStore) => state.batch.batch,
    selectLoadState: (state: IBatchStore) => state.batch.loadState,
    selectSaveState: (state: IBatchStore) => state.batch.saveState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerBatchReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createBatchApi(client);

    const logic = createBatchLogic(api);

    reducerRegistry.register('batch', batchReducer as Reducer, logic as any);
};

export default registerBatchReducer;