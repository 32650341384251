/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the drug management
 * opms extension.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */
import { IOnlinePatientManagementExtension, OnlinePatientManagement, PatientInformationFn } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';

import registerBatchReducer from './store/modules/batch';
import registerBatchesReducer from './store/modules/batches';
import registerDepotReducer from './store/modules/depot';
import registerDepotBatchReducer from './store/modules/depotbatch';
import registerDepotBatchesReducer from './store/modules/depotbatches';
import registerDepotsReducer from './store/modules/depots';
import registerDrugDispensationReducer from './store/modules/drugdispensation';
import registerDrugDispensationsReducer from './store/modules/drugdispensations';
import registerDrugsReducer from './store/modules/drugs';
import registerDrugShipmentReducer from './store/modules/drugshipment';
import registerDrugShipmentDepotBatchReducer from './store/modules/drugshipmentdepotbatch';
import registerDrugShipmentDepotBatchesReducer from './store/modules/drugshipmentdepotbatches';
import registerDrugShipmentsReducer from './store/modules/drugshipments';
import registerDrugShipmentDocumentReducer from './store/modules/drugshipmentdocuments';
import registerDrugShipmentVerifyReducer from './store/modules/drugshipmentverify';
import registerOrderFormsReducer from './store/modules/orderforms';
import registerPatientDrugsReducer from './store/modules/patientdrugs';
import registerPatientsDrugsReducer from './store/modules/patientsdrugs';
import registerPharmaciesReducer from './store/modules/pharmacies';
import registerPharmacistsReducer from './store/modules/pharmacists';
import registerPharmacyReducer from './store/modules/pharmacy';
import registerTimepointsReducer from './store/modules/timepoints';

import DrugManagementContext from './context/DrugManagementContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugManagementExtensionReducerOptions {
    depots?: boolean | null;
    depot?: boolean | null;
    orderForms?: boolean | null;
    batches?: boolean | null;
    depotBatches?: boolean | null;
    depotBatch?: boolean | null;
    batch?: boolean | null;
    drugs?: boolean | null;
};

interface IDrugManagementStoreOptions {
    initialiseReducers?: boolean | null | IDrugManagementExtensionReducerOptions;
};

export interface IDrugManagementExtensionOptions {
    /**
     * The configuration options to use when creating the redux store.
     *
     * (Defaults used if not provided).
     */
    storeOptions?: IDrugManagementStoreOptions | null;

    baseUrl?: string;

    defaultUnit?: string;

    randomisedStatus?: number;

    treatments?: string[];

    allowEditConsignmentNumber?: boolean;

    consignmentNumberLabel?: string;

    maxDrugsPerDispensation?: number;

    patientCaption?: PatientInformationFn;

    allowDrugShipmentPharmacistSelection?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class handles the global context used by the OPMS.
 */
export class DrugManagementExtension implements IOnlinePatientManagementExtension {
    private reducerOptions: boolean | IDrugManagementExtensionReducerOptions;
    public ProviderComponent: React.ComponentType<{
        children: React.ReactNode;
    }> | undefined;

    /**
     * Creates a new OnlinePatientManagement using the provided configuration options.
     * @param options Configuration Options
     */
    constructor(options: IDrugManagementExtensionOptions) {
        this.initialiseReducerOptions(options?.storeOptions?.initialiseReducers);
        this.initialiseProviderComponent(options);
    }

    private initialiseReducerOptions(reducerOptions?: boolean | IDrugManagementExtensionReducerOptions | null) {
        this.reducerOptions = reducerOptions ?? true;
    }

    private initialiseProviderComponent(options: IDrugManagementExtensionOptions) {
        const context = {
            baseUrl: options?.baseUrl ?? '/drug-management',
            defaultUnit: options?.defaultUnit ?? '',
            randomisedStatus: options?.randomisedStatus ?? -1,
            treatments: options?.treatments ?? ['','Arm A','Arm B'],
            allowEditConsignmentNumber: options?.allowEditConsignmentNumber ?? true,
            consignmentNumberLabel: options?.consignmentNumberLabel ?? 'Consignment Number',
            maxDrugsPerDispensation: options?.maxDrugsPerDispensation,
            patientCaption: options?.patientCaption,
            allowDrugShipmentPharmacistSelection : options?.allowDrugShipmentPharmacistSelection
        }

        this.ProviderComponent = ({ children }) => {
            return (
                <DrugManagementContext.Provider value={context}>
                    {children}
                </DrugManagementContext.Provider>
            );
        }
    }


    /**
     * This method initialises the Reducers in the Reducer Registry.
     * @param onlinePatientManagement Online Patient Management.
     */
    public initialiseReducers(onlinePatientManagement: OnlinePatientManagement) {
        // Check if reducer initialisation was requested to be skipped.
        if (this.reducerOptions === false) {
            return;
        }

        if (this.reducerOptions === true || this.reducerOptions?.depots !== false) {
            // Register Authenticated User Reducer if requested.
            registerDepotsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.depot !== false) {
            // Register Authenticated User Reducer if requested.
            registerDepotReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.orderForms !== false) {
            // Register Authenticated User Reducer if requested.
            registerOrderFormsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.batches !== false) {
            // Register Batches Reducer if requested.
            registerBatchesReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.depotBatches !== false) {
            // Register Depot Batches Reducer if requested.
            registerDepotBatchesReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.depotBatch !== false) {
            // Register Depot Batch Reducer if requested.
            registerDepotBatchReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.batch !== false) {
            // Register Batch Reducer if requested.
            registerBatchReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.drugs !== false) {
            // Register Drugs Reducer if requested.
            registerDrugsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        registerDrugDispensationReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerDrugDispensationsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerDrugShipmentReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerDrugShipmentDepotBatchReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerDrugShipmentDepotBatchesReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerDrugShipmentsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerDrugShipmentVerifyReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerPatientDrugsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerPatientsDrugsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerPharmaciesReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerPharmacistsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerPharmacyReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerTimepointsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        registerDrugShipmentDocumentReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default OnlinePatientManagement;