/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the lookups reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IIndividualLookupsState {
    lookups: Dtos.ILookup[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface ILookupsState {
    byIds: Record<string, IIndividualLookupsState>;
}

export interface ILookupsStore {
    lookups: ILookupsState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (formDefinitionId?: number | null) => {
    return `${(formDefinitionId ?? 'null')}`
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all lookups reducer state changes and actions. 
 */
export class LookupsReducer extends ImmerReducer<ILookupsState>
{
    public loadByIds(formDefinitionId?: number | null) {
        const context = createIdsContext(formDefinitionId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualLookupsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByIdsSuccess(formDefinitionId?: number | null, lookups?: Dtos.ILookup[]) {
        const context = createIdsContext(formDefinitionId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualLookupsState };
        }

        this.draftState.byIds[context].lookups = lookups ? lookups : null;

        this.draftState.byIds[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdsFailure(formDefinitionId?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(formDefinitionId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualLookupsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByIds(formDefinitionId?: number | null) {
        const context = createIdsContext(formDefinitionId);

        this.draftState.byIds[context] = { ...initialIndividualLookupsState }
    }

    public clearAll() {
        this.draftState = { ...initialLookupsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialLookupsState: ILookupsState = {
    byIds: {}
}

export const initialIndividualLookupsState: IIndividualLookupsState = {
    lookups: null,
    loadState: {
        state: RequestState.None
    }
}

export const lookupsActions = createActionCreators(LookupsReducer);
export const lookupsReducer = createReducerFunction(LookupsReducer, initialLookupsState);

const createLookupsApi = (client: JsonServiceClient) => ({
    loadByIds: (formDefinitionId?: number) => {
        return client.get(new Dtos.LookupGetCollectionByFormDefinitionId({ formDefinitionId }));
    }
});

const createLookupsLogic = (api: ReturnType<typeof createLookupsApi>) => {
    const logic = {
        loadByIds: createLogic<ILookupsStore, {}, undefined, string, ReturnType<typeof lookupsActions.loadByIds>>({
            type: lookupsActions.loadByIds.type,
            process: async ({ action }, dispatch, done) => {
                const formDefinitionId = action.payload as unknown as number | null | undefined;

                try {
                    const response = await api.loadByIds(formDefinitionId ?? undefined);

                    dispatch(lookupsActions.loadByIdsSuccess(
                        formDefinitionId,
                        response.lookups
                    ));
                }
                catch (error: any) {
                    dispatch(lookupsActions.loadByIdsFailure(formDefinitionId, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadByIds
    ]
};

export const useLookupsSelector: TypedUseSelectorHook<ILookupsStore> = useSelector;

export const lookupsSelectors = {
    lookupsByIds: (state: ILookupsStore, formDefinitionId?: number | null) => {
        const context = createIdsContext(formDefinitionId);

        if (!state.lookups.byIds[context]) {
            return initialIndividualLookupsState.lookups;
        }

        return state.lookups.byIds[context].lookups;
    },
    loadStateByIds: (state: ILookupsStore, formDefinitionId?: number | null) => {
        const context = createIdsContext(formDefinitionId);

        if (!state.lookups.byIds[context]) {
            return initialIndividualLookupsState.loadState;
        }

        return state.lookups.byIds[context].loadState;
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerLookupsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createLookupsApi(client);

    const logic = createLookupsLogic(api);

    reducerRegistry.register('lookups', lookupsReducer as Reducer, logic as any);
};

export default registerLookupsReducer;