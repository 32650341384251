/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving drugShipments.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDrugShipmentsState {
    drugShipments: Dtos.DrugShipment[] | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IDrugShipmentsStore {
    drugShipments: IDrugShipmentsState;
};

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

const initialDrugShipmentsState: IDrugShipmentsState = {
    drugShipments: null,
    loadState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DrugShipmentsReducer extends ImmerReducer<IDrugShipmentsState> {
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(drugShipments?: Dtos.DrugShipment[]) {
        this.draftState.drugShipments = drugShipments ?? null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDrugShipmentsState };
    }
};

export const drugShipmentsActions = createActionCreators(DrugShipmentsReducer);
export const drugShipmentsReducer = createReducerFunction(DrugShipmentsReducer, initialDrugShipmentsState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDrugShipmentsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.GetDrugShipments());
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDrugShipmentsLogic = (api: ReturnType<typeof createDrugShipmentsApi>) => {
    const logic = {
        load: createLogic<IDrugShipmentsStore, {}, undefined, string, ReturnType<typeof drugShipmentsActions.load>>({
            type: drugShipmentsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(drugShipmentsActions.loadSuccess(
                        response.drugShipments
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }
    return [
        logic.load
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDrugShipmentsSelector: TypedUseSelectorHook<IDrugShipmentsStore> = useSelector;

export const drugShipmentsSelectors = {
    drugShipments: (state: IDrugShipmentsStore) => state.drugShipments.drugShipments,
    loadState: (state: IDrugShipmentsStore) => state.drugShipments.loadState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDrugShipmentsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDrugShipmentsApi(client);

    const logic = createDrugShipmentsLogic(api);

    reducerRegistry.register('drugShipments', drugShipmentsReducer as Reducer, logic as any);
};

export default registerDrugShipmentsReducer;