/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving depotBatches.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';




/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDepotBatchesState {
    depotBatches: Dtos.DepotBatch[] | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IDepotBatchesStore {
    depotBatches: IDepotBatchesState;
};

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

const initialDepotBatchesState: IDepotBatchesState = {
    depotBatches: null,
    loadState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DepotBatchesReducer extends ImmerReducer<IDepotBatchesState> {
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadByDepotId(depotId: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadByPharmacyId(pharmacyId: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadByInstCode(instCode: string) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadByDrugShipmentId(drugShipmentId: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(depotBatches?: Dtos.DepotBatch[]) {
        this.draftState.depotBatches = depotBatches ?? null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDepotBatchesState };
    }
};

export const depotBatchesActions = createActionCreators(DepotBatchesReducer);
export const depotBatchesReducer = createReducerFunction(DepotBatchesReducer, initialDepotBatchesState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDepotBatchesApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.GetDepotBatches());
    },
    loadByDepotId: (depotId: number) => {
        return client.get(new Dtos.GetDepotBatchesByDepotId({
            depotId
        }));
    },
    loadByPharmacyId: (pharmacyId: number) => {
        return client.get(new Dtos.GetDepotBatchesByPharmacyId({
            pharmacyId
        }));
    },
    loadByInstCode: (instCode: string) => {
        return client.get(new Dtos.GetDepotBatchesByInstitutionCode({
            instCode
        }));
    },
    loadByDrugShipmentId: (drugShipmentId: number) => {
        return client.get(new Dtos.GetDepotBatchesByDrugShipmentId({
            drugShipmentId
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDepotBatchesLogic = (api: ReturnType<typeof createDepotBatchesApi>) => {
    const logic = {
        load: createLogic<IDepotBatchesStore, {}, undefined, string, ReturnType<typeof depotBatchesActions.load>>({
            type: depotBatchesActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(depotBatchesActions.loadSuccess(
                        response.depotBatches
                    ));
                }
                catch (error: any) {
                    dispatch(depotBatchesActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByDepotId: createLogic<IDepotBatchesState, {}, undefined, string, ReturnType<typeof depotBatchesActions.loadByDepotId>>({
            type: depotBatchesActions.loadByDepotId.type,
            process: async ({ action }, dispatch, done) => {
                const depotId = action.payload;

                try {
                    const response = await api.loadByDepotId(depotId);

                    dispatch(depotBatchesActions.loadSuccess(
                        response.depotBatches
                    ));
                }
                catch (error: any) {
                    dispatch(depotBatchesActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByPharmacyId: createLogic<IDepotBatchesState, {}, undefined, string, ReturnType<typeof depotBatchesActions.loadByPharmacyId>>({
            type: depotBatchesActions.loadByPharmacyId.type,
            process: async ({ action }, dispatch, done) => {
                const pharmacyId = action.payload;

                try {
                    const response = await api.loadByPharmacyId(pharmacyId);

                    dispatch(depotBatchesActions.loadSuccess(
                        response.depotBatches
                    ));
                }
                catch (error: any) {
                    dispatch(depotBatchesActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByInstCode: createLogic<IDepotBatchesState, {}, undefined, string, ReturnType<typeof depotBatchesActions.loadByInstCode>>({
            type: depotBatchesActions.loadByInstCode.type,
            process: async ({ action }, dispatch, done) => {
                const instCode = action.payload;

                try {
                    const response = await api.loadByInstCode(instCode);

                    dispatch(depotBatchesActions.loadSuccess(
                        response.depotBatches
                    ));
                }
                catch (error: any) {
                    dispatch(depotBatchesActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByDrugShipmentId: createLogic<IDepotBatchesState, {}, undefined, string, ReturnType<typeof depotBatchesActions.loadByDrugShipmentId>>({
            type: depotBatchesActions.loadByDrugShipmentId.type,
            process: async ({ action }, dispatch, done) => {
                const drugShipmentId = action.payload;

                try {
                    const response = await api.loadByDrugShipmentId(drugShipmentId);

                    dispatch(depotBatchesActions.loadSuccess(
                        response.depotBatches
                    ));
                }
                catch (error: any) {
                    dispatch(depotBatchesActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }
    return [
        logic.load,
        logic.loadByDepotId,
        logic.loadByPharmacyId,
        logic.loadByInstCode,
        logic.loadByDrugShipmentId
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDepotBatchesSelector: TypedUseSelectorHook<IDepotBatchesStore> = useSelector;

export const depotBatchesSelectors = {
    depotBatches: (state: IDepotBatchesStore) => state.depotBatches.depotBatches,
    loadState: (state: IDepotBatchesStore) => state.depotBatches.loadState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDepotBatchesReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDepotBatchesApi(client);

    const logic = createDepotBatchesLogic(api);

    reducerRegistry.register('depotBatches', depotBatchesReducer as Reducer, logic as any);
};

export default registerDepotBatchesReducer;