/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component for the select icon.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

/*
 * Used to identify request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to type master groups
 */
import * as Dtos from '../../api/dtos';

/*
 * Used to consistently identify and name the all master groups option.
 */
import { ALL_INSTITUTIONS_CODE, ALL_INSTITUTIONS } from '../../constants/institution';

/*
 * Used for basic selector logic.
 */ 
import CodeSelector from './CodeSelector';

/*
 * Used to load institutions
 */
import useInstitutionsByCodes from '../../hooks/data/useInstitutionsByCodes';
import { ALL_MASTER_GROUPS_CODE } from '../../constants/masterGroup';
import { ALL_COLLABORATING_GROUPS_CODE } from '../../constants/collaboratingGroup';
import { ALL_COUNTRIES_CODE } from '../../constants/country';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IInstitutionSelectorProps {
    masterGroupCode?: string | null;
    collaboratingGroupCode?: string | null;
    countryCode?: string | null;
    allowAll?: boolean | null;
    onChange?: (code: string | null | undefined) => void;
    value?: string | null;
}

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const InstitutionSelector: React.FunctionComponent<IInstitutionSelectorProps> = ({
    allowAll,
    onChange,
    collaboratingGroupCode,
    masterGroupCode,
    countryCode,
    value,
}) => {

    const [institutions, loadState] = useInstitutionsByCodes(masterGroupCode === ALL_MASTER_GROUPS_CODE ? null : masterGroupCode, collaboratingGroupCode === ALL_COLLABORATING_GROUPS_CODE ? null : collaboratingGroupCode, countryCode === ALL_COUNTRIES_CODE ? null : countryCode, true);

    const onSelectedItemChange = React.useCallback((code: string | null | undefined) => {
        if (onChange) {
            onChange(code);
        }
    }, [onChange]);

    return (
        <CodeSelector
            inputId="institution"
            label="Institution"
            onChange={onSelectedItemChange as any}
            selection={value}
            loading={loadState.state === RequestState.None || loadState.state === RequestState.Pending}
            allSelectionAllowed={allowAll}
            allSelectionLabel={ALL_INSTITUTIONS}
            allSelectionValue={ALL_INSTITUTIONS_CODE}
            items={institutions}
        />
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default InstitutionSelector;
