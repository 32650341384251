/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the eventDefinition reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualEventDefinitionState {
    eventDefinition: Dtos.IEventDefinition | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IEventDefinitionState {
    byId: Record<number, IIndividualEventDefinitionState>;
    byCode: Record<string, IIndividualEventDefinitionState>;
}

export interface IEventDefinitionStore {
    eventDefinition: IEventDefinitionState;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all eventDefinition reducer state changes and actions. 
 */
export class EventDefinitionReducer extends ImmerReducer<IEventDefinitionState>
{
    public loadById(id: number) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualEventDefinitionState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number, eventDefinition?: Dtos.IEventDefinition) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualEventDefinitionState };
        }

        this.draftState.byId[id].eventDefinition = eventDefinition ? eventDefinition : null;

        this.draftState.byId[id].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualEventDefinitionState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public loadByCode(code: string) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualEventDefinitionState };
        }

        this.draftState.byCode[code].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByCodeSuccess(code: string, eventDefinition?: Dtos.IEventDefinition) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualEventDefinitionState };
        }

        this.draftState.byCode[code].eventDefinition = eventDefinition ? eventDefinition : null;

        this.draftState.byCode[code].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodeFailure(code: string, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualEventDefinitionState };
        }

        this.draftState.byCode[code].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number) {
        if (this.draftState.byId[id]) {
            delete this.draftState.byId[id];
        }
    }

    public clearByCode(code: string) {
        if (this.draftState.byCode[code]) {
            delete this.draftState.byCode[code];
        }
    }

    public clearAll() {
        this.draftState = { ...initialEventDefinitionState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualEventDefinitionState: IIndividualEventDefinitionState = {
    eventDefinition: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialEventDefinitionState: IEventDefinitionState = {
    byCode: {},
    byId: {}
}

export const eventDefinitionActions = createActionCreators(EventDefinitionReducer);
export const eventDefinitionReducer = createReducerFunction(EventDefinitionReducer, initialEventDefinitionState);

const createEventDefinitionApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.EventDefinitionGetSingleById({ id }));
    },
    loadByCode: (code: string) => {
        return client.get(new Dtos.EventDefinitionGetSingleByCode({ code }));
    }
});

export const createEventDefinitionLogic = (api: ReturnType<typeof createEventDefinitionApi>) => {
    const logics = {
        loadById: createLogic<IEventDefinitionStore, {}, undefined, string, ReturnType<typeof eventDefinitionActions.loadById>>({
            type: eventDefinitionActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadById(action.payload);

                    dispatch(eventDefinitionActions.loadByIdSuccess(
                        action.payload,
                        response.eventDefinition
                    ));
                }
                catch (error: any) {
                    dispatch(eventDefinitionActions.loadByIdFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCode: createLogic<any, {}, undefined, string, ReturnType<typeof eventDefinitionActions.loadByCode>>({
            type: eventDefinitionActions.loadByCode.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadByCode(action.payload);
                    dispatch(eventDefinitionActions.loadByCodeSuccess(
                        action.payload,
                        response.eventDefinition
                    ));
                }
                catch (error: any) {
                    dispatch(eventDefinitionActions.loadByCodeFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadByCode,
        logics.loadById
    ]
}

export const useEventDefinitionSelector: TypedUseSelectorHook<IEventDefinitionStore> = useSelector;

export const eventDefinitionSelectors = {
    eventDefinitionById: (state: IEventDefinitionStore, id: number) => state.eventDefinition.byId[id]?.eventDefinition ?? null,
    loadStateById: (state: IEventDefinitionStore, id: number) => state.eventDefinition.byId[id]?.loadState ?? initialIndividualEventDefinitionState.loadState,
    eventDefinitionByCode: (state: IEventDefinitionStore, code: string) => state.eventDefinition.byCode[code]?.eventDefinition ?? null,
    loadStateByCode: (state: IEventDefinitionStore, code: string) => state.eventDefinition.byCode[code]?.loadState ?? initialIndividualEventDefinitionState.loadState,
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerEventDefinitionReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createEventDefinitionApi(client);

    const logic = createEventDefinitionLogic(api);

    reducerRegistry.register('eventDefinition', eventDefinitionReducer as Reducer, logic as any);
};

export default registerEventDefinitionReducer;