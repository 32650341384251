/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving and saving a drugShipment.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';



/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDrugShipmentState {
    drugShipment: Dtos.DrugShipment | null;
    loadState: IRequestState<ResponseStatus>;
    saveState: IRequestState<ResponseStatus>;
    setStatusState: IRequestState<ResponseStatus>;
    deleteState: IRequestState<ResponseStatus>;
};

export interface IDrugShipmentStore {
    drugShipment: IDrugShipmentState;
};

export interface IDeleteDrugShipmentForm {
    id: number;
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

const initialDrugShipmentState: IDrugShipmentState = {
    drugShipment: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    },
    setStatusState: {
        state: RequestState.None
    },
    deleteState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DrugShipmentReducer extends ImmerReducer<IDrugShipmentState> {
    public loadById(id: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(drugShipment?: Dtos.DrugShipment) {
        this.draftState.drugShipment = drugShipment ?? null;
        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus: ResponseStatus) {
        this.draftState.drugShipment = null;
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public save(form: Dtos.DrugShipment) {
        this.draftState.saveState = {
            state: RequestState.Pending
        };
    }

    public saveSuccess(drugShipment?: Dtos.DrugShipment) {
        this.draftState.drugShipment = drugShipment ?? null;
        this.draftState.saveState = {
            state: RequestState.Success
        };
    }

    public saveFailure(responseStatus: ResponseStatus) {
        this.draftState.drugShipment = null;
        this.draftState.saveState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public setStatus(form: Dtos.DrugShipment) {
        this.draftState.saveState = {
            state: RequestState.Pending
        };
    }

    public setStatusSuccess(drugShipment?: Dtos.DrugShipment) {
        this.draftState.drugShipment = drugShipment ? drugShipment : null;
        this.draftState.setStatusState = {
            state: RequestState.Success
        };
    }

    public setStatusFailure(responseStatus: ResponseStatus) {
        this.draftState.drugShipment = null;
        this.draftState.setStatusState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public deleteById(form: IDeleteDrugShipmentForm) {
        this.draftState.deleteState = {
            state: RequestState.Pending
        };
    }

    public deleteSuccess(form?: IDeleteDrugShipmentForm) {
        this.draftState.drugShipment = null;
        this.draftState.deleteState = {
            state: RequestState.Success
        };
    }

    public deleteFailure(responseStatus: ResponseStatus) {
        this.draftState.deleteState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDrugShipmentState };
    }
};

export const drugShipmentActions = createActionCreators(DrugShipmentReducer);
export const drugShipmentReducer = createReducerFunction(DrugShipmentReducer, initialDrugShipmentState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDrugShipmentApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.GetDrugShipmentById({
            id
        }));
    },
    save: (drugShipment?: Dtos.DrugShipment) => {
        return client.post(new Dtos.SetDrugShipment({
            drugShipment
        }));
    },
    setStatus: (drugShipment?: Dtos.DrugShipment) => {
        return client.post(new Dtos.SetDrugShipmentStatus({
            drugShipment
        }));
    },
    deleteById: (id: number) => {
        return client.post(new Dtos.DeleteDrugShipment({
            drugShipmentId: id
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDrugShipmentLogic = (api: ReturnType<typeof createDrugShipmentApi>) => {
    const logic = {
        loadById: createLogic<IDrugShipmentState, {}, undefined, string, ReturnType<typeof drugShipmentActions.loadById>>({
            type: drugShipmentActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload;

                try {
                    const response = await api.loadById(id);

                    dispatch(drugShipmentActions.loadSuccess(
                        response.drugShipment
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        save: createLogic<IDrugShipmentState, {}, undefined, string, ReturnType<typeof drugShipmentActions.save>>({
            type: drugShipmentActions.save.type,
            process: async ({ action }, dispatch, done) => {
                const drugShipment = action.payload;

                try {
                    const response = await api.save(drugShipment);

                    dispatch(drugShipmentActions.saveSuccess(
                        response.drugShipment
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentActions.saveFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        setStatus: createLogic<IDrugShipmentState, {}, undefined, string, ReturnType<typeof drugShipmentActions.setStatus>>({
            type: drugShipmentActions.setStatus.type,
            process: async ({ action }, dispatch, done) => {
                const drugShipment = action.payload;

                try {
                    const response = await api.setStatus(drugShipment);

                    dispatch(drugShipmentActions.setStatusSuccess(
                        response.drugShipment
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentActions.setStatusFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        deleteById: createLogic<IDrugShipmentState, {}, undefined, string, ReturnType<typeof drugShipmentActions.deleteById>>({
            type: drugShipmentActions.deleteById.type,
            process: async ({ action }, dispatch, done) => {
                const form = action.payload;

                try {
                    const response = await api.deleteById(form.id);

                    dispatch(drugShipmentActions.deleteSuccess(
                        form
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentActions.deleteFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadById,
        logic.save,
        logic.setStatus,
        logic.deleteById
    ];
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDrugShipmentSelector: TypedUseSelectorHook<IDrugShipmentStore> = useSelector;

export const drugShipmentSelectors = {
    selectDrugShipment: (state: IDrugShipmentStore) => state.drugShipment.drugShipment,
    selectLoadState: (state: IDrugShipmentStore) => state.drugShipment.loadState,
    selectSaveState: (state: IDrugShipmentStore) => state.drugShipment.saveState,
    selectSetStatusState: (state: IDrugShipmentStore) => state.drugShipment.setStatusState,
    selectDeleteState: (state: IDrugShipmentStore) => state.drugShipment.deleteState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDrugShipmentReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDrugShipmentApi(client);

    const logic = createDrugShipmentLogic(api);

    reducerRegistry.register('drugShipment', drugShipmentReducer as Reducer, logic as any);
};

export default registerDrugShipmentReducer;