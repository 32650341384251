/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use a depot by the id
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    ResponseStatus,
    useAsyncFunction,
    BoundActionCreator,
    TypedFunction,
    bindActionCreatorsWithType,
    OmitFirstArg
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

import {
    depotActions,
    useDepotSelector,
    depotSelectors,
    IDepotStore
} from '../store/modules/depot';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseDepotActions {
    load: BoundActionCreator<OmitFirstArg<typeof depotActions.load>>;
    clear: BoundActionCreator<OmitFirstArg<typeof depotActions.clear>>;
    save: BoundActionCreator<OmitFirstArg<typeof depotActions.save>>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<OmitFirstArg<typeof depotActions.save>>>, Promise<Dtos.Depot | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useDepot = (id?: number, autoLoad?: boolean): [
    Dtos.Depot | null,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IUseDepotActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncSave = useAsyncFunction(depotActions.save, depotActions.saveSuccess, depotActions.saveFailure);

    const actions: IUseDepotActions = React.useMemo(() => {
        const load = () => depotActions.load(id!!);
        load.type = depotActions.load.type;

        const clear = () => depotActions.clear(id!!);
        clear.type = depotActions.clear.type;

        const save = (depot?: Dtos.Depot) => depotActions.save(id, depot);
        save.type = depotActions.save.type;

        const asyncSave = async (depot?: Dtos.Depot) => {
            const [, savedDepot] = await unboundAsyncSave([id, depot]);

            return savedDepot;
        };

        return {
            ...bindActionCreatorsWithType({
                load,
                clear,
                save,
            }, dispatch),
            asyncSave
        };
    }, [depotActions, id, dispatch, unboundAsyncSave]);

    const depotSelector = React.useCallback((state: IDepotStore) => {
        return depotSelectors.depot(state, id)
    }, [depotSelectors.depot, id]);

    const loadStateSelector = React.useCallback((state: IDepotStore) => {
        return depotSelectors.loadState(state, id)
    }, [depotSelectors.loadState, id]);

    const saveStateSelector = React.useCallback((state: IDepotStore) => {
        return depotSelectors.saveState(state, id)
    }, [depotSelectors.saveState, id]);

    const depot = useDepotSelector(depotSelector);

    const loadState = useDepotSelector(loadStateSelector);

    const saveState = useDepotSelector(saveStateSelector);

    React.useEffect(() => {
        if (autoLoad && id) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, id]);

    return [
        depot,
        loadState,
        saveState,
        actions
    ];
}