/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving drugDispensations.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';




/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDrugDispensationsState {
    drugDispensations: Dtos.DrugDispensation[] | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IDrugDispensationsStore {
    drugDispensations: IDrugDispensationsState;
};

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

const initialDrugDispensationsState: IDrugDispensationsState = {
    drugDispensations: null,
    loadState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DrugDispensationsReducer extends ImmerReducer<IDrugDispensationsState> {
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadByPatientId(patientId: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(drugDispensations?: Dtos.DrugDispensation[]) {
        this.draftState.drugDispensations = drugDispensations ?? null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDrugDispensationsState };
    }
};

export const drugDispensationsActions = createActionCreators(DrugDispensationsReducer);
export const drugDispensationsReducer = createReducerFunction(DrugDispensationsReducer, initialDrugDispensationsState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDrugDispensationsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.GetDrugDispensations());
    },
    loadByPatientId: (patientId: number) => {
        return client.get(new Dtos.GetDrugDispensationByPatientId({
            patientId
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDrugDispensationsLogic = (api: ReturnType<typeof createDrugDispensationsApi>) => {
    const logic = {
        load: createLogic<IDrugDispensationsStore, {}, undefined, string, ReturnType<typeof drugDispensationsActions.load>>({
            type: drugDispensationsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(drugDispensationsActions.loadSuccess(
                        response.drugDispensations
                    ));
                }
                catch (error: any) {
                    dispatch(drugDispensationsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByPatientId: createLogic<IDrugDispensationsState, {}, undefined, string, ReturnType<typeof drugDispensationsActions.loadByPatientId>>({
            type: drugDispensationsActions.loadByPatientId.type,
            process: async ({ action }, dispatch, done) => {
                const patientId = action.payload;

                try {
                    const response = await api.loadByPatientId(patientId);

                    dispatch(drugDispensationsActions.loadSuccess(
                        response.drugDispensations
                    ));
                }
                catch (error: any) {
                    dispatch(drugDispensationsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }
    return [
        logic.load,
        logic.loadByPatientId
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDrugDispensationsSelector: TypedUseSelectorHook<IDrugDispensationsStore> = useSelector;

export const drugDispensationsSelectors = {
    drugDispensations: (state: IDrugDispensationsStore) => state.drugDispensations.drugDispensations,
    loadState: (state: IDrugDispensationsStore) => state.drugDispensations.loadState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDrugDispensationsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDrugDispensationsApi(client);

    const logic = createDrugDispensationsLogic(api);

    reducerRegistry.register('drugDispensations', drugDispensationsReducer as Reducer, logic as any);
};

export default registerDrugDispensationsReducer;