/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae investigator review form component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { makeStyles, Button, List, ListItem, Table, TableHead, TableRow, TableBody, TableCell, Box } from '@material-ui/core';

import { InstitutionContext, Form, SubmitButton, Field, TextArea, FieldGroup, FormGrid, IFormGridCell, Input, OnlinePatientManagementContext, RouteLoading, Text, Checkbox, Select, FormLookupContext, GetFieldLookup, useFieldState, useFormActions, getParentPropertyPath, ICrfConditionParameters, CrfCondition } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext';
import SaeReviewContext from '../context/SaeReviewContext';
import useSaeMedicalReviewForm from '../hooks/useSaeMedicalReviewForm';
import { grey } from '@material-ui/core/colors';
import { get } from 'lodash-es';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeMedicalReviewFormProps {
    patientStudyNumber?: string,
    saeNumber?: number;
}

interface ISaeRejectionForm {
    reason?: string;
    followUpNumber?: number;
}

interface IInputDisplayProps {
    convert?: (value: any, lookup?: Dtos.Lookup) => any;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const treatmentPropertyPath = 'lastDoseStudyDrugs.drugName'
const relatednessPropertyPath = 'lastDoseStudyDrugs.drugRelatedToEvent'
const medicalReviewRelatednessPropertyPath = 'MedicalReview.Relatedness'
const expectednessPropertyPath = 'MedicalReview.Expectedness'

const useSaeMedicalReview = (patientStudyNumber: string, saeNumber: number) => {
    const [form, setForm] = React.useState<Dtos.MedicalReview | null>(null);
    const [formLoading, setFormLoading] = React.useState(true);
    const [formError, setFormError] = React.useState(false);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);
    const client = onlinePatientManagement?.serviceStackClient;

    React.useEffect(() => {
        const getForm = async () => {
            setFormLoading(true);
            setFormError(false);

            try {
                const response = await client.get(new Dtos.SaeMedicalReviewSingleByPatientStudyNumber({
                    patientStudyNumber,
                    saeNumber
                }));

                setForm(response.medicalReview);
            }
            catch {
                setFormError(true);
            }

            setFormLoading(false);
        }

        getForm();
    }, [patientStudyNumber, saeNumber, client]);

    return {
        form,
        formLoading,
        formError
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    instruction: {
        fontWeight: "bold",
        marginTop: theme.spacing(2)
    },
    ml0: {
        marginLeft: theme.spacing(-1)
    },
    mt1: {
        marginTop: theme.spacing(3)
    },
    reviewButton: {
        textAlign: 'right',
    },
    loading: {
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    section: {
        margin: theme.spacing(3, 0)
    },
    static: {
        background: grey[200]
    },
    tableHeader: {
        fontWeight: 'bold',
        fontSize: '1rem',
        background: grey[200]
    },
    narrative: {
        '& > *': {
            '& > *': {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            }
        },
        '& *': {
            paddingTop: '0 !important'
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const isApplicable = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.MedicalReview>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.MedicalReviewItem = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.notApplicable !== true;
}

const isRelated = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.MedicalReview>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.MedicalReviewItem = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.notApplicable !== true && (
        row.relatednessId === Dtos.SaeRelatedness.Possible as number ||
        row.relatednessId === Dtos.SaeRelatedness.Probable as number ||
        row.relatednessId === Dtos.SaeRelatedness.Definite as number
    );
}

const InputDisplay: React.FunctionComponent<IInputDisplayProps> = ({
    convert
}) => {
    const lookup = React.useContext(FormLookupContext);

    const { value, name } = useFieldState({ value: true });

    return (
        <>
            {convert ? convert(value, lookup) : value}
        </>
    );

}

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */

const SaeMedicalReviewForm: React.FunctionComponent<ISaeMedicalReviewFormProps> = ({
    patientStudyNumber,
    saeNumber
}) => {
    const classes = useStyles();

    const { sae, loadState: saeLoadState, actions: saeActions } = React.useContext(SaeContext);

    const { hasMedicalReview } = React.useContext(SaeReviewContext);

    const showMedicalReviewForm = React.useMemo(() => {
        return sae?.medicalReviewStatus === Dtos.SaeMedicalReviewStatus.Pending && !!hasMedicalReview;
    }, [sae?.medicalReviewStatus, hasMedicalReview]);

    const { form: initialMedicalReview, formLoading: initialMedicalReviewLoading, formError: initialMedicalReviewError } = useSaeMedicalReview(patientStudyNumber!, +saeNumber!);

    const { trialName, organisationName, organisationLogo } = React.useContext(OnlinePatientManagementContext);

    const treatmentLookup = React.useMemo(() => {
        return GetFieldLookup(sae?.lookups!, treatmentPropertyPath) ?? undefined;
    }, [sae]);

    const relatednessLookup = React.useMemo(() => {
        return GetFieldLookup(sae?.lookups!, relatednessPropertyPath) ?? undefined;;
    }, [sae]);

    const expectednessLookup = React.useMemo(() => {
        return GetFieldLookup(sae?.lookups!, expectednessPropertyPath) ?? undefined;;
    }, [sae]);

    const medicalReviewRelatednessLookup = React.useMemo(() => {
        return GetFieldLookup(sae?.lookups!, medicalReviewRelatednessPropertyPath) ?? undefined;;
    }, [sae]);

    const columns: Array<IFormGridCell<Dtos.MedicalReviewItem>> = [
        {
            name: 'typeId',
            header: 'Study Drug',
            minWidth: 175,
            maxWidth: 175,
            headerClassName: classes.tableHeader,
            content: (
                <FormLookupContext.Provider value={treatmentLookup}>
                    <InputDisplay convert={(value: number, lookup?: Dtos.Lookup) => lookup?.items.find(i => i.id === value)?.value} />
                </FormLookupContext.Provider>
            )
        },
        {
            name: 'latestRelatednessId',
            header: 'Site Investigator Relatedness Assessment',
            maxWidth: 150,
            headerClassName: classes.tableHeader,
            content: (
                <FormLookupContext.Provider value={relatednessLookup}>
                    <InputDisplay convert={(value: number, lookup?: Dtos.Lookup) => lookup?.items.find(i => i.id === value)?.value ?? 'Not Provided'} />
                </FormLookupContext.Provider>
            )
        },
        {
            name: 'notApplicable',
            header: 'Not Applicable',
            minWidth: 100,
            maxWidth: 100,
            headerClassName: classes.tableHeader,
            content: (
                <Input
                    component={Checkbox}
                />
            )
        },
        {
            name: 'relatednessId',
            header: 'Relatedness Assessment',
            minWidth: 150,
            maxWidth: 150,
            headerClassName: classes.tableHeader,
            content: (
                <CrfCondition
                    type={Dtos.MedicalReview}
                    condition={isApplicable}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <FormLookupContext.Provider value={medicalReviewRelatednessLookup}>
                        <Input component={Select} />
                    </FormLookupContext.Provider>
                </CrfCondition>
            )
        },
        {
            name: 'expected',
            header: 'Expectedness',
            minWidth: 150,
            maxWidth: 150,
            headerClassName: classes.tableHeader,
            content: (
                <CrfCondition
                    type={Dtos.MedicalReview}
                    condition={isRelated}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <FormLookupContext.Provider value={expectednessLookup} >
                        <Input component={Select} />
                    </FormLookupContext.Provider >
                </CrfCondition>
            )
        },
        {
            name: 'comments',
            header: 'Comments/Clinical Query',
            headerClassName: classes.tableHeader,
            content: (
                <CrfCondition
                    type={Dtos.MedicalReview}
                    condition={isApplicable}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Input
                        component={TextArea}
                        variant="outlined"
                        rows={2}
                    />
                </CrfCondition>
            )
        },
    ]

    const {
        handleSubmit,
        onFormCancel,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate
    } = useSaeMedicalReviewForm();

    if (!showMedicalReviewForm) {
        return <></>;
    }

    return (
        <>
            {
                initialMedicalReviewLoading && (
                    <div className={classes.loading}>
                        <RouteLoading />
                        <div>
                            <Typography variant="subtitle1">
                                Loading Medical Review
                            </Typography>
                        </div>
                    </div>
                )
            }
            {
                !initialMedicalReviewLoading &&
                !initialMedicalReviewError &&
                <>
                    <Form
                        initialValues={initialMedicalReview}
                        onValidate={validate}
                        validateOn="onChange"
                        allowSubmit={undefined}
                        fieldErrors={'default'}
                        onSubmit={handleSubmit}
                        labels={undefined}
                        lookups={undefined}
                        readOnly={false}
                        onSubmitFailed={onFormSubmitFailure}
                        onSubmitValidationFailed={onFormSubmitValidationFailure}
                    >
                        <FieldGroup>
                            <br />

                            <Typography
                                component={'span'}
                                className={classes.mt1}
                            >
                                An SAE has been updated for the {trialName} trial.
                            </Typography>

                            <Typography
                                className={classes.instruction}
                            >
                                Please submit a medical review using the form below:
                            </Typography>

                            <br />

                            <FormGrid
                                name="items"
                                columns={columns}
                                type={Dtos.MedicalReviewItem}
                                allowAdd={false}
                                hideRemoveColumn={true}
                            />

                            <Box className={classes.narrative}>
                                <Field
                                    name="narrativeSummary"
                                    label="Narrative Summary"
                                    component={TextArea}
                                    variant="outlined"
                                    rows={4}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    fullWidth
                                />
                            </Box>

                            <Typography>
                                <br />
                                <u>For Australian Participants please note:</u>
                                <br />
                                <br />
                                <b>TGA Blue Card Notification:</b>
                                <br />
                                <br />
                                If a SAE has 'Relatedness' of "Possible", "Probable" or "Definite, and 'Expectedness' of "Unexpected", TGA notification within 15 days of BCT's knowledge of the event is required.
                                <br />
                                <br />
                                <b>TGA Death Notification:</b>
                                <br />
                                <br />
                                In case of SAE (with 'Relatedness' of "Possible", "Probable" or "Definite") resulting in 'Death', regardless of 'Expectedness', TGA notification within 7 days of BCT's knowledge of the event is required.
                             </Typography>

                            <div className={classes.reviewButton}>
                                <SubmitButton
                                    variant="contained"
                                    color="primary"
                                    onClick={undefined}
                                >
                                    Submit Review
                                </SubmitButton>
                            </div>

                            <br />
                        </FieldGroup>
                    </Form>
                </>
            }
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeMedicalReviewForm;