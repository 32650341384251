/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae collapsible table component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useHistory } from 'react-router-dom';


import { Theme, makeStyles } from '@material-ui/core/styles';

import { Column } from 'material-table';


import {
    CollapsibleTable, TextArea, Form, Field, OnlinePatientManagementContext, useSnackbar, InstitutionContext
} from '@ngt/opms';

import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import { IPatient } from '@ngt/opms';
import { SaeContext } from '..';
import { Typography, Box, Input, Dialog, DialogContent, DialogTitle, DialogContentText, TextField, DialogActions, Button, Paper } from '@material-ui/core';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen as faEdit } from '@fortawesome/pro-duotone-svg-icons/faPen';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';
//import { faPlusCircle as faEdit } from '@fortawesome/pro-duotone-svg-icons/faPlusCircle';


/*
 * ---------------------------------------------------------------------------------
 * interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeNarrativeSummaryProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    mr2: {
        marginRight: theme.spacing(2),
    },
    noSummary: {
        opacity: '0.38',
        textAlign: 'center',
        fontSize: '0.875rem',
    },
    summaryContainer: {
        marginTop: theme.spacing(4),
        fontSize: '0.875rem',
    },
    summary: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        '& p': {
            fontSize: '0.875rem',
        }
    },
    summaryTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end'
    },
    update: {
        color: theme.palette.primary.main,
        padding: theme.spacing(0, 0.5),
        textTransform: 'none',
        fontWeight: 400,
        marginBottom: theme.spacing(-0.5),

        '&:hover': {
            cursor: 'pointer'
        },

        '& p': {
            fontSize: '0.875rem',
        }
    },
    icon: {
        marginRight: theme.spacing(0.5)
    },
    dialogTitle: {
        color: theme.palette.primary.main,
    },
    pre: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
        margin: 0,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: '0.875rem',
        lineHeight: '1.43',
        letterSpacing: '0.01071em'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.SaeView,
    Permission.SaeReview,
    Permission.SaeAdminister
];


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const SaeNarrativeSummary: React.FunctionComponent<ISaeNarrativeSummaryProps> = () => {
    const classes = useStyles();

    const { sae, loadState: saeLoadState, actions } = React.useContext(SaeContext);

    const { institution } = React.useContext(InstitutionContext);

    const [[canViewSae, canReviewSae, canAdministerSae], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, false);

    const [modalOpen, setmodalOpen] = React.useState(false);

    const toggleModal = React.useCallback(() => {
        setmodalOpen(state => !state);
    }, [setmodalOpen])

    const [value, setValue] = React.useState(sae?.medicalReview?.narrativeSummary);

    React.useEffect(() => {
        setValue(sae?.medicalReview?.narrativeSummary);
    }, [sae?.medicalReview?.narrativeSummary, setValue]);

    const [updating, setUpdating] = React.useState(false);

    const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }, [setValue]);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const { enqueueSnackbar } = useSnackbar();

    const onClick = React.useCallback(() => {
        setUpdating(true);
        client
            .post(new Dtos.SaeMedicalReviewNarrativeSummaryPostSave({
                medicalReviewId: sae?.medicalReview?.id,
                narrativeSummary: value
            }))
            .then(response => {
                actions.load();
                setUpdating(false);
                toggleModal();
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Narrative Summary Updated
                        </AlertTitle>
                        The narrative summary was updated successfully.
                    </>,
                    { variant: 'success' }
                );
            })
            .catch((e) => {
                setUpdating(false);
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Narrative Summary Not Updated
                        </AlertTitle>
                        An error occurred while attempting to update the narrative summary.
                    </>,
                    { variant: 'critical' }
                );
            })
    }, [setUpdating, client, actions, setValue, value, toggleModal, enqueueSnackbar, sae]);

    return (
        <Box className={classes.summaryContainer}>
            <Box className={classes.summaryTitle}>
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.mr2}
                >
                    Narrative Summary
                </Typography>

                {
                    !!canAdministerSae && <Button className={classes.update} onClick={toggleModal}>
                        <FontAwesomeIcon icon={faEdit} className={classes.icon} fixedWidth />
                        <Typography>
                            Update
                        </Typography>
                    </Button>
                }
            </Box>
            
            <Paper className={classes.summary} elevation={3}>
                {
                    sae?.medicalReview?.narrativeSummary ? 
                        <pre className={classes.pre}>
                            {sae.medicalReview.narrativeSummary}
                        </pre>
                        :
                        <Typography className={classes.noSummary}>
                            There is no narrative summary in the recent medical review.
                        </Typography>
                }
            </Paper>

            <Dialog
                open={modalOpen}
                onClose={toggleModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>Update Narrative Summary</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        variant="outlined"
                        multiline={true}
                        rows={8}
                        rowsMax={25}
                        margin="dense"
                        id="name"
                        value={value}
                        onChange={handleChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModal} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={onClick} color="primary" disabled={updating}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default SaeNarrativeSummary;
