/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the pharmacy summary component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * React
 */
import * as React from 'react';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';

import { faHospitalUser } from '@fortawesome/pro-duotone-svg-icons/faHospitalUser';

import { faVials } from '@fortawesome/pro-duotone-svg-icons/faVials';

import Grid from '@material-ui/core/Grid';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { PatientInformationCard } from '@ngt/opms';
import { getPharmacyStatusText } from '../utilities/getPharmacyStatusText';



/*
 * ---------------------------------------------------------------------------------
 * interfaces
 * ---------------------------------------------------------------------------------
 */

interface IPharmacyInformationProps {
    pharmacyName?: string;
    pharmacyStatus?: Dtos.PharmacyStatus;
    drugsAvailable?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    item: {
        display: 'flex',

        '& > *': {
            flex: '1 1 auto'
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


const PharmacyInformation: React.FunctionComponent<IPharmacyInformationProps> = ({
    pharmacyName,
    pharmacyStatus,
    drugsAvailable
}) => {

    const theme = useTheme();
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <PatientInformationCard
                    color={theme.palette.primary.main}
                    icon={faBuilding}
                    title="Pharmacy"
                    text={pharmacyName}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <PatientInformationCard
                    color={theme.palette.secondary.main}
                    icon={faHospitalUser}
                    title="Status"
                    text={getPharmacyStatusText(pharmacyStatus)}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <PatientInformationCard
                    color="#76c6d7"
                    icon={faVials}
                    title="Drugs"
                    text={<div style={{ lineHeight: '1.5' }}>{drugsAvailable}</div>}
                />
            </Grid>
        </Grid>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PharmacyInformation;
