/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving drugShipments.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDrugShipmentVerifyState {
    drugShipmentVerify: Dtos.DrugShipmentVerify | null;
    verifyState: IRequestState<ResponseStatus>;
};

export interface IDrugShipmentVerifyStore {
    drugShipmentVerify: IDrugShipmentVerifyState;
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

const initialDrugShipmentVerifyState: IDrugShipmentVerifyState = {
    drugShipmentVerify: null,
    verifyState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DrugShipmentVerifyReducer extends ImmerReducer<IDrugShipmentVerifyState> {
    public verify(form: Dtos.DrugShipmentVerify) {
        this.draftState.verifyState = {
            state: RequestState.Pending
        };
    }

    public verifySuccess(drugShipmentVerify?: Dtos.DrugShipmentVerify) {
        this.draftState.drugShipmentVerify = drugShipmentVerify ? drugShipmentVerify : null;
        this.draftState.verifyState = {
            state: RequestState.Success
        };
    }

    public verifyFailure(responseStatus: ResponseStatus) {
        this.draftState.drugShipmentVerify = null;
        this.draftState.verifyState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDrugShipmentVerifyState };
    }
};

export const drugShipmentVerifyActions = createActionCreators(DrugShipmentVerifyReducer);
export const drugShipmentVerifyReducer = createReducerFunction(DrugShipmentVerifyReducer, initialDrugShipmentVerifyState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDrugShipmentVerifyApi = (client: JsonServiceClient) => ({
    verify: (drugShipmentVerify?: Dtos.DrugShipmentVerify) => {
        return client.post(new Dtos.SetDrugShipmentVerify({
            drugShipmentVerify
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDrugShipmentVerifyLogic = (api: ReturnType<typeof createDrugShipmentVerifyApi>) => {
    const logic = {
        verify: createLogic<IDrugShipmentVerifyStore, {}, undefined, string, ReturnType<typeof drugShipmentVerifyActions.verify>>({
            type: drugShipmentVerifyActions.verify.type,
            process: async ({ action }, dispatch, done) => {
                const drugShipmentVerify = action.payload;

                try {
                    const response = await api.verify(drugShipmentVerify);

                    dispatch(drugShipmentVerifyActions.verifySuccess(
                        response.drugShipmentVerify
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentVerifyActions.verifyFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.verify
    ];
}

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDrugShipmentVerifySelector: TypedUseSelectorHook<IDrugShipmentVerifyStore> = useSelector;

export const drugShipmentVerifySelectors = {
    selectDrugShipmentVerify: (state: IDrugShipmentVerifyStore) => state.drugShipmentVerify.drugShipmentVerify,
    selectVerifyState: (state: IDrugShipmentVerifyStore) => state.drugShipmentVerify.verifyState
}

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDrugShipmentVerifyReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDrugShipmentVerifyApi(client);

    const logic = createDrugShipmentVerifyLogic(api);

    reducerRegistry.register('drugShipmentVerify', drugShipmentVerifyReducer as Reducer, logic as any);
};

export default registerDrugShipmentVerifyReducer;