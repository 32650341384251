/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving pharmacists.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';




/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IPharmacistsState {
    pharmacists: Dtos.IPharmacist[] | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IPharmacistsStore {
    pharmacists: IPharmacistsState;
};

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

const initialPharmacistsState: IPharmacistsState = {
    pharmacists: null,
    loadState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class PharmacistsReducer extends ImmerReducer<IPharmacistsState> {
    public loadByInstCode(instCode: string) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(pharmacists?: Dtos.IPharmacist[]) {
        this.draftState.pharmacists = pharmacists ?? null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialPharmacistsState };
    }
};

export const pharmacistsActions = createActionCreators(PharmacistsReducer);
export const pharmacistsReducer = createReducerFunction(PharmacistsReducer, initialPharmacistsState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createPharmacistsApi = (client: JsonServiceClient) => ({
    loadByInstCode: (instCode: string) => {
        return client.get(new Dtos.GetPharmacistsByInstCode({
            instCode
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createPharmacistsLogic = (api: ReturnType<typeof createPharmacistsApi>) => {
    const logic = {
        load: createLogic<IPharmacistsStore, {}, undefined, string, ReturnType<typeof pharmacistsActions.loadByInstCode>>({
            type: pharmacistsActions.loadByInstCode.type,
            process: async ({ action }, dispatch, done) => {
                const instCode = action.payload;

                try {
                    const response = await api.loadByInstCode(instCode);

                    dispatch(pharmacistsActions.loadSuccess(
                        response.pharmacists
                    ));
                }
                catch (error: any) {
                    dispatch(pharmacistsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }
    return [
        logic.load
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const usePharmacistsSelector: TypedUseSelectorHook<IPharmacistsStore> = useSelector;

export const pharmacistsSelectors = {
    pharmacists: (state: IPharmacistsStore) => state.pharmacists.pharmacists,
    loadState: (state: IPharmacistsStore) => state.pharmacists.loadState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerPharmacistsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPharmacistsApi(client);

    const logic = createPharmacistsLogic(api);

    reducerRegistry.register('pharmacists', pharmacistsReducer as Reducer, logic as any);
};

export default registerPharmacistsReducer;