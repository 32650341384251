/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use depot batches.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    ResponseStatus
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';

import {
    depotBatchesActions,
    useDepotBatchesSelector,
    depotBatchesSelectors,
    IDepotBatchesStore
} from '../store/modules/depotbatches';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseDepotBatchesActions {
    load: BoundActionCreator<typeof depotBatchesActions.load>;
    loadByDepotId: BoundActionCreator<typeof depotBatchesActions.loadByDepotId>;
    loadByPharmacyId: BoundActionCreator<typeof depotBatchesActions.loadByPharmacyId>;
    loadByInstCode: BoundActionCreator<typeof depotBatchesActions.loadByInstCode>;
    loadByDrugShipmentId: BoundActionCreator<typeof depotBatchesActions.loadByDrugShipmentId>;
    clear: BoundActionCreator<typeof depotBatchesActions.clear>;
}

export const useDepotBatches = (): [Dtos.DepotBatch[] | null, IRequestState<ResponseStatus>, IUseDepotBatchesActions] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {
        const load = () => depotBatchesActions.load();
        load.type = depotBatchesActions.load.type;

        const loadByDepotId = (depotId: number) => depotBatchesActions.loadByDepotId(depotId);
        loadByDepotId.type = depotBatchesActions.loadByDepotId.type;

        const loadByPharmacyId = (pharmacyId: number) => depotBatchesActions.loadByPharmacyId(pharmacyId);
        loadByPharmacyId.type = depotBatchesActions.loadByPharmacyId.type;

        const loadByInstCode = (instCode: string) => depotBatchesActions.loadByInstCode(instCode);
        loadByInstCode.type = depotBatchesActions.loadByInstCode.type;

        const loadByDrugShipmentId = (drugShipmentId: number) => depotBatchesActions.loadByDrugShipmentId(drugShipmentId);
        loadByDrugShipmentId.type = depotBatchesActions.loadByDrugShipmentId.type;

        const clear = () => depotBatchesActions.clear();
        clear.type = depotBatchesActions.clear.type;

        return bindActionCreators({
            load,
            loadByDepotId,
            loadByPharmacyId,
            loadByInstCode,
            loadByDrugShipmentId,
            clear
        }, dispatch);
    }, [depotBatchesActions, dispatch]);

    const depotBatchesSelector = React.useCallback((state: IDepotBatchesStore) => {
        return depotBatchesSelectors.depotBatches(state);
    }, [depotBatchesSelectors.depotBatches]);

    const loadStateSelector = React.useCallback((state: IDepotBatchesStore) => {
        return depotBatchesSelectors.loadState(state);
    }, [depotBatchesSelectors.loadState]);

    const depotBatches = useDepotBatchesSelector(depotBatchesSelector);

    const loadState = useDepotBatchesSelector(loadStateSelector);

    return [
        depotBatches as Dtos.DepotBatch[] | null,
        loadState,
        actions
    ];
};