/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit as updateIcon } from '@fortawesome/pro-duotone-svg-icons/faEdit';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';
import {
    ITableProps,
    InstitutionsContext,
    ALL_INSTITUTIONS_CODE,
    Table,
    TrialContextSelector,
    IFormLabel,
    CodeSelector
} from '@ngt/opms';
import { Column, MTableToolbar} from 'material-table';
import { useParams, useHistory } from 'react-router-dom';
import { RequestState } from '@ngt/request-utilities';
import { makeStyles, Theme, Button, IconButton, PropTypes, Grid } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useScreeningSummaries } from '../hooks/useScreeningSummaries';
import { ScreeningSummary } from '../api/screeningSummary';
import ScreeningSummaryResolver from './ScreeningSummaryResolver';
import ScreeningSummaryDialog from './ScreeningSummaryDialog';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitData<T extends { data: any }> = Omit<T, 'data' | 'title' | 'loading' | 'columns'>;
interface IItem {
    id?: number;
    name?: string;
    code?: string;
}
interface IScreeningSummaryTableProps<TScreeningSummary extends ScreeningSummary = ScreeningSummary> extends OmitData<ITableProps<TScreeningSummary>> {
    data?: TScreeningSummary[];
    columns: Array<Column<TScreeningSummary>>;
    title?: string;
    loading?: boolean;
    formFieldsComponent?: any;
    formLabels?: IFormLabel[];
    downloadButtons?: JSX.Element;
    addButtonText?: string;
}

interface IScreeningSummaryButtonProps {
    icon: IconProp;
    color?: PropTypes.Color;
    onClickFn: () => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    createGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-Start',
        alignItems: 'flex-end',
    },
    createGridBtn: {
        marginLeft: theme.spacing(2),
        width: '100%',
    },
    header: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    year: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-Start',
        alignItems: 'flex-end',
        width: '100%',
        paddingRight: '20px'
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mr1: {
        marginRight: theme.spacing(1)
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    textWrap: {
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ScreeningSummaryButton: React.FunctionComponent<IScreeningSummaryButtonProps> = ({
    icon,
    color,
    onClickFn
}) => {
    const classes = useStyles();

    return <>
        <IconButton
            color={color ?? "primary"}
            onClick={onClickFn}
            className={classes.button}
            size="small"
        >
            <FontAwesomeIcon icon={icon} fixedWidth />
        </IconButton>
    </>
};

const useScreeningSummaryColumns = <TScreeningSummary extends ScreeningSummary = ScreeningSummary>(
    columns: Array<Column<TScreeningSummary>>,
    classes: Record<string, string>,
    handleUpdate: (id?: number) => void
) => {
    const screeningSummaryColumns = React.useMemo(() => {
        const startColumns: Array<Column<TScreeningSummary>> = [];

        if(!(window.location.href).includes('/screeningsummary/') || (window.location.href).includes(ALL_INSTITUTIONS_CODE)){
            startColumns.push({
                field: 'institutionName',
                title: 'Site',
                width: 100,
                sorting: false,
                align: 'left'
            });
        }

        const actionColumns: Array<Column<TScreeningSummary>> = [];

        actionColumns.push({
            field: 'id',
            title: '',
            render: screeningSummary => {
                return (
                    <div className={classes.textWrap}>
                        {
                            <ScreeningSummaryButton
                                icon={updateIcon}
                                onClickFn={() => handleUpdate(screeningSummary.id)}
                            />
                        }
                    </div>
                )
            },
            width: 100,
            sorting: false,
            align: 'center'
        });
        const totalCols = startColumns.concat(columns);
        return totalCols.concat(actionColumns);
    }, [columns, classes, handleUpdate])

    return screeningSummaryColumns;
};

const permissions: Permission[] = [
    Permission.ScreeningLogView,
    Permission.ScreeningLogUpdate,
];

const ScreeningSummaryTable = <TScreeningSummary extends ScreeningSummary = ScreeningSummary> ({
    data,
    loading,
    title,
    columns,
    formFieldsComponent,
    formLabels,
    downloadButtons,
    style,
    addButtonText,
    ...tableProps
}: IScreeningSummaryTableProps<TScreeningSummary>) => {

    const classes = useStyles();

    const history = useHistory();

    const { institutionCode, year } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutions = contextInstitutions?.institutions;

    const [yearItems, setYearItems] = React.useState([] as IItem[]); 
    const [instLoading, setInstLoading] = React.useState(false); 

    const institution = React.useMemo(() => {
        if (institutionCode === ALL_INSTITUTIONS_CODE) {
            return undefined;
        }

        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [screeningSummary] = useScreeningSummaries<TScreeningSummary>(institution?.id, year ? +year : undefined, data === undefined);
    const [[canViewScreeningSummary], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, institutionCode ? true : false);

    React.useEffect(() => {
        const startYear = 2020;
        const currentYear = (new Date).getFullYear();

        var result = [] as IItem[];

        for(var i = startYear; i <= currentYear; i++){
            result.push({
                id: i,
                name: i.toString(),
                code: i.toString()
            })
        }
        setYearItems(result);
    }, [])

    React.useEffect(() => {
        if (contextInstitutions?.loadState.state === RequestState.Success &&
            institutions && institutions.length === 1)

            if (institutions && institutions.length === 1) {
                history.replace(`/screeningsummary/${institutions[0].code}`)
            }
    }, [contextInstitutions?.loadState, institutions, institution])

    React.useEffect(() => {
        if (permissionLoadState.state !== RequestState.Pending &&
            permissionLoadState.state !== RequestState.None) {
            if (!canViewScreeningSummary) {
                history.replace(`/error/403`);
            }
        }
    }, [canViewScreeningSummary, permissionLoadState, history]);

    const onSelect = React.useCallback((_newMasterGroupCode?: string | null,
        _newCollaboratingGroupCode?: string | null,
        _newCountryCode?: string | null,
        newInstitutionCode?: string | null) => {
        if(year && newInstitutionCode){
            history?.push(`/screeningsummary/${newInstitutionCode}/${year}`);
        }
        else if (newInstitutionCode) {
            history?.push(`/screeningsummary/${newInstitutionCode}`);
        }
        else if (year) {
            history?.push(`/screeningsummary/${ALL_INSTITUTIONS_CODE}/${year}`);
        }
        else {
            history?.push(`/screeningsummary`);
        }
        return;
    }, [history, year]);

    const onYearSelect = React.useCallback((year?: string | null) => {
        if (year && institution?.id) {
            history?.push(`/screeningsummary/${institution?.code}/${year}`);
        }
        else if(year){
            history?.push(`/screeningsummary/${ALL_INSTITUTIONS_CODE}/${year}`);
        }
        else if(institution?.id){
            history?.push(`/screeningsummary/${institution?.code}`);
        }
        else {
            history?.push(`/screeningsummary`);
        }

        return;
    }, [history, institution?.id]);

    const screeningSummaryToUse = React.useMemo(() => {
        if (!screeningSummary && !data) {
            return [];
        }

        return (data ?? screeningSummary ?? []);
    }, [screeningSummary, data, institution?.id]);

    const titleToUse = title ? title : 'Screening Summary';

    const [screeningSummaryId, setScreeningSummaryId] = React.useState<number|undefined>(undefined);
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);

    const handleAdd = () => {
        setOpenDialog(true);
    };

    const handleCancel = () => {
        setOpenDialog(false);
        setScreeningSummaryId(undefined);
    };

    const handleUpdate = (id?: number) => {
        if (!id) {
            throw new Error('Screening log ID was not provided.');
        }
        setOpenDialog(true);
        setScreeningSummaryId(id);
    };

    const downloadButtonsToUse = React.useMemo(() => {
        return downloadButtons ?
            downloadButtons :
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component="a"
                className={classes.createGridBtn}
                href={`/print/screeningsummary/` + (institution?.code ?? 'all-institutions')}
            >
                Download
            </Button>;
    }, [downloadButtons]);

    const useAddButtonText = addButtonText ?? `Add ${titleToUse} Entry`;

    const columnsToUse = useScreeningSummaryColumns(columns, classes, handleUpdate);
    return (
        <>
            {
                    <Grid item xs={12} className={classes.mt3}>
                        <TrialContextSelector
                            onChange={onSelect}
                            sm={4}
                            allowAllMasterGroups={false}
                            allowAllCollaboratingGroups={false}
                            allowAllCountries={false}
                            allowAllInstitutions={true}
                            hideMasterGroups={true}
                            hideCollaboratingGroups={true}
                            hideCountries={true}
                            masterGroupCode={null}
                            collaboratingGroupCode={null}
                            countryCode={null}
                            institutionCode={institution?.code ?? ALL_INSTITUTIONS_CODE}
                            institutionsLoading={instLoading}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={8}
                                className={classes.createGrid}
                            >
                                <div className={classes.year}>
                                {
                                    <CodeSelector
                                        inputId="year"
                                        label="Year"
                                        items={yearItems}
                                        onChange={onYearSelect}
                                        allSelectionAllowed={true}
                                        allSelectionLabel={'⠀'}
                                        allSelectionValue={null}
                                        selection={year}
                                    />
                                }
                                {
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        onClick={handleAdd}
                                        className={classes.createGridBtn}
                                        disabled={!institution}
                                    >
                                        {useAddButtonText}
                                    </Button>
                                }
                                </div>
                                {
                                    downloadButtonsToUse
                                }
                            </Grid>
                        </TrialContextSelector>
                    </Grid>
            }
            {
                <Table
                    title={titleToUse}
                    data={screeningSummaryToUse ?? []}
                    columns={columnsToUse}
                    options={{
                        paging: false,
                        search: false,
                        draggable: false
                    }}
                    components={{
                        Toolbar: props => (
                            <>
                                <div className={classes.header}>
                                    <MTableToolbar 
                                    {...props} />
                                </div>
                                
                          </>
                        ),
                    }}
                    {
                        ...tableProps
                    }
                />
            }

            {
                openDialog && <ScreeningSummaryResolver id={screeningSummaryId} institutionId={institution?.id}>
                    <ScreeningSummaryDialog
                        title={titleToUse}
                        formCancel={handleCancel}
                        formFieldGroup={formFieldsComponent}
                        formLabels={formLabels}
                    />
                </ScreeningSummaryResolver>
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningSummaryTable;