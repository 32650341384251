/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently loaded sae.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used for typing request states.
 */
import { IRequestState } from '@ngt/request-utilities';

import { ResponseStatus, IPatient } from '@ngt/opms'

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * Used to set default values for the context
 */
import { initialIndividualScreeningSummaryState } from '../store/screeningSummary';

import { ScreeningSummary } from '../api/screeningSummary';
import { validate } from '@material-ui/pickers';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IScreeningSummaryContext {
    screeningSummary: ScreeningSummary | null;
    loadState: IRequestState<ResponseStatus>,
    saveState: IRequestState<ResponseStatus>,
    actions: IScreeningSummaryActions;
}

interface IScreeningSummaryActions {
    load: () => void;
    clear: () => void;

    asyncSave: (screeningSummary: ScreeningSummary) => Promise<ScreeningSummary | undefined>;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const ScreeningSummaryContext = React.createContext<IScreeningSummaryContext>({
    screeningSummary: initialIndividualScreeningSummaryState.screeningSummary,
    loadState: initialIndividualScreeningSummaryState.loadState,
    saveState: initialIndividualScreeningSummaryState.saveState,
    actions: {
        load: () => undefined,
        clear: () => undefined,

        asyncSave: (form) => new Promise<ScreeningSummary  | undefined>((resolve, screeningSummary) => {
            resolve(undefined)
        }),
    }
});
/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningSummaryContext;