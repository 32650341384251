/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the treatments reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ITreatmentsState {
    treatments: Dtos.ITreatment[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface ITreatmentsStore {
    treatments: ITreatmentsState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createContext = (treatmentId?: number, collaboratingGroupId?: number, countryId?: number) => {
    return `${(treatmentId ?? 'null')}-${(collaboratingGroupId ?? 'null')}-${(countryId ?? 'null')}`
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all treatments reducer state changes and actions. 
 */
export class TreatmentsReducer extends ImmerReducer<ITreatmentsState>
{
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(treatments?: Dtos.ITreatment[]) {
        this.draftState.treatments = treatments ? treatments : null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: Dtos.ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialTreatmentsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialTreatmentsState: ITreatmentsState = {
    treatments: null,
    loadState: {
        state: RequestState.None
    }
}

export const treatmentsActions = createActionCreators(TreatmentsReducer);
export const treatmentsReducer = createReducerFunction(TreatmentsReducer, initialTreatmentsState);

const createTreatmentsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.TreatmentGetCollection());
    }
});

const createTreatmentsLogic = (api: ReturnType<typeof createTreatmentsApi>) => {
    const logic = {
        load: createLogic<ITreatmentsStore, {}, undefined, string, ReturnType<typeof treatmentsActions.load>>({
            type: treatmentsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(treatmentsActions.loadSuccess(
                        response.treatments
                    ));
                }
                catch (error: any) {
                    dispatch(treatmentsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ]
};

export const useTreatmentsSelector: TypedUseSelectorHook<ITreatmentsStore> = useSelector;

export const treatmentsSelectors = {
    treatments: (state: ITreatmentsStore) => state.treatments.treatments,
    loadState: (state: ITreatmentsStore) => state.treatments.loadState
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerTreatmentsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createTreatmentsApi(client);

    const logic = createTreatmentsLogic(api);

    reducerRegistry.register('treatments', treatmentsReducer as Reducer, logic as any);
};

export default registerTreatmentsReducer;