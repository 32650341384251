/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { Grid, useTheme, makeStyles } from '@material-ui/core';

import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';

import { faHeartbeat } from '@fortawesome/pro-duotone-svg-icons/faHeartbeat';

import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import PatientInformationCard from './PatientInformationCard';

import * as Dtos from '../../../api/dtos';
import PatientContext from '../../../contexts/data/PatientContext';
import InstitutionContext from '../../../contexts/data/InstitutionContext';
import { DateTime } from 'luxon';
import PatientStateContext from '../../../contexts/configuration/PatientStateContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export type PatientInformationFn = (patient?: Dtos.IPatient | null, patientState?: Dtos.IPatientState | null, institution?: Dtos.IInstitution | null) => React.ReactNode;

/**
 * This interface defines the properties for the PatientInformation component.
 */
export interface IPatientInformationProps {
    patient?: Dtos.IPatient | null;
    patientState?: Dtos.IPatientState | null;
    institution?: Dtos.IInstitution | null;
    patientText?: string | PatientInformationFn;
    patientCaption?: string | PatientInformationFn;
    patientStateText?: string | PatientInformationFn;
    patientStateCaption?: string | PatientInformationFn;
    institutionText?: string | PatientInformationFn;
    institutionCaption?: string | PatientInformationFn;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',

        '& > *': {
            flex: '1 1 auto'
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const defaultPatientStateCaptionFn = (enteredDate: string | undefined) =>
{
    if (enteredDate) {
        DateTime.fromISO(enteredDate).toFormat('dd/MM/yyyy');
    }

    return DateTime.local().toFormat('dd/MM/yyyy');
}

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const PatientInformation: React.FunctionComponent<IPatientInformationProps> = ({
    children,
    patient,
    patientState,
    institution,
    patientText,
    patientCaption,
    institutionText,
    institutionCaption,
    patientStateText,
    patientStateCaption
}) => {
    const theme = useTheme()
    const classes = useStyles();

    const contextPatient = React.useContext(PatientContext);
    const contextPatientState = React.useContext(PatientStateContext);
    const contextInstitution = React.useContext(InstitutionContext);

    const patientToUse = React.useMemo(() => {
        return patient === undefined ? contextPatient.patient : patient;
    }, [patient, contextPatient]);

    const patientStateToUse = React.useMemo(() => {
        return patientState === undefined ? contextPatientState.patientState : patientState;
    }, [patientState, contextPatientState]);

    const institutionToUse = React.useMemo(() => {
        return institution === undefined ? contextInstitution.institution : institution;
    }, [institution, contextInstitution]);

    const patientTextToUse = !patientText ?
        patientToUse?.studyNumber ?? 'New Patient' :
        patientText instanceof Function ?
            patientText(patientToUse, patientStateToUse, institutionToUse) :
            patientText;

    const patientCaptionToUse = !patientCaption ?
        patientToUse?.id?.toString() ?? '' :
        patientCaption instanceof Function ?
            patientCaption(patientToUse, patientStateToUse, institutionToUse) :
            patientCaption;

    const institutionTextToUse = !institutionText ?
        institutionToUse?.code ?? 'XXXXX' :
        institutionText instanceof Function ?
            institutionText(patientToUse, patientStateToUse, institutionToUse) :
            institutionText;

    const institutionCaptionToUse = !institutionCaption ?
        institutionToUse?.name ?? 'Unknown Institution' :
        institutionCaption instanceof Function ?
            institutionCaption(patientToUse, patientStateToUse, institutionToUse) :
            institutionCaption;

    const patientStateTextToUse = !patientStateText ?
        patientStateToUse?.name ?? 'New Patient' :
        patientStateText instanceof Function ?
            patientStateText(patientToUse, patientStateToUse, institutionToUse) :
            patientStateText;

    const patientStateCaptionToUse = !patientStateCaption ?
        defaultPatientStateCaptionFn(patientToUse?.enteredDate) :
        patientStateCaption instanceof Function ?
            patientStateCaption(patientToUse, patientStateToUse, institutionToUse) :
            patientStateCaption;

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <PatientInformationCard
                    color={theme.palette.primary.main}
                    icon={faUser}
                    title="Patient"
                    text={patientTextToUse}
                    caption={patientCaptionToUse}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <PatientInformationCard
                    color={theme.palette.secondary.main}
                    icon={faBuilding}
                    title="Institution"
                    text={institutionTextToUse}
                    caption={institutionCaptionToUse}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <PatientInformationCard
                    color="#76c6d7"
                    icon={faHeartbeat}
                    title="Status"
                    text={patientStateTextToUse}
                    caption={patientStateCaptionToUse}
                />
            </Grid>
        </Grid>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientInformation;