/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae summary component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles, darken } from '@material-ui/core/styles';

import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableRowProps,
    Tooltip,
    Button
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import { Column } from 'material-table';

import { DateTime } from 'luxon';

import {
    RouteLoading,
    CollapsibleTable,
    GetFieldLookup
} from '@ngt/opms';

import { RequestState } from '@ngt/request-utilities';

import { MTableBodyRow } from 'material-table';

import { blue } from '@material-ui/core/colors';

import classNames from 'classnames';

import { HashLink as Link } from 'react-router-hash-link';

import Markdown from 'markdown-to-jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSquare } from '@fortawesome/pro-duotone-svg-icons/faSquare';

import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';

import { faDownload } from '@fortawesome/pro-duotone-svg-icons';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext';
import SaeNarrativeSummary from './SaeNarrativeSummary';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeSummaryProps {
    showMedicalReview?: boolean;
}

interface IOcGroup<T extends Dtos.ISaeSubform = any> {
    name: string;
    title: string;
    data: T[];
    columns: Array<Column<T>>;
    createRowProps?: ((data: any, index: number, level: number) => TableRowProps);
    entityName?: string;
    Before?: React.ComponentType;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const highlightColor = blue[100];

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    blueBgColor: {
        backgroundColor: `${highlightColor} !important`,
        minHeight: '20px',
        '&:nth-child(odd)': {
            background: `${darken(highlightColor, 0.07)} !important`
        }
    },
    cellHeight: {
        minHeight: '20px'
    },
    textWrap: {
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    },
    hidden: {
        display: 'none'
    },
    //legend: {
    //    backgroundColor: darken(highlightColor, 0.07),
    //    height: '15px',
    //    width: '15px',
    //    position: 'absolute',
    //    top: '50%',
    //    marginTop: '-7.5px'
    //},
    legend: {
        fontSize: '0.85rem',
        background: 'white',
    },
    legendIcon: {
        color: blue[400],
        background: 'white',
        marginRight: theme.spacing(0.5)
    },
    mb3: {
        marginBottom: theme.spacing(3),
    },
    mt4: {
        marginTop: theme.spacing(4)
    },
    mt3: {
        marginTop: theme.spacing(3),
    },
    mt2: {
        marginTop: theme.spacing(2),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    table: {
        marginTop: theme.spacing(2),
        borderTop: '1px',

    },
    tableHeader: {
        fontWeight: 'bold',
        width: '50%'
    },
    tableHeaderColor: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    row: {
        '&:nth-child(odd)': {
            background: theme.palette.grey[100]
        }
    },
    tableMedicalReview: {
        border: '1px solid rgba(224, 224, 224, 1)'
    },
    fontSize: {
        fontSize: '0.875rem'
    },
    actionHistoryLink: {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
        fontSize: '1rem',

        '&:hover': {
            color: theme.palette.secondary.dark,
        }
    },
    downloadButton: {
        minWidth: '0',
        padding: theme.spacing(1)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const SOURCE_DOCUMENT_NAME = "document";
const ACTION_HISTORY_GROUP_NAME = "actionHistory";
const ACTION_HISTORY_REPORT_ITEM_NAME = "saeFollowUpNumber";

const treatmentPropertyPath = 'lastDoseStudyDrugs.drugName'
const relatednessPropertyPath = 'lastDoseStudyDrugs.drugRelatedToEvent'
const medicalReviewRelatednessPropertyPath = 'MedicalReview.Relatedness'
const expectednessPropertyPath = 'MedicalReview.Expectedness'

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */

const SaeSubform: React.FunctionComponent<IOcGroup> = ({
    data,
    columns,
    name,
    title,
    createRowProps,
    entityName,
    Before
}) => {
    const classes = useStyles();

    return (
        <div className={classes.mt3} id={title.replace(' ', '')}>
            {!!Before && (<Before />)}
            <CollapsibleTable
                title={title}
                loading={false}
                data={data ?? []}
                columns={columns}
                entityName={entityName ?? 'Record'}
                components={{
                    Row: (props) => {
                        const rowProps = createRowProps ? createRowProps(props.data, props.index, props.level) : {};

                        return <MTableBodyRow {...props} {...rowProps} />
                    }
                }}
                pluralizeTitle={false}
            />
        </div>
    );
}

const SaeSummary: React.FunctionComponent<ISaeSummaryProps> = ({
    showMedicalReview
}) => {
    const classes = useStyles();

    const { sae, loadState: saeLoadState } = React.useContext(SaeContext);

    const treatmentLookup = React.useMemo(() => {
        return GetFieldLookup(sae?.lookups!, treatmentPropertyPath);
    }, [sae]);

    const relatednessLookup = React.useMemo(() => {
        return GetFieldLookup(sae?.lookups!, relatednessPropertyPath);
    }, [sae]);

    const expectednessLookup = React.useMemo(() => {
        return GetFieldLookup(sae?.lookups!, expectednessPropertyPath);
    }, [sae]);

    const medicalReviewRelatednessLookup = React.useMemo(() => {
        return GetFieldLookup(sae?.lookups!, medicalReviewRelatednessPropertyPath);
    }, [sae]);

    const groups: IOcGroup[] = React.useMemo(() => {
        if (!sae) {
            return [];
        }

        const g: IOcGroup[] = [];

        Object
            .keys(sae.saeFormItems)
            .filter(key => key.includes('.'))
            .forEach(key => {
                const index = key.indexOf('.');
                const groupName = key.substring(0, index);

                const itemName = key.substring(index + 1);

                let group = g.find(x => x.name === groupName)

                if (!group) {

                    group = { title: sae.saeFormItems[groupName], name: groupName, data: (sae as any).form[groupName] ?? (sae as any)[groupName], columns: [] };

                    g.push(group);
                }

                group.createRowProps = (data: any, index: number, level: number) => {

                    let ecrfIndex: number = 0;

                    sae.ecrfs
                        .forEach((ecrf, i) => {
                            if ((ecrf as any)[groupName]?.length > 0) {
                                ecrfIndex = i;
                                return;
                            }
                        });

                    return {
                        className: classNames({ [classes.blueBgColor]: (sae as any).ecrfs[ecrfIndex][groupName]?.length > index && ecrfIndex > 0, [classes.row]: (sae as any).ecrfs[ecrfIndex][groupName]?.length <= index })
                    }
                };

                group.columns.push({
                    field: itemName,
                    title: sae.saeFormItems[key],
                    render: (data, type) => {

                        if (!!sae.form && !!sae.ecrfs) {
                            if (itemName === SOURCE_DOCUMENT_NAME) {
                                return (
                                    <div className={`${classes.cellHeight}`}>
                                        <Tooltip title="Download">
                                            <Button color="primary" variant="contained" className={classes.downloadButton} component="a" target="_blank" href={`/opms/sae/open-clinica/document?FileName=${encodeURIComponent(data[itemName])}`}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                );
                            } else if (groupName === ACTION_HISTORY_GROUP_NAME && itemName === ACTION_HISTORY_REPORT_ITEM_NAME) {
                                return (
                                    <div className={`${classes.cellHeight} ${classes.textWrap}`}>
                                        <div dangerouslySetInnerHTML={{ __html: data[itemName] === 0 ? 'Initial' : `Follow-up no. ${data[itemName]}` }} />
                                    </div>
                                );
                            } else {
                                return (
                                    <div className={`${classes.cellHeight} ${classes.textWrap}`}>
                                        <div dangerouslySetInnerHTML={{ __html: getTransformedValue(data[itemName]) ?? '' }} />
                                    </div>
                                );
                            }
                        }

                        return undefined;
                    }
                })
            });

        return g;
    }, [sae]);

    const medicalReviewGroup: IOcGroup | null = React.useMemo(() => {
        if (!sae?.medicalReview) {
            return null;
        }

        return {
            title: 'Medical Review',
            name: 'medicalReview.items',
            entityName: 'Study Drug',
            data: sae?.medicalReview?.items?.filter(i => !i.notApplicable) ?? [],
            Before: (sae?.medicalReview?.date ?? '') < (sae?.form?.dateUpdated ?? '') ?
                () => {
                    return (
                        <Alert
                            icon={<FontAwesomeIcon icon={faInfoCircle} fixedWidth />}
                            severity="info"
                        >
                            The SAE has been updated since this medical review was submitted.
                        </Alert>
                    );
                } :
                undefined,
            columns: [
                {
                    field: 'typeId',
                    title: 'Study Drug',
                    render: (mr, type) => <div className={classes.cellHeight}>
                        {treatmentLookup?.items?.find(li => li.id === mr?.typeId)?.value}
                    </div>
                },
                {
                    id: 'latestRelatednessId',
                    title: 'Site Investigator Relatedness Assessment',
                    render: (mr, type) => <div className={classes.cellHeight}>
                        {relatednessLookup?.items?.find(li => li.id === mr?.latestRelatednessId)?.value ?? (mr?.dataEntered ? 'Not Provided' : '')}
                    </div>
                },
                {
                    id: 'relatednessId',
                    title: 'Relatedness Assessment',
                    render: (mr, type) => <div className={classes.cellHeight}>
                        {medicalReviewRelatednessLookup?.items?.find(li => li.id === mr?.relatednessId)?.value}
                    </div>
                },
                {
                    id: 'expected',
                    title: 'Expectedness',
                    render: (mr, type) => <div className={classes.cellHeight}>
                        {expectednessLookup?.items?.find(li => li.id === mr?.expected)?.value}
                    </div>
                },
                {
                    id: 'comments',
                    title: 'Comments/Clinical Query',
                    render: (mr, type) => <div className={classes.cellHeight}>
                        <pre style={{ whiteSpace: 'pre-line', wordBreak: 'break-word', background: 'transparent', border: 'none', textAlign: 'left', display: 'inline-block', marginBottom: 0, paddingBottom: 0 }}>
                            {mr?.comments}
                        </pre>
                    </div>
                }
            ],
            createRowProps: undefined
        }
    }, [sae]);

    const sourceDocuments: IOcGroup | null = React.useMemo(() => {
        if (!sae?.medicalReview) {
            return null;
        }

        return {
            title: 'Medical Review',
            name: 'medicalReview.items',
            entityName: 'Study Drug',
            data: sae?.medicalReview?.items.filter(i => !i.notApplicable) ?? [],
            Before: (sae?.medicalReview?.date ?? '') < (sae?.form?.dateUpdated ?? '') ?
                () => {
                    return (
                        <Alert
                            icon={<FontAwesomeIcon icon={faInfoCircle} fixedWidth />}
                            severity="info"
                        >
                            The SAE has been updated since this medical review was submitted.
                        </Alert>
                    );
                } :
                undefined,
            columns: [
                {
                    field: 'typeId',
                    title: 'Study Drug',
                    render: (mr, type) => <div className={classes.cellHeight}>
                        {treatmentLookup?.items?.find(li => li.id === mr?.typeId)?.value}
                    </div>
                },
                {
                    id: 'latestRelatednessId',
                    title: 'Site Investigator Relatedness Assessment',
                    render: (mr, type) => <div className={classes.cellHeight}>
                        {relatednessLookup?.items?.find(li => li.id === mr?.latestRelatednessId)?.value ?? (mr?.dataEntered ? 'Not Provided' : '')}
                    </div>
                },
                {
                    id: 'relatednessId',
                    title: 'Relatedness Assessment',
                    render: (mr, type) => <div className={classes.cellHeight}>
                        {medicalReviewRelatednessLookup?.items?.find(li => li.id === mr?.relatednessId)?.value}
                    </div>
                },
                {
                    id: 'expected',
                    title: 'Expectedness',
                    render: (mr, type) => <div className={classes.cellHeight}>
                        {expectednessLookup?.items?.find(li => li.id === mr?.expected)?.value}
                    </div>
                },
                {
                    id: 'comments',
                    title: 'Comments/Clinical Query',
                    render: (mr, type) => <div className={classes.cellHeight}>
                        <pre style={{ whiteSpace: 'pre-line', wordBreak: 'break-word', background: 'transparent', border: 'none', textAlign: 'left', display: 'inline-block', marginBottom: 0, paddingBottom: 0 }}>
                            {mr?.comments}
                        </pre>
                    </div>
                }
            ],
            createRowProps: undefined
        }
    }, [sae]);

    if (saeLoadState.state === RequestState.None || saeLoadState.state === RequestState.Pending) {
        return <RouteLoading />;
    }

    return (
        <>
            {
                sae?.medicalReview && <SaeNarrativeSummary />
            }

            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.mt4}
                >
                    Current Serious Adverse Event Entry
                </Typography>

                <div className={classes.legend}>
                    <FontAwesomeIcon icon={faSquare} className={classes.legendIcon} fixedWidth />
                    Latest value updated in the latest follow up
                </div>
            </div>

            <TableContainer className={classes.table} component={Paper} elevation={3}>
                <Table>
                    <TableBody>
                        {
                            !!sae && Object.keys(sae.saeFormItems!!)?.map((itemKey, itemIndex) => {

                                if (itemKey.includes('.')) {
                                    return
                                } else if (itemIndex + 1 < Object.keys(sae.saeFormItems!!)?.length &&
                                    Object.keys(sae.saeFormItems!!)[itemIndex + 1].includes('.')) {
                                    return
                                }

                                return <TableRow key={itemKey} className={hasChanged(sae.ecrfs, itemKey) ? classes.blueBgColor : classes.row}>
                                    <TableCell className={classes.tableHeader} component="th" scope="row">
                                        {sae.saeFormItems[itemKey]}
                                    </TableCell>
                                    <TableCell align="left">
                                        {
                                            <Typography variant="body2">
                                                <Markdown>
                                                    {getTransformedValue((sae as any).form[itemKey]) ?? ''}
                                                </Markdown>
                                            </Typography>
                                        }
                                    </TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {
                sae?.ecrfs && sae?.ecrfs.length > 1 &&
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.mt4}
                >
                    Initial Serious Adverse Event Entry
                    </Typography>
            }

            {
                sae?.ecrfs && sae?.ecrfs.length > 1 &&
                <TableContainer className={classes.table} component={Paper} elevation={3}>
                    <Table>
                        <TableBody>
                            {
                                !!sae && Object.keys(sae.saeFormItems!!)?.map((itemKey, itemIndex) => {
                                    if (itemKey.includes('.')) {
                                        return
                                    } else if (itemIndex + 1 < Object.keys(sae.saeFormItems!!)?.length &&
                                        Object.keys(sae.saeFormItems!!)[itemIndex + 1].includes('.')) {
                                        return
                                    }

                                    return <TableRow key={itemKey} className={classes.row}>
                                        <TableCell className={classes.tableHeader} component="th" scope="row">
                                            {sae.saeFormItems[itemKey]}
                                        </TableCell>
                                        <TableCell align="left">
                                            {
                                                getTransformedValue((sae as any).ecrfs[0][itemKey])
                                            }
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                !!sae && !!groups && groups.map((group, groupNumber) => {
                    if (groupNumber === groups.length - 1) {
                        return null;
                    }

                    return <SaeSubform key={group.name} {...group} />
                })
            }

            {
                !!sae && showMedicalReview && !!medicalReviewGroup && (
                    <SaeSubform {...medicalReviewGroup} />
                )
            }

            {
                !!sae && !!groups && groups.length >= 1 && (
                    <SaeSubform {...groups[groups.length - 1]} />
                )
            }
        </>
    );
}

const hasChanged = (saes: Dtos.ISaeForm[], itemKey: string): boolean => {
    if (saes.length < 2) {
        return false;
    }

    if (getTransformedValue((saes as any)[saes.length - 1][itemKey]) !== '' && getTransformedValue((saes as any)[saes.length - 1][itemKey]) !== getTransformedValue((saes as any)[saes.length - 2][itemKey])) {
        return true;
    }

    return false;
}

const getTransformedValue = (property: any): string | null => {

    if (property == null) {
        return '';
    }

    if (typeof property === 'boolean') {
        return (property === true ? 'Yes' : '');
    } else if (typeof property === 'string') {
        if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{7}Z/.test(property)) {
            return DateTime.fromISO(property).toFormat('dd/MM/yyyy, hh:mm a');
        } else if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{7}/.test(property)) {
            return DateTime.fromISO(property).toFormat('dd/MM/yyyy');
        }
    } else if (typeof property === 'object') {
        // For lookup values
        return property.value;
    }

    return property?.toString().replace(/\<br\/\>\<br\/\>$/, '');
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeSummary;