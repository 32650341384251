/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving pharmacies.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';



/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface ITimepointsState {
    timepoints: Dtos.Timepoint[] | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface ITimepointsStore {
    timepoints: ITimepointsState;
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

const initialTimepointsState: ITimepointsState = {
    timepoints: null,
    loadState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class TimepointsReducer extends ImmerReducer<ITimepointsState> {
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadByTreatmentId(treatmentId: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(timepoints?: Dtos.Timepoint[]) {
        this.draftState.timepoints = timepoints ? timepoints : null;
        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus: ResponseStatus) {
        this.draftState.timepoints = null;
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialTimepointsState };
    }
};

export const timepointsActions = createActionCreators(TimepointsReducer);
export const timepointsReducer = createReducerFunction(TimepointsReducer, initialTimepointsState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createTimepointsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.GetTimepoints());
    },
    loadByTreatmentId: (treatmentId: number) => {
        return client.get(new Dtos.GetTimepointsByTreatmentId({
            treatmentId
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createTimepointsLogic = (api: ReturnType<typeof createTimepointsApi>) => {
    const logic = {
        load: createLogic<ITimepointsState, {}, undefined, string, ReturnType<typeof timepointsActions.load>>({
            type: timepointsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(timepointsActions.loadSuccess(
                        response.timepoints
                    ));
                }
                catch (error: any) {
                    dispatch(timepointsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByTreatmentId: createLogic<ITimepointsState, {}, undefined, string, ReturnType<typeof timepointsActions.loadByTreatmentId>>({
            type: timepointsActions.loadByTreatmentId.type,
            process: async ({ action }, dispatch, done) => {
                const treatmentId = action.payload;

                try {
                    const response = await api.loadByTreatmentId(treatmentId);

                    dispatch(timepointsActions.loadSuccess(
                        response.timepoints
                    ));
                }
                catch (error: any) {
                    dispatch(timepointsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.load,
        logic.loadByTreatmentId
    ];
}

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useTimepointsSelector: TypedUseSelectorHook<ITimepointsStore> = useSelector;

export const timepointsSelectors = {
    selectTimepoints: (state: ITimepointsStore) => state.timepoints.timepoints,
    selectLoadState: (state: ITimepointsStore) => state.timepoints.loadState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerTimepointsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createTimepointsApi(client);

    const logic = createTimepointsLogic(api);

    reducerRegistry.register('timepoints', timepointsReducer as Reducer, logic as any);
};

export default registerTimepointsReducer;