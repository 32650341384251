/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the events reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IIndividualEventsState {
    events: Dtos.IEvent[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IEventsState {
    byIds: Record<string, IIndividualEventsState>;
    byCodes: Record<string, IIndividualEventsState>;
}

export interface IEventsStore {
    events: IEventsState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (patientId?: number | null) => {
    return `${(patientId ?? 'null')}`;
}

const createStudyNumbersContext = (patientStudyNumber?: string | null) => {
    return `${(patientStudyNumber ?? 'null')}`;
}


/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all events reducer state changes and actions. 
 */
export class EventsReducer extends ImmerReducer<IEventsState>
{
    public loadByIds(patientId?: number | null) {
        const context = createIdsContext(patientId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualEventsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByIdsSuccess(patientId?: number | null, events?: Dtos.IEvent[]) {
        const context = createIdsContext(patientId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualEventsState };
        }

        this.draftState.byIds[context].events = events ? events : null;

        this.draftState.byIds[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdsFailure(patientId?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(patientId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualEventsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByIds(patientId?: number | null) {
        const context = createIdsContext(patientId);

        this.draftState.byIds[context] = { ...initialIndividualEventsState }
    }

    public loadByCodes(patientStudyNumber?: string | null) {
        const context = createStudyNumbersContext(patientStudyNumber);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualEventsState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByCodesSuccess(patientStudyNumber?: string | null, events?: Dtos.IEvent[]) {
        const context = createStudyNumbersContext(patientStudyNumber);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualEventsState };
        }

        this.draftState.byCodes[context].events = events ? events : null;

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodesFailure(patientStudyNumber?: string | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createStudyNumbersContext(patientStudyNumber);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualEventsState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByCodes(patientStudyNumber?: string | null) {
        const context = createStudyNumbersContext(patientStudyNumber);

        this.draftState.byCodes[context] = { ...initialIndividualEventsState }
    }

    public clearAll() {
        this.draftState = { ...initialEventsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialEventsState: IEventsState = {
    byIds: {},
    byCodes: {}
}

export const initialIndividualEventsState: IIndividualEventsState = {
    events: null,
    loadState: {
        state: RequestState.None
    }
}

export const eventsActions = createActionCreators(EventsReducer);
export const eventsReducer = createReducerFunction(EventsReducer, initialEventsState);

const createEventsApi = (client: JsonServiceClient) => ({
    loadByIds: (patientId?: number) => {
        if (patientId) {
            return client.get(new Dtos.EventGetCollectionByPatientId({ patientId }));
        }
        else {
            return client.get(new Dtos.EventGetCollection());
        }
    },
    loadByCodes: (patientStudyNumber?: string) => {
        if (patientStudyNumber) {
            return client.get(new Dtos.EventGetCollectionByPatientStudyNumber({ patientStudyNumber }));
        }
        else {
            return client.get(new Dtos.EventGetCollection());
        }
    }
});

const createEventsLogic = (api: ReturnType<typeof createEventsApi>) => {
    const logic = {
        loadByIds: createLogic<IEventsStore, {}, undefined, string, ReturnType<typeof eventsActions.loadByIds>>({
            type: eventsActions.loadByIds.type,
            process: async ({ action }, dispatch, done) => {
                const patientId = action.payload as unknown as number | undefined | null;

                try {
                    const response = await api.loadByIds(patientId ?? undefined);

                    dispatch(eventsActions.loadByIdsSuccess(
                        patientId,
                        response.events
                    ));
                }
                catch (error: any) {
                    dispatch(eventsActions.loadByIdsFailure(patientId, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCodes: createLogic<IEventsStore, {}, undefined, string, ReturnType<typeof eventsActions.loadByCodes>>({
            type: eventsActions.loadByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const patientStudyNumber = action.payload as unknown as string | undefined | null;

                try {
                    const response = await api.loadByCodes(patientStudyNumber ?? undefined);

                    dispatch(eventsActions.loadByCodesSuccess(
                        patientStudyNumber,
                        response.events
                    ));
                }
                catch (error: any) {
                    dispatch(eventsActions.loadByCodesFailure(patientStudyNumber, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadByIds,
        logic.loadByCodes
    ]
};

export const useEventsSelector: TypedUseSelectorHook<IEventsStore> = useSelector;

export const eventsSelectors = {
    eventsByIds: (state: IEventsStore, patientId?: number | null) => {
        const context = createIdsContext(patientId);

        if (!state.events.byIds[context]) {
            return initialIndividualEventsState.events;
        }

        return state.events.byIds[context].events;
    },
    loadStateByIds: (state: IEventsStore, patientId?: number | null) => {
        const context = createIdsContext(patientId);

        if (!state.events.byIds[context]) {
            return initialIndividualEventsState.loadState;
        }

        return state.events.byIds[context].loadState;
    },
    eventsByCodes: (state: IEventsStore, patientStudyNumber?: string | null) => {
        const context = createStudyNumbersContext(patientStudyNumber);

        if (!state.events.byCodes[context]) {
            return initialIndividualEventsState.events;
        }

        return state.events.byCodes[context].events;
    },
    loadStateByCodes: (state: IEventsStore, patientStudyNumber?: string | null) => {
        const context = createStudyNumbersContext(patientStudyNumber);

        if (!state.events.byCodes[context]) {
            return initialIndividualEventsState.loadState;
        }

        return state.events.byCodes[context].loadState;
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerEventsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createEventsApi(client);

    const logic = createEventsLogic(api);

    reducerRegistry.register('events', eventsReducer as Reducer, logic as any);
};

export default registerEventsReducer;