/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the drugShipment management page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { IRequestState } from '@ngt/request-utilities';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, MenuItem, makeStyles, Theme } from '@material-ui/core';

import * as Yup from 'yup';

import { Form, Select, Text, ResponseStatus, SubmitButton, IFormGridCell, Input, FormGrid } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { IUsePatientDrugsActions } from '../hooks/usePatientDrugs';
import useModal from '../hooks/useModal';
import { IPatientDrugsForm } from '../store/modules/patientdrugs';


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogContent: {
        padding: '0'
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IPatientDrugsModalProps {
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    patientDrugs: Dtos.PatientDrug[] | null;
    patientDrugsLoadState: IRequestState<ResponseStatus>;
    drugs: Dtos.Drug[] | null;
    drugLoadState: IRequestState<ResponseStatus>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    patientDrugsActions: IUsePatientDrugsActions;
}

interface IPatientDrugsFormProps {
    patientDrugs: Dtos.PatientDrug[] | null;
    patientDrugsLoadState: IRequestState<ResponseStatus>;
    drugs: Dtos.Drug[] | null;
    drugLoadState: IRequestState<ResponseStatus>;
    setModalOpen: (open: boolean) => void;
}

const validation = Yup.object().shape<IPatientDrugsForm>({
    patientDrugs: Yup.array()
        .min(1, 'At least one drug status must exist on this form')
        .of(Yup.object<Dtos.PatientDrug>().shape({
            drugId: Yup.number().required(),
            patientTreatmentStatus: Yup.number().required()
        }))
});

const PatientDrugsForm: React.FunctionComponent<IPatientDrugsFormProps> = ({
    patientDrugs,
    patientDrugsLoadState,
    drugs,
    drugLoadState,
    setModalOpen
}) => {
    const classes = useStyles();

    const onCloseClick = React.useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const allowDelete = React.useCallback(() => {
        return false;
    }, []);

    const patientTreatmentStatuses = React.useMemo(() => {
        return [
            <MenuItem key={Dtos.PatientTreatmentStatus.OnTreatment} value={Dtos.PatientTreatmentStatus.OnTreatment}>On Treatment</MenuItem>,
            <MenuItem key={Dtos.PatientTreatmentStatus.OffTreatment} value={Dtos.PatientTreatmentStatus.OffTreatment}>Off Treatment</MenuItem>
        ];
    }, []);

    const patientDrugItems = React.useMemo(() => {
        if (patientDrugs &&
            patientDrugs.length > 0) {

            const columns: Array<IFormGridCell<Dtos.PatientDrug>> = [
                {
                    name: 'drug.drugName' as any,
                    header: 'Drug',
                    content: (
                        <Input
                            component={Text}
                            disabled={true}
                        />
                    )
                },
                {
                    name: 'patientTreatmentStatus',
                    header: 'Status',
                    content: (
                        <Input
                            component={Select}
                            nullOption={false}
                        >
                            {patientTreatmentStatuses}
                        </Input>
                    )
                },
            ];

            return <FormGrid
                name="patientDrugs"
                columns={columns}
                type={Dtos.PatientDrug}
                minRow={1}
                allowDelete={allowDelete}
                allowAdd={false}
            />;
        }

        return null;
    }, [patientDrugs, patientDrugsLoadState, drugs, drugLoadState, patientTreatmentStatuses]);

    return (
        <>
            <DialogContent
                className={classes.dialogContent}
            >
                {
                    patientDrugItems
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary">
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const PatientDrugsModal: React.FunctionComponent<IPatientDrugsModalProps> = ({
    patientDrugs,
    patientDrugsLoadState,
    drugs,
    drugLoadState,
    modalOpen,
    setModalOpen,
    onCloseClick,
    onFormSave,
    patientDrugsActions
}) => {

    const patientDrugData = React.useMemo(() => {
        const patientDrugDataForm: IPatientDrugsForm = Object.assign({}, { patientDrugs });

        return patientDrugDataForm;
    }, [patientDrugs]);

    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'drug status',
        validation: validation,
        asyncSave: patientDrugsActions.asyncSave,
        onFormSave: onFormSave
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Save Patient Drugs Status
                </DialogTitle>
                <Form
                    initialValues={patientDrugData}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onValidate={debouncedValidate}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <PatientDrugsForm
                        patientDrugs={patientDrugs}
                        patientDrugsLoadState={patientDrugsLoadState}
                        drugs={drugs}
                        drugLoadState={drugLoadState}
                        setModalOpen={setModalOpen}
                    />
                </Form>
            </Dialog>
        </>
    );
}

export default PatientDrugsModal;