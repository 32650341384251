/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to verify drug shipments
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    ResponseStatus,
    useAsyncFunction,
    BoundActionCreator,
    TypedFunction,
    bindActionCreatorsWithType
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

import {
    drugShipmentVerifyActions,
    useDrugShipmentVerifySelector,
    drugShipmentVerifySelectors,
    IDrugShipmentVerifyStore
} from '../store/modules/drugshipmentverify';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseDrugShipmentVerifyActions {
    clear: BoundActionCreator<typeof drugShipmentVerifyActions.clear>;

    asyncVerify: TypedFunction<Parameters<BoundActionCreator<typeof drugShipmentVerifyActions.verify>>, Promise<Dtos.DrugShipmentVerify | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useDrugShipmentVerify = (): [
    Dtos.DrugShipmentVerify | null,
    IRequestState<ResponseStatus>,
    IUseDrugShipmentVerifyActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncVerify = useAsyncFunction(drugShipmentVerifyActions.verify, drugShipmentVerifyActions.verifySuccess, drugShipmentVerifyActions.verifyFailure);

    const actions: IUseDrugShipmentVerifyActions = React.useMemo(() => {
        const clear = () => drugShipmentVerifyActions.clear();
        clear.type = drugShipmentVerifyActions.clear.type;

        const asyncVerify = async (form: Dtos.DrugShipmentVerify) => {
            const verifiedForm = await unboundAsyncVerify(form);

            return verifiedForm;
        };

        return {
            ...bindActionCreatorsWithType({
                clear,
            }, dispatch),
            asyncVerify
        };
    }, [drugShipmentVerifyActions, dispatch, unboundAsyncVerify]);

    const drugShipmentVerifySelector = React.useCallback((state: IDrugShipmentVerifyStore) => {
        return drugShipmentVerifySelectors.selectDrugShipmentVerify(state)
    }, [drugShipmentVerifySelectors.selectDrugShipmentVerify]);

    const drugShipmentVerifyStateSelector = React.useCallback((state: IDrugShipmentVerifyStore) => {
        return drugShipmentVerifySelectors.selectVerifyState(state)
    }, [drugShipmentVerifySelectors.selectVerifyState]);

    const drugShipmentVerify = useDrugShipmentVerifySelector(drugShipmentVerifySelector);

    const drugShipmentVerifyState = useDrugShipmentVerifySelector(drugShipmentVerifyStateSelector);

    return [
        drugShipmentVerify,
        drugShipmentVerifyState,
        actions
    ];
}