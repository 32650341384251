import { ShipmentStatus } from '../api/dtos';

export const getShipmentStatusText = (shipmentStatus: ShipmentStatus): string => {
    switch (shipmentStatus) {
        case ShipmentStatus.New:
            return 'New';
        case ShipmentStatus.Ordered:
            return 'Ordered';
        case ShipmentStatus.InTransit:
            return 'In Transit';
        case ShipmentStatus.Available:
            return 'Available';
        case ShipmentStatus.Lost:
            return 'Lost';
        case ShipmentStatus.QuarantinedFromTransit:
            return 'Quarantined From Transit';
        case ShipmentStatus.Destroyed:
            return 'Destroyed';
        case ShipmentStatus.QuarantinedAtSite:
            return 'Quarantined At Site';
        default:
            return 'Unknown';
    }
};

export const getShipmentStatusDateDescription = (shipmentStatus: ShipmentStatus): string => {
    switch (shipmentStatus) {
        case ShipmentStatus.New:
            return 'Date Created';
        case ShipmentStatus.Ordered:
            return 'Date Ordered';
        case ShipmentStatus.InTransit:
            return 'Date Shipped';
        case ShipmentStatus.Available:
            return 'Date Received';
        case ShipmentStatus.Lost:
            return 'Date Lost';
        case ShipmentStatus.QuarantinedFromTransit:
            return 'Date Quarantined From Transit';
        case ShipmentStatus.Destroyed:
            return 'Date Destroyed';
        case ShipmentStatus.QuarantinedAtSite:
            return 'Date Quarantined At Site';
        default:
            return 'Unknown';
    }
};