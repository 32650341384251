/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae collapsible table component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useHistory } from 'react-router-dom';


import { Theme, makeStyles } from '@material-ui/core/styles';

import { Column } from 'material-table';


import {
    CollapsibleTable
} from '@ngt/opms';

import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import { useSaes } from '../hooks/useSaes';

import { IPatient } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeHistoryProps<TSaeForm extends Dtos.ISaeForm = Dtos.ISaeForm, TPatient extends IPatient = IPatient, TSae extends Dtos.Sae<TSaeForm, TPatient> = Dtos.Sae<TSaeForm, TPatient>> {
    columns: Array<Column<TSae>>;
    patientStudyNumber?: string,
    saeNumber?: number,
    institutionCode?: string
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    loading: {
        textAlign: 'center',
        padding: theme.spacing(3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const SaeHistory = <TSaeForm extends Dtos.ISaeForm = Dtos.ISaeForm, TPatient extends IPatient = IPatient, TSae extends Dtos.Sae<TSaeForm, TPatient> = Dtos.Sae<TSaeForm, TPatient>> ({
    columns,
    patientStudyNumber,
    saeNumber,
    institutionCode
}: ISaeHistoryProps<TSaeForm, TPatient, TSae>) => {
    const classes = useStyles();

    const history = useHistory();

    const [saes, saesLoadState, saesActions] = useSaes(
        institutionCode
    );

    React.useEffect(() => {
        saesActions.load();

        return () => {
            saesActions.clear();
        }
    }, [saesActions])

    const patientSaes = React.useMemo(() => {
        if (!saes) {
            return [];
        }

        return saes.filter(sae => sae.patient.studyNumber === patientStudyNumber && sae.form.saeNumber !== saeNumber).sort((a, b) => (a.form.saeNumber ?? 0) - (b.form.saeNumber ?? 0));
    }, [saes, patientStudyNumber, saeNumber]);

    const saesLoading = React.useMemo(() => {
        return saesLoadState.state === RequestState.None || saesLoadState.state === RequestState.Pending;
    }, [saesLoadState]);

    const onRowClick = React.useCallback((event?: React.MouseEvent<Element, MouseEvent>, sae?: TSae) => {
        history.push(`/sae/${institutionCode}/${sae?.patient?.studyNumber}/${sae?.form?.saeNumber}`)
    }, [history, institutionCode]);

    return (
        <>
            <CollapsibleTable
                title="SAE History"
                loading={saesLoading}
                data={patientSaes as any}
                columns={columns as any}
                entityName="SAEs"
                startCollapsed={true}
                pluralizeTitle={false}
                disabledTableText={"There is no other recorded SAE for this participant"}
                onRowClick={onRowClick}
            />
        </>
    );
}
/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default SaeHistory;
