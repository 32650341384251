/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { Autocomplete as MuiAutocomplete, AutocompleteProps as MuiAutocompleteProps } from '@material-ui/lab';

import { TextFieldProps as MuiTextFieldProps, TextField, Typography, Box } from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';
import { IInputRenderProps } from '../../../form/components/Input';
import FormLookupContext from '../../../contexts/form/FormLookupContext';
import { useMemo } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


type AutocompleteProps = Partial<MuiAutocompleteProps<any, false, false, true>> & IInputRenderProps<number, Dtos.IValidationError>

export interface IAutocompleteProps extends AutocompleteProps {
    TextFieldProps?: MuiTextFieldProps;
    options?: Dtos.ILookupItem[];
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Autocomplete: React.FunctionComponent<IAutocompleteProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    fullWidth,
    TextFieldProps,
    options,
    getOptionLabel,
    onChange,
    children,
    ...rest
}) => {
    const lookup = React.useContext(FormLookupContext);

    const optionsToUse = useMemo(() => {
        return options ?? lookup?.items ?? [];
    }, [options, lookup]);

    const valueToUse = useMemo(() => {
        return optionsToUse?.find(x => x.id == value) ?? null
    }, [optionsToUse, value])

    const onChangeCombined = React.useCallback((event, value: Dtos.ILookupItem) => {
        if (onChange) {
            onChange(event, value, "select-option");
        }

        onInputChange(value ? value?.id : undefined as any, true);
    }, [onChange, onInputChange]);

    return (
        <MuiAutocomplete
            {...rest as any}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            name={name}
            value={valueToUse}
            options={optionsToUse}
            onChange={onChangeCombined}
            getOptionLabel={getOptionLabel ? getOptionLabel : (option: Dtos.ILookupItem) => `${option.value}`}
            renderInput={(params) => {
                const onFocusCombined = (event: React.FocusEvent<HTMLInputElement>) => {
                    if ((params.inputProps as any).onFocus) {
                        (params.inputProps as any).onFocus(event);
                    }

                    onFocus();
                };

                const onBlurCombined = (event: React.FocusEvent<HTMLInputElement>) => {
                    if ((params.inputProps as any).onBlur) {
                        (params.inputProps as any).onBlur(event);
                    }

                    onBlur();
                };

                return (
                    <TextField
                        {...TextFieldProps}
                        {...params}
                        InputLabelProps={{
                            ...TextFieldProps?.InputLabelProps,
                            ...params.InputLabelProps
                        }}
                        InputProps={{
                            ...TextFieldProps?.InputProps,
                            ...params.InputProps
                        }}
                        inputProps={{
                            ...TextFieldProps?.inputProps,
                            ...params.inputProps,
                            onBlur: onBlurCombined,
                            onFocus: onFocusCombined
                        }}
                    />
                )
            }}
            popupIcon={<></>}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Autocomplete;
