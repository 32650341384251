/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the administer depot page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useParams } from 'react-router-dom';

import { Theme, makeStyles } from '@material-ui/core/styles';

/**
 * Used to format text.
 */
import Typography from '@material-ui/core/Typography';

import { RequestState, IRequestState } from '@ngt/request-utilities';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, MenuItem } from '@material-ui/core';

import { Column } from 'material-table';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DateTime } from 'luxon';

import * as Yup from 'yup';

import { Field, CollapsibleTable, Form, Numeric, Select, Checkbox, ResponseStatus, SubmitButton } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import * as Dtos from '../api/dtos';
import { useBatches } from '../hooks/useBatches';
import { useDepotBatch, IUseDepotBatchActions } from '../hooks/useDepotBatch';
import { useDepotBatches } from '../hooks/useDepotBatches';
import { useDepot } from '../hooks/useDepot';
import DepotModal from '../modals/DepotModal';
import { useOrderForms } from '../hooks/useOrderForms';
import DepotInformation from '../components/DepotInformation';
import DrugBreadcrumbs from '../components/DrugBreadcrumbs';
import { getDrugUnitName } from '../utilities/getDrugUnitName';
import useModal from '../hooks/useModal';
import { useLoading } from '../hooks/useLoading';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IAdministerDepotParams {
    depotId: string;
}

interface IAdministerDepotProps {
}

interface IDepotBatchModalProps {
    depotBatch: Dtos.DepotBatch | null;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    batches: Dtos.Batch[] | null;
    batchesLoadState: IRequestState<ResponseStatus>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    depotBatchActions: IUseDepotBatchActions;
}

interface IDepotBatchForm extends Partial<Dtos.DepotBatch> {
}

interface IDepotBatchFormProps {
    batches: Dtos.Batch[] | null;
    batchesLoadState: IRequestState<ResponseStatus>;
    setModalOpen: (open: boolean) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(4)
    },
    buttonSet: {
        paddingBottom: theme.spacing(1),
        textAlign: 'right'
    },
    button: {
        marginLeft: theme.spacing(3),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    dialogContent: {
        padding: '0'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Hooks
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const useDepotBatchColumns = (depotBatches: Dtos.DepotBatch[] | null,
    drugUnitName: string) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.DepotBatch>> = [
            {
                title: 'Batch Number',
                field: 'actualBatchNo',
                render: db => db.batch.batchNo
            },
            {
                title: 'Drug Type',
                field: 'drugName',
                render: db => db.batch.drug.drugName
            },
            {
                title: 'Expiry Date',
                field: 'expiryDate',
                render: db => db.batch.dateExpiration ?
                    DateTime.fromISO(db.batch.dateExpiration).toFormat('dd/MM/yyyy') :
                    null
            },
            {
                title: `${drugUnitName} Total`,
                field: 'drugUnitsTotal'
            },
            {
                title: `${drugUnitName} Allocated`,
                field: 'drugUnitsAllocated'
            },
            {
                title: `${drugUnitName} Destroyed`,
                field: 'drugUnitsDestroyed'
            },
            {
                title: `${drugUnitName} Available`,
                field: 'drugUnitsAvailable',
                render: db => db.drugUnitsTotal - (db.drugUnitsAllocated + db.drugUnitsDestroyed)
            },
        ]

        return cols;
    }, [depotBatches]);

    return columns as any;
};

const validation = Yup.object().shape<IDepotBatchForm>({
    batchId: Yup.number().required(),
    depotId: Yup.number().required(),
    drugUnitsTotal: Yup.number().required()
        .when(['drugUnitsAllocated', 'drugUnitsDestroyed'],
            (drugUnitsAllocated: number, drugUnitsDestroyed: number, schema: Yup.NumberSchema) => {
                return schema.min(drugUnitsAllocated + drugUnitsDestroyed, `Total can not be less than the sum of the rest of the drug unit values`);
            }),
    drugUnitsAllocated: Yup.number().required(),
    drugUnitsDestroyed: Yup.number().required()
});

const DepotBatchForm: React.FunctionComponent<IDepotBatchFormProps> = ({
    batches,
    batchesLoadState,
    setModalOpen
}) => {
    const classes = useStyles();

    const drugUnitName = getDrugUnitName(true);

    const onCloseClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setModalOpen(false);
    }, [setModalOpen]);

    const batchItems = React.useMemo(() => {
        if (!batches || batches.length === 0) {
            return [];
        }
        else {
            return batches.map(batch => {
                return <MenuItem
                    key={batch.id}
                    value={batch.id}
                >
                    {batch.batchNo}
                </MenuItem>;
            });
        }
    }, [batches]);

    return (
        <>
            <DialogContent
                className={classes.dialogContent}
            >
                {
                    batchesLoadState && batchesLoadState.state && batchesLoadState.state === RequestState.Pending ?
                        <CircularProgress /> :
                        <Field
                            name="batchId"
                            component={Select}
                            label="Batch"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            {batchItems}
                        </Field>
                }
                <Field
                    name="depotBatchNo"
                    component={Numeric}
                    label="Depot Batch Number"
                    disabled={true}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="drugUnitsTotal"
                    component={Numeric}
                    label={`${drugUnitName} Total`}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="drugUnitsAllocated"
                    component={Numeric}
                    label={`${drugUnitName} Allocated`}
                    disabled={true}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="drugUnitsDestroyed"
                    component={Numeric}
                    label={`${drugUnitName} Destroyed`}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="available"
                    component={Checkbox}
                    label="Available"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary" autoFocus>
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const DepotBatchModal: React.FunctionComponent<IDepotBatchModalProps> = ({
    depotBatch,
    modalOpen,
    setModalOpen,
    batches,
    batchesLoadState,
    depotBatchActions,
    onCloseClick,
    onFormSave
}) => {
    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'Depot Batch',
        validation: validation,
        asyncSave: depotBatchActions.asyncSave,
        onFormSave: onFormSave
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    Save Depot Batch
                </DialogTitle>
                <Form
                    initialValues={depotBatch}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onValidate={debouncedValidate}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <DepotBatchForm
                        batches={batches}
                        batchesLoadState={batchesLoadState}
                        setModalOpen={setModalOpen}
                    />
                </Form>
            </Dialog>
        </>
    );
}

const AdministerDepot: React.FunctionComponent<IAdministerDepotProps> = () => {
    const classes = useStyles();

    //const params = this.props
    const params = useParams<IAdministerDepotParams>();

    const depotId = parseInt(params.depotId, 10);

    const [depot, , , depotActions] = useDepot(depotId, true);

    const [batches, batchesLoadState, batchesActions] = useBatches();

    const [depotBatches, depotBatchesLoadState, depotBatchesActions] = useDepotBatches();

    const [depotBatch, depotBatchLoadState, , depotBatchActions] = useDepotBatch();

    const [orderForms, orderFormLoadState, orderFormActions] = useOrderForms();

    const [depotModalOpen, setDepotModalOpen] = React.useState(false);

    const [batchModalOpen, setBatchModalOpen] = React.useState(false);

    const [depotForm, setDepot] = React.useState<Dtos.Depot | null>(null);

    const [depotBatchForm, setDepotBatch] = React.useState<Dtos.DepotBatch | null>(null);

    const drugUnitName = getDrugUnitName(true);

    React.useEffect(() => {
        batchesActions.load();

        return () => {
            batchesActions.clear();
        }
    }, []);

    React.useEffect(() => {
        depotBatchesActions.loadByDepotId(depotId);

        return () => {
            depotBatchesActions.clear();
        }
    }, [depotId]);

    React.useEffect(() => {
        orderFormActions.load();

        return () => {
            orderFormActions.clear();
        }
    }, []);

    React.useEffect(() => {
        if (depotBatchLoadState.state === RequestState.Success) {
            setDepotBatch(depotBatch);

            setBatchModalOpen(true);
        }
    }, [depotBatchLoadState.state]);

    const onDepotFormSave = React.useCallback(() => {
        setDepotModalOpen(false);
        depotActions.load();
        depotBatchesActions.loadByDepotId(depotId);
    }, [setDepotModalOpen, depotId]);

    const onBatchFormSave = React.useCallback(() => {
        setBatchModalOpen(false);

        depotBatchActions.clear();

        depotBatchesActions.loadByDepotId(depotId);
    }, [setBatchModalOpen, depotId]);

    const editDepotClick = React.useCallback(() => {
        setDepot(depot);

        setDepotModalOpen(true);
    }, [depot, setDepotModalOpen]);

    const addDepotBatchClick = React.useCallback(() => {
        setDepotBatch(new Dtos.DepotBatch({
            depotId: depotId,
            drugUnitsAllocated: 0,
            drugUnitsDestroyed: 0,
            available: true
        }));

        setBatchModalOpen(true);
    }, [depotId, setBatchModalOpen]);

    const onRowClick = React.useCallback((event?: React.MouseEvent,
        rowData?: Dtos.DepotBatch) => {
        if (rowData &&
            rowData.id) {
            depotBatchActions.clear();
            depotBatchActions.load(rowData.id);
        }
    }, []);

    const depotBatchColumns = useDepotBatchColumns(depotBatches,
        drugUnitName);

    const depotBatchesLoading = useLoading(depotBatches,
        depotBatchesLoadState);

    //this is here for general cleanup in case something is loaded into redux and never reset
    React.useEffect(() => {
        return () => {
            depotBatchActions.clear();
        }
    }, []);

    return (
        <>
            <DepotModal
                depot={depotForm}
                modalOpen={depotModalOpen}
                setModalOpen={setDepotModalOpen}
                orderForms={orderForms}
                orderFormLoadState={orderFormLoadState}
                onFormSave={onDepotFormSave}
                depotActions={depotActions}
            />
            <DepotBatchModal
                depotBatch={depotBatchForm}
                modalOpen={batchModalOpen}
                setModalOpen={setBatchModalOpen}
                batches={batches}
                batchesLoadState={batchesLoadState}
                onFormSave={onBatchFormSave}
                depotBatchActions={depotBatchActions}
            />
            <DrugBreadcrumbs
                showInstitution={false}
                showDepot={true}
                depotCode={depot?.id?.toString()}
                depotName={depot?.depotName}
            />
            <div
                className={classes.container}
            >
                <DepotInformation
                    depot={depot}
                />
                <Typography
                    variant="h1"
                    color="secondary"
                    className={classes.heading}
                >
                    Administer Depot
                </Typography>
                <div
                    className={classes.buttonSet}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={editDepotClick}
                    >
                        Edit Depot
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={addDepotBatchClick}
                    >
                        Add Depot Batch
                    </Button>
                </div>
                <CollapsibleTable
                    title="Depot Batch"
                    loading={depotBatchesLoading}
                    data={depotBatches ?? []}
                    columns={depotBatchColumns}
                    onRowClick={onRowClick}
                />
            </div>
        </>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default AdministerDepot;