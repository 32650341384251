import * as React from 'react';

import * as pluralize from 'pluralize';
import DrugManagementContext from '../context/DrugManagementContext';

export const getDrugUnitName = (plural?: boolean): string => {
    const drugManagementContext = React.useContext(DrugManagementContext);

    let unitName = drugManagementContext.defaultUnit;

    if (!!plural) {
        unitName = pluralize.plural(unitName);
    }

    return unitName;
};