/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use pharmacists.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    ResponseStatus
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';

import {
    pharmacistsActions,
    usePharmacistsSelector,
    pharmacistsSelectors,
    IPharmacistsStore
} from '../store/modules/pharmacists';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUsePharmacistsActions {
    loadByInstCode: BoundActionCreator<typeof pharmacistsActions.loadByInstCode>;
    clear: BoundActionCreator<typeof pharmacistsActions.clear>;
}

export const usePharmacists = (): [Dtos.IPharmacist[] | null, IRequestState<ResponseStatus>, IUsePharmacistsActions] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {

        const loadByInstCode = (instCode: string) => pharmacistsActions.loadByInstCode(instCode);
        loadByInstCode.type = pharmacistsActions.loadByInstCode.type;

        const clear = () => pharmacistsActions.clear();
        clear.type = pharmacistsActions.clear.type;

        return bindActionCreators({
            loadByInstCode,
            clear
        }, dispatch);
    }, [pharmacistsActions, dispatch]);

    const pharmacistsSelector = React.useCallback((state: IPharmacistsStore) => {
        return pharmacistsSelectors.pharmacists(state);
    }, [pharmacistsSelectors.pharmacists]);

    const loadStateSelector = React.useCallback((state: IPharmacistsStore) => {
        return pharmacistsSelectors.loadState(state);
    }, [pharmacistsSelectors.loadState]);

    const pharmacists = usePharmacistsSelector(pharmacistsSelector);

    const loadState = usePharmacistsSelector(loadStateSelector);

    return [
        pharmacists as Dtos.IPharmacist[] | null,
        loadState,
        actions
    ];
};