/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the screeninglogs reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';
import { ScreeningSummary } from '../api/screeningSummary';

/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualScreeningSummariesState {
    screeningSummaries: Array<ScreeningSummary> | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IScreeningSummariesState {
    byContext: Record<string, IIndividualScreeningSummariesState>;
};

export interface IScreeningSummariesStore {
    screeningSummaries: IScreeningSummariesState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualScreeningSummariesState: IIndividualScreeningSummariesState = {
    screeningSummaries: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialScreeningSummariesState: IScreeningSummariesState = {
    byContext: {}
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class ScreeningSummariesReducer extends ImmerReducer<IScreeningSummariesState> {
    public load(institutionId?: number, year?: number) {
        const context = `${institutionId}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningSummariesState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(institutionId?: number, screeningSummaries?: Array<ScreeningSummary>) {
        const context = `${institutionId}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningSummariesState };
        }

        this.draftState.byContext[context].screeningSummaries = screeningSummaries ? screeningSummaries : null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(institutionId?: number, responseStatus?: ResponseStatus) {
        const context = `${institutionId}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningSummariesState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(institutionId?: number, year?: number) {
        const context = `${institutionId}`;

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialScreeningSummariesState };
    }
}

export const screeningSummariesActions = createActionCreators(ScreeningSummariesReducer);
export const screeningSummariesReducer = createReducerFunction(ScreeningSummariesReducer, initialScreeningSummariesState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createScreeningSummariesApi = (client: JsonServiceClient) => ({
    load: (institutionId?: number, year?: number) => {
        return client.get(new Dtos.ScreeningSummaryGetCollection({ 
            institutionId,
            year
        }));
    },
    loadByInstitutionId: (institutionId?: number) => {
        const request: Dtos.ScreeningSummaryGetCollectionByInstitutionId = new Dtos.ScreeningSummaryGetCollectionByInstitutionId({
            institutionId
        });
        return client.get(request);
    },
    loadByYear: (institutionId?: number, year?: number) => {
        const request:  Dtos.ScreeningSummaryGetCollectionByYear = new Dtos.ScreeningSummaryGetCollectionByYear({
            institutionId,
            year
        });
        return client.get(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createScreeningSummariesLogic = (api: ReturnType<typeof createScreeningSummariesApi>) => {
    const logic = {
        load: createLogic<IScreeningSummariesStore, {}, undefined, string, ReturnType<typeof screeningSummariesActions.load>>({
            type: screeningSummariesActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const [institutionId, year] = action.payload ? action.payload : [];
                
                try {
                    let response: Dtos.ScreeningSummaryResponse | null = null;

                    response = await api.load(institutionId, year);

                    dispatch(screeningSummariesActions.loadSuccess(
                        institutionId,
                        response.screeningSummary
                    ));
                }
                catch (error: any) {
                    dispatch(screeningSummariesActions.loadFailure(
                        institutionId,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ];
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useScreeningSummariesSelector: TypedUseSelectorHook<IScreeningSummariesStore> = useSelector;

export const screeningSummariesSelectors = {
    //banana
    screeningSummaries: (state: IScreeningSummariesStore, institutionId?: number) => {
        const context = `${institutionId}`;
        
        return state.screeningSummaries?.byContext[context]?.screeningSummaries ?? initialIndividualScreeningSummariesState.screeningSummaries;
    },
    loadState: (state: IScreeningSummariesStore, institutionId?: number) => {
        const context = `${institutionId}`;

        return state.screeningSummaries?.byContext[context]?.loadState ?? initialIndividualScreeningSummariesState.loadState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerScreeningSummariesReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createScreeningSummariesApi(client);

    const logic = createScreeningSummariesLogic(api);

    reducerRegistry.register('screeningSummaries', screeningSummariesReducer as Reducer, logic as any);
};

export default registerScreeningSummariesReducer;