/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the pharmacy management page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { RequestState, IRequestState } from '@ngt/request-utilities';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, MenuItem, Theme, makeStyles, Typography } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import * as Yup from 'yup';

import { Field, Form, Text, Select, ResponseStatus, SubmitButton, IInstitution, Numeric, Checkbox, FieldProvider } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { IUsePharmacyActions } from '../hooks/usePharmacy';
import useModal from '../hooks/useModal';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogContent: {
        padding: '0'
    },
    drugHeader: {
        padding: theme.spacing(3)
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IPharmacyModalProps {
    pharmacy: Dtos.Pharmacy | null;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    pharmacists: Dtos.IPharmacist[] | null;
    pharmacistsLoadState: IRequestState<ResponseStatus>;
    depots: Dtos.Depot[] | null;
    depotsLoadState: IRequestState<ResponseStatus>;
    drugs: Dtos.Drug[] | null;
    drugLoadState: IRequestState<ResponseStatus>;
    institutions: IInstitution[] | null;
    institutionLoadState: IRequestState<ResponseStatus>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    pharmacyActions: IUsePharmacyActions;
}

interface IPharmacyFormProps {
    pharmacy: Dtos.Pharmacy | null;
    pharmacists: Dtos.IPharmacist[] | null;
    pharmacistsLoadState: IRequestState<ResponseStatus>;
    depots: Dtos.Depot[] | null;
    depotsLoadState: IRequestState<ResponseStatus>;
    drugs: Dtos.Drug[] | null;
    drugLoadState: IRequestState<ResponseStatus>;
    institutions: IInstitution[] | null;
    institutionLoadState: IRequestState<ResponseStatus>;
    setModalOpen: (open: boolean) => void;
}

interface IPharmacyForm extends Partial<Dtos.Pharmacy> {
}

const validation = Yup.object().shape<IPharmacyForm>({
    institutionCode: Yup.string().required(),
    depotId: Yup.number().required(),
    pharmacyStatus: Yup.number().required(),
    pharmacyName: Yup.string().required(),
    pharmacyDrugs: Yup.array().of(Yup.object<Dtos.PharmacyDrug>().shape({
        minThreshold: Yup.number().required().label('Minimum threshold').min(0),
        capacity: Yup.number().required().label('Capacity').min(0)
    }))
});

const PharmacyForm: React.FunctionComponent<IPharmacyFormProps> = ({
    pharmacy,
    pharmacists,
    pharmacistsLoadState,
    depots,
    depotsLoadState,
    drugs,
    institutions,
    institutionLoadState,
    setModalOpen
}) => {
    const classes = useStyles();

    const onCloseClick = React.useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const institutionItems = React.useMemo(() => {
        if (!institutions || institutions.length === 0) {
            return [];
        }
        else {
            return institutions.map(institution => {
                return <MenuItem
                    key={institution.id}
                    value={institution.code}
                >
                    {institution.name}
                </MenuItem>;
            });
        }
    }, [institutions]);

    const depotItems = React.useMemo(() => {
        if (!depots || depots.length === 0) {
            return [];
        }
        else {
            return depots.map(depot => {
                return <MenuItem
                    key={depot.id}
                    value={depot.id}
                >
                    {depot.depotName}
                </MenuItem>;
            });
        }
    }, [depots]);

    const pharmacistItems = React.useMemo(() => {
        if (!pharmacists || pharmacists.length === 0) {
            return [];
        }
        else {
            return pharmacists.map(pharmacist => {
                return <MenuItem
                    key={pharmacist.id}
                    value={pharmacist.id}
                >
                    {
                        `${pharmacist.title ?? ''} ${pharmacist.firstName ?? ''} ${pharmacist.lastName ?? ''}`
                    }
                </MenuItem>;
            });
        }
    }, [pharmacists]);

    const pharmacyDrugs = React.useMemo(() => {
        if (pharmacy &&
            pharmacy.pharmacyDrugs &&
            pharmacy.pharmacyDrugs.length > 0) {
            return <FieldProvider
                name="pharmacyDrugs"
                autoRegister={false}
            >
                {
                    pharmacy.pharmacyDrugs.map((pd, i) => {
                        return (
                            <FieldProvider
                                key={`pd-${pd.id}`}
                                name={`[${i}]`}
                                autoRegister={false}
                            >
                                <Typography
                                    variant="h4"
                                    color="secondary"
                                    className={classes.drugHeader}
                                >
                                    {drugs?.find(d => d.id === pd.drugId)?.drugName}
                                </Typography>
                                <Field
                                    name={'minThreshold'}
                                    component={Numeric}
                                    label="Minimum Threshold"
                                />
                                <Field
                                    name={'capacity'}
                                    component={Numeric}
                                    label="Capacity"
                                />
                                <Field
                                    name={'enabled'}
                                    component={Checkbox}
                                    label="Enabled"
                                />
                            </FieldProvider>
                        );
                    })
                }
            </FieldProvider>;
        }

        return null;
    }, [pharmacy, drugs]);

    return (
        <>
            <DialogContent
                className={classes.dialogContent}
            >
                {
                    institutionLoadState && institutionLoadState.state && institutionLoadState.state === RequestState.Pending ?
                        <CircularProgress /> :
                        <Field
                            name="institutionCode"
                            component={Select}
                            disabled={true}
                            label="Institution"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            {institutionItems}
                        </Field>
                }
                <Field
                    name="pharmacyStatus"
                    component={Select}
                    label="Status"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                >
                    <MenuItem value={Dtos.PharmacyStatus.Inactive}>Inactive</MenuItem>
                    <MenuItem value={Dtos.PharmacyStatus.Open}>Open</MenuItem>
                    <MenuItem value={Dtos.PharmacyStatus.Closed}>Closed</MenuItem>
                </Field>
                {
                    pharmacistsLoadState && pharmacistsLoadState.state && pharmacistsLoadState.state === RequestState.Pending ?
                        <CircularProgress /> :
                        <Field
                            name="pharmacistPersonId"
                            component={Select}
                            label="Pharmacist"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            {pharmacistItems}
                        </Field>
                }
                {
                    depotsLoadState && depotsLoadState.state && depotsLoadState.state === RequestState.Pending ?
                        <CircularProgress /> :
                        <Field
                            name="depotId"
                            component={Select}
                            label="Depot"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            {depotItems}
                        </Field>
                }
                <Field
                    name="pharmacyName"
                    component={Text}
                    label="Pharmacy Name"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="phoneNumber"
                    component={Text}
                    label="Phone Number"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="address"
                    component={Text}
                    label="Address"
                    multiline={true}
                    rows={3}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="email"
                    component={Text}
                    label="Email"
                    multiline={true}
                    rows={3}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="ccOnEmails"
                    component={Checkbox}
                    label="CC on all Drug related emails"
                />
                {
                    pharmacyDrugs
                }
                <Field
                    name="notes"
                    component={Text}
                    label="Notes"
                    multiline={true}
                    rows={3}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary">
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const PharmacyModal: React.FunctionComponent<IPharmacyModalProps> = ({
    pharmacy,
    modalOpen,
    setModalOpen,
    pharmacists,
    pharmacistsLoadState,
    depots,
    depotsLoadState,
    drugs,
    drugLoadState,
    institutions,
    institutionLoadState,
    onCloseClick,
    onFormSave,
    pharmacyActions
}) => {

    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'pharmacy',
        validation: validation,
        asyncSave: pharmacyActions.asyncSave,
        onFormSave: onFormSave
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Save Pharmacy
                </DialogTitle>
                <Form
                    initialValues={pharmacy}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onValidate={debouncedValidate}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <PharmacyForm
                        pharmacy={pharmacy}
                        pharmacists={pharmacists}
                        pharmacistsLoadState={pharmacistsLoadState}
                        depots={depots}
                        depotsLoadState={depotsLoadState}
                        drugs={drugs}
                        drugLoadState={drugLoadState}
                        institutions={institutions}
                        institutionLoadState={institutionLoadState}
                        setModalOpen={setModalOpen}
                    />
                </Form>
            </Dialog>
        </>
    );
}

export default PharmacyModal;