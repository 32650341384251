/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving and saving a patientDrugs.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';



/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IPatientDrugsState {
    patientDrugs: Dtos.PatientDrug[] | null;
    loadState: IRequestState<ResponseStatus>;
    saveState: IRequestState<ResponseStatus>;
};

export interface IPatientDrugsStore {
    patientDrugs: IPatientDrugsState;
};

export interface IPatientDrugsForm {
    patientDrugs: Dtos.PatientDrug[] | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

const initialPatientDrugsState: IPatientDrugsState = {
    patientDrugs: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class PatientDrugsReducer extends ImmerReducer<IPatientDrugsState> {
    public loadById(id: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(patientDrugs?: Dtos.PatientDrug[]) {
        this.draftState.patientDrugs = patientDrugs ?? null;
        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus: ResponseStatus) {
        this.draftState.patientDrugs = null;
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public save(form: IPatientDrugsForm) {
        this.draftState.saveState = {
            state: RequestState.Pending
        };
    }

    public saveSuccess(form: IPatientDrugsForm) {
        this.draftState.patientDrugs = form.patientDrugs ?? null;
        this.draftState.saveState = {
            state: RequestState.Success
        };
    }

    public saveFailure(responseStatus: ResponseStatus) {
        this.draftState.patientDrugs = null;
        this.draftState.saveState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialPatientDrugsState };
    }
};

export const patientDrugsActions = createActionCreators(PatientDrugsReducer);
export const patientDrugsReducer = createReducerFunction(PatientDrugsReducer, initialPatientDrugsState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createPatientDrugsApi = (client: JsonServiceClient) => ({
    loadByPatientId: (patientId: number) => {
        return client.get(new Dtos.GetPatientDrugsByPatientId({
            patientId
        }));
    },
    save: (patientDrugs: Dtos.PatientDrug[]) => {
        return client.post(new Dtos.SetPatientDrugs({
            patientDrugs
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createPatientDrugsLogic = (api: ReturnType<typeof createPatientDrugsApi>) => {
    const logic = {
        loadByPatientId: createLogic<IPatientDrugsState, {}, undefined, string, ReturnType<typeof patientDrugsActions.loadById>>({
            type: patientDrugsActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload;

                try {
                    const response = await api.loadByPatientId(id);

                    dispatch(patientDrugsActions.loadSuccess(
                        response.patientDrugs
                    ));
                }
                catch (error: any) {
                    dispatch(patientDrugsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        save: createLogic<IPatientDrugsState, {}, undefined, string, ReturnType<typeof patientDrugsActions.save>>({
            type: patientDrugsActions.save.type,
            process: async ({ action }, dispatch, done) => {
                const patientDrugs = action.payload;

                try {
                    const response = await api.save(patientDrugs.patientDrugs!!);

                    dispatch(patientDrugsActions.saveSuccess(
                        { patientDrugs: response.patientDrugs } as IPatientDrugsForm
                    ));
                }
                catch (error: any) {
                    dispatch(patientDrugsActions.saveFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadByPatientId,
        logic.save
    ];
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const usePatientDrugsSelector: TypedUseSelectorHook<IPatientDrugsStore> = useSelector;

export const patientDrugsSelectors = {
    selectPatientDrugs: (state: IPatientDrugsStore) => state.patientDrugs.patientDrugs,
    selectLoadState: (state: IPatientDrugsStore) => state.patientDrugs.loadState,
    selectSaveState: (state: IPatientDrugsStore) => state.patientDrugs.saveState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerPatientDrugsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPatientDrugsApi(client);

    const logic = createPatientDrugsLogic(api);

    reducerRegistry.register('patientDrugs', patientDrugsReducer as Reducer, logic as any);
};

export default registerPatientDrugsReducer;