/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use a drugDispensation by the id
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    ResponseStatus,
    useAsyncFunction,
    BoundActionCreator,
    TypedFunction,
    bindActionCreatorsWithType
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

import {
    drugDispensationActions,
    useDrugDispensationSelector,
    drugDispensationSelectors,
    IDrugDispensationStore
} from '../store/modules/drugdispensation';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseDrugDispensationActions {
    load: BoundActionCreator<typeof drugDispensationActions.loadById>;
    clear: BoundActionCreator<typeof drugDispensationActions.clear>;
    save: BoundActionCreator<typeof drugDispensationActions.save>;
    deleteDispensation: BoundActionCreator<typeof drugDispensationActions.deleteDispensation>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<typeof drugDispensationActions.save>>, Promise<Dtos.DrugDispensation | undefined>>;
    asyncDelete: TypedFunction<Parameters<BoundActionCreator<typeof drugDispensationActions.deleteDispensation>>, Promise<Dtos.DrugDispensation | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useDrugDispensation = (): [
    Dtos.DrugDispensation | null,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IUseDrugDispensationActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncSave = useAsyncFunction(drugDispensationActions.save, drugDispensationActions.saveSuccess, drugDispensationActions.saveFailure);

    const unboundAsyncDelete = useAsyncFunction(drugDispensationActions.deleteDispensation, drugDispensationActions.deleteSuccess, drugDispensationActions.deleteFailure);

    const actions: IUseDrugDispensationActions = React.useMemo(() => {
        const load = (id: number) => drugDispensationActions.loadById(id);
        load.type = drugDispensationActions.loadById.type;

        const clear = () => drugDispensationActions.clear();
        clear.type = drugDispensationActions.clear.type;

        const save = (drugDispensation: Dtos.DrugDispensation) => drugDispensationActions.save(drugDispensation);
        save.type = drugDispensationActions.save.type;

        const deleteDispensation = (drugDispensation: Dtos.DrugDispensation) => drugDispensationActions.deleteDispensation(drugDispensation);
        deleteDispensation.type = drugDispensationActions.deleteDispensation.type;

        const asyncSave = async (drugDispensation?: Dtos.DrugDispensation) => {
            const savedDrugDispensation = await unboundAsyncSave(drugDispensation);

            return savedDrugDispensation;
        };

        const asyncDelete = async (drugDispensation?: Dtos.DrugDispensation) => {
            await unboundAsyncDelete(drugDispensation);

            return drugDispensation;
        };

        return {
            ...bindActionCreatorsWithType({
                load,
                clear,
                save,
                deleteDispensation
            }, dispatch),
            asyncSave,
            asyncDelete
        };
    }, [drugDispensationActions, dispatch, unboundAsyncSave]);

    const drugDispensationSelector = React.useCallback((state: IDrugDispensationStore) => {
        return drugDispensationSelectors.selectDrugDispensation(state)
    }, [drugDispensationSelectors.selectDrugDispensation]);

    const loadStateSelector = React.useCallback((state: IDrugDispensationStore) => {
        return drugDispensationSelectors.selectLoadState(state)
    }, [drugDispensationSelectors.selectLoadState]);

    const saveStateSelector = React.useCallback((state: IDrugDispensationStore) => {
        return drugDispensationSelectors.selectSaveState(state)
    }, [drugDispensationSelectors.selectSaveState]);

    const deleteStateSelector = React.useCallback((state: IDrugDispensationStore) => {
        return drugDispensationSelectors.selectDeleteState(state)
    }, [drugDispensationSelectors.selectDeleteState]);

    const drugDispensation = useDrugDispensationSelector(drugDispensationSelector);

    const loadState = useDrugDispensationSelector(loadStateSelector);

    const saveState = useDrugDispensationSelector(saveStateSelector);

    const deleteState = useDrugDispensationSelector(deleteStateSelector);

    return [
        drugDispensation,
        loadState,
        saveState,
        deleteState,
        actions
    ];
}