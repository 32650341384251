
/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { ScreeningSummary } from '../api/screeningSummary';
import { Theme, makeStyles, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog, Typography } from '@material-ui/core';
import ScreeningSummaryForm from './ScreeningSummaryForm';
import ScreeningSummaryContext from '../context/ScreeningSummaryContext';
import useScreeningSummaryForm from '../hooks/useScreeningSummaryForm';
import { IFormLabel } from '@ngt/opms';
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IScreeningSummaryDialogProps<TScreeningSummary extends ScreeningSummary = ScreeningSummary> {
    title?: string;
    formCancel?: () => void,
    formFieldGroup?: any,
    formLabels?:  IFormLabel[]
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogTitleColor: {
        color: theme.palette.secondary.main
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ScreeningSummaryDialog = ({
    title,
    formCancel,
    formFieldGroup,
    formLabels
}: IScreeningSummaryDialogProps) => {

    const classes = useStyles();

    const titleToUse = title ?? "Screening Summary Data Entry"

    const {
        handleSubmit,
        onFormCancel,
        onFormSubmit,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate
    } = useScreeningSummaryForm({ formType: undefined, afterFormSave: undefined, onCancel: formCancel, readOnly: false });

    return (
        <>
            {
                <Dialog open={true} onClose={undefined} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="form-dialog-title" className={classes.dialogTitleColor}>{titleToUse}</DialogTitle>
                    <DialogContent>
                        <ScreeningSummaryForm
                            formLabels={formLabels}
                            formFieldGroup={formFieldGroup}
                            onFormSubmit={onFormSubmit}
                            handleSubmit={handleSubmit}
                            onFormCancel={onFormCancel}
                            onSubmitFailed={onFormSubmitFailure}
                            onSubmitValidationFailure={onFormSubmitValidationFailure}
                            validate={validate}
                        />
                    </DialogContent>
                </Dialog>

            }
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningSummaryDialog;