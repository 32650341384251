/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import FieldGroupContext from '../../../../contexts/form/FieldGroupContext';
import FieldProvider from '../../FieldProvider';
import FormLabel from '../../FormLabel';
import { isString } from 'lodash-es';
import FormErrorHandler from '../../FormErrorHandler';
import FormErrorDisplay from '../../FormErrorDisplay';
import FieldErrorFlag from '../../FieldErrorFlag';
import Input, { IInputProps } from '../../Input';
import { InputComponentType, OmitInputRender } from '../../../../form/components/Input';
import { Table, TableHead, TableCell, TableBody, TableRow, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Box } from '@material-ui/core';
import FormGridHeader, { IFormGridHeaderProps } from './FormGridHeader';
import { IFormGridCellProps } from './FormGridCell';
import FormGridRow from './FormGridRow';
import useFieldState from '../../../../form/hooks/useFieldState';
import IDtoClass from '../../../../utilities/IDtoClass';
import useFieldActions from '../../../../form/hooks/useFieldActions';
import ConditionContext from '../../../../form/contexts/ConditionContext';
import useFormState from '../../../../form/hooks/useFormState';
import FormOptionsContext from '../../../../contexts/form/FormOptionsContext';
import useFormActions from '../../../../form/hooks/useFormActions';
import LookupsContext from '../../../../contexts/utility/LookupsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IFormGridProps<TValues extends Object = any> {
    type: IDtoClass<TValues>;
    name: string;
    columns: IFormGridCell<TValues>[];
    noErrors?: boolean;
    minRow?: number;
    onAdd?: (values: TValues[] | null | undefined) => TValues[] | null | undefined;
    onDelete?: (values: TValues[] | null | undefined, index: number) => TValues[] | null | undefined;
    allowDelete?: (values: TValues[] | null | undefined, index: number) => boolean;
    allowAdd?: boolean;
    onRowClick?: (index: number) => void;
    onAddClick?: (index: number) => void;
    hideRemoveColumn?: boolean;
    rowLabel?: string;
    rowValueAccessor?: string;
    getRowValue?: (getFieldValue: (path: string) => string, lookups: Dtos.ILookup[] | null, parentName: string, index?: number) => string | Promise<string|undefined> | undefined;
    gridNote?: JSX.Element;
}

export interface IFormGridCell<TValues extends Record<string, any> = any> extends Omit<IFormGridCellProps, 'name'> {
    name: Extract<keyof TValues, string>;
    header?: React.ReactNode;
    headerClassName?: string;
    minWidth?: number;
    width?: number;
    maxWidth?: number;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    container: {
        overflowX: 'auto'
    },
    table: {
        minWidth: '100%'
    },
    addButton: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(3)
    },
    removeCell: {
        width: 95
    },
    dialogTitle: {
        color: theme.palette.primary.main
    },
    dialogActions: {
        margin: theme.spacing(0, 1)
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */


const FormGrid = <TValues extends Object = any>({
    type,
    name,
    columns,
    noErrors,
    minRow,
    onAdd,
    onDelete,
    allowDelete,
    allowAdd,
    onRowClick,
    onAddClick,
    hideRemoveColumn,
    rowLabel,
    rowValueAccessor,
    getRowValue,
    gridNote
}: IFormGridProps<TValues>) => {
    const calculatedColumns: Array<IFormGridCell> = React.useMemo(() => {
        return columns?.filter(column => !!column.name) ?? [];
    }, [columns])

    return (
        <FormErrorHandler
            forceNew
        >
            <FieldProvider name={name}>
                <FormGridInternal
                    type={type}
                    name={name}
                    columns={calculatedColumns}
                    noErrors={noErrors}
                    minRow={minRow}
                    onAdd={onAdd}
                    onDelete={onDelete}
                    allowDelete={allowDelete}
                    allowAdd={allowAdd}
                    onRowClick={onRowClick}
                    onAddClick={onAddClick}
                    hideRemoveColumn={hideRemoveColumn}
                    rowLabel={rowLabel}
                    rowValueAccessor={rowValueAccessor}
                    getRowValue={getRowValue}
                    gridNote={gridNote}
                />
            </FieldProvider>
        </FormErrorHandler>
    )
}

const FormGridInternal = <TValues extends Object = any>({
    type,
    name,
    columns,
    noErrors,
    minRow,
    onAdd,
    onDelete,
    allowDelete,
    allowAdd,
    onRowClick,
    onAddClick,
    hideRemoveColumn,
    rowLabel,
    rowValueAccessor,
    getRowValue,
    gridNote
}: IFormGridProps<TValues>) => {
    const classes = useStyles();

    const { value } = useFieldState<TValues[], Dtos.IValidationError>({ value: true });
    const { setValue, getValue } = useFieldActions<TValues[], Dtos.IValidationError>();

    const addRow = React.useCallback(() => {
        if (onAdd) {
            setValue(onAdd(value));
        } else if (value) {
            setValue([...value, new type({})]);
        } else {
            setValue([new type({})]);
        }

        if (onAddClick && getValue() != null) {
            onAddClick((getValue() as TValues[]).length-1);
        }

    }, [setValue, value, onAdd, onAddClick, getValue]);

    const [removeIndex, setRemoveIndex] = React.useState<number | undefined>(undefined);

    const removeRowClick = React.useCallback((index: number) => {
        setRemoveIndex(index);
    }, [setRemoveIndex]);

    const removeRow = React.useCallback(() => {
        console.log('here')
        if (onDelete) {
            setValue(onDelete(value, removeIndex!!));
            setRemoveIndex(undefined);

            return;
        }

        if (!value) {
            return;
        }

        if (removeIndex!! >= value.length) {
            return;
        }

        setValue(value.filter((v, i) => removeIndex !== i));
        setRemoveIndex(undefined);

    }, [setValue, value, onDelete, removeIndex, setRemoveIndex]);

    const allowRemoveRow = React.useCallback((index: number) => {
        if (allowDelete) {
            return allowDelete(value, index);
        }

        return true;
    }, [value, allowDelete]);

    const { getFieldValue } = useFormActions();

    const { lookups } = React.useContext(LookupsContext);

    const rowLabelToUse = rowLabel ?? 'Entry';

    const [rowValueToUse, setRowValueToUse] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (getRowValue) {
            Promise
                .resolve(getRowValue(getFieldValue, lookups, name, removeIndex))
                .then(value => setRowValueToUse(value));

            return;
        }

        setRowValueToUse(rowValueAccessor ? getFieldValue(`${name}[${removeIndex}].${rowValueAccessor}`) : undefined);

    }, [getRowValue, getFieldValue, lookups, name, removeIndex, rowValueAccessor, setRowValueToUse]);

    React.useEffect(() => {
        if (!value && minRow) {
            let newValues: TValues[]|null|undefined = [];

            for (var i = 0; i < minRow; i++) {
                if (onAdd) {
                    newValues = onAdd(newValues);
                    continue;
                }

                if (newValues) {
                    newValues = [...newValues, new type({})];
                    continue;
                }

                newValues = [new type({})];
            }

            setValue(newValues);
        }
    }, [value, minRow, setValue]);

    const conditionContext = React.useContext(ConditionContext);
    const formOptionsContext = React.useContext(FormOptionsContext);
    const { submitting } = useFormState({ submitting: true });

    const disabled = React.useMemo(() => {
        return submitting || conditionContext?.conditionMet === false || formOptionsContext.readOnly;
    }, [conditionContext?.conditionMet, submitting, formOptionsContext]);

    const allowAddComputed = allowAdd ?? true;

    return (
        <>
            <div
                className={classes.container}
            >
                <Table
                    className={classes.table}
                >
                    <TableHead>
                        <TableRow>
                            {
                                columns?.map((column, i) => {
                                    return (
                                        <FormGridHeader
                                            key={i}
                                            name={column.name}
                                            content={column.header}
                                            className={column.headerClassName}
                                            maxWidth={column.maxWidth}
                                            minWidth={column.minWidth}
                                            width={column.width}
                                            hidden={column.hidden}
                                        />
                                    );
                                })
                            }
                            {
                                !disabled &&
                                !hideRemoveColumn && (
                                    <TableCell className={classes.removeCell} />
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            value?.map((value, i) => {
                                return (
                                    <FormGridRow
                                        key={i}
                                        removeRow={removeRowClick}
                                        cells={columns}
                                        index={i}
                                        noErrors={noErrors}
                                        disabled={disabled}
                                        allowDelete={allowRemoveRow}
                                        onClick={onRowClick}
                                        hideRemoveColumn={hideRemoveColumn}
                                    />
                                );
                            })
                        }
                    </TableBody>
                </Table>
                <FormErrorDisplay />
            </div>
            {
                <Box
                    className={classes.addButton}
                >
                    <Box>
                        {gridNote}
                    </Box>
                    <Box>
                        {
                            !disabled &&
                            allowAddComputed &&
                            (
                                <Button
                                    variant="contained"
                                    type="button"
                                    color="primary"
                                    onClick={addRow}
                                >
                                    Add
                                </Button>
                            )
                        }
                    </Box>
                </Box>
            }
            {
                removeIndex != undefined && 
                (
                    <Dialog open={removeIndex != undefined} onClose={() => setRemoveIndex(undefined)} aria-labelledby="remove-dialog" maxWidth="sm">
                        <DialogTitle className={classes.dialogTitle}>
                            Remove {rowLabelToUse}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to remove {rowValueToUse ?? 'this ' + rowLabelToUse.toLowerCase()}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                color="secondary"
                                onClick={() => setRemoveIndex(undefined)}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => removeRow()}
                                disabled={false}
                            >
                                Remove
                            </Button>
                        </DialogActions>
                    </Dialog>    
                )
            }
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormGrid;
