/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use a pharmacy by the id
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    ResponseStatus,
    useAsyncFunction,
    BoundActionCreator,
    TypedFunction,
    bindActionCreatorsWithType
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

import {
    pharmacyActions,
    usePharmacySelector,
    pharmacySelectors,
    IPharmacyStore,
    IQuarantinePharmacyStockForm,
    IQuarantineRemoveQuarantinedStockForm
} from '../store/modules/pharmacy';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUsePharmacyActions {
    loadById: BoundActionCreator<typeof pharmacyActions.loadById>;
    loadByInstCode: BoundActionCreator<typeof pharmacyActions.loadByInstCode>;
    clear: BoundActionCreator<typeof pharmacyActions.clear>;
    save: BoundActionCreator<typeof pharmacyActions.save>;
    quarantineStock: BoundActionCreator<typeof pharmacyActions.quarantineStock>;
    actionQuarantinedStock: BoundActionCreator<typeof pharmacyActions.actionQuarantinedStock>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<typeof pharmacyActions.save>>, Promise<Dtos.Pharmacy | undefined>>;
    asyncQuarantineStock: TypedFunction<Parameters<BoundActionCreator<typeof pharmacyActions.quarantineStock>>, Promise<IQuarantinePharmacyStockForm | undefined>>;
    asyncActionQuarantinedStock: TypedFunction<Parameters<BoundActionCreator<typeof pharmacyActions.actionQuarantinedStock>>, Promise<IQuarantineRemoveQuarantinedStockForm | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const usePharmacy = (): [
    Dtos.Pharmacy | null,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IUsePharmacyActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncSave = useAsyncFunction(pharmacyActions.save, pharmacyActions.saveSuccess, pharmacyActions.saveFailure);

    const unboundAsyncQuarantineStock = useAsyncFunction(pharmacyActions.quarantineStock,
        pharmacyActions.quarantineStockSuccess,
        pharmacyActions.quarantineStockFailure);

    const unboundAsyncActionQuarantinedStock = useAsyncFunction(pharmacyActions.actionQuarantinedStock,
        pharmacyActions.actionQuarantinedStockSuccess,
        pharmacyActions.actionQuarantinedStockFailure)


    const actions: IUsePharmacyActions = React.useMemo(() => {
        const loadById = (id: number) => pharmacyActions.loadById(id);
        loadById.type = pharmacyActions.loadById.type;

        const loadByInstCode = (institutionCode: string) => pharmacyActions.loadByInstCode(institutionCode);
        loadByInstCode.type = pharmacyActions.loadByInstCode.type;

        const clear = () => pharmacyActions.clear();
        clear.type = pharmacyActions.clear.type;

        const save = (pharmacy?: Dtos.Pharmacy) => pharmacyActions.save(pharmacy!!);
        save.type = pharmacyActions.save.type;

        const quarantineStock = (form: IQuarantinePharmacyStockForm) => pharmacyActions.quarantineStock(form);
        quarantineStock.type = pharmacyActions.quarantineStock.type;

        const actionQuarantinedStock = (form: IQuarantineRemoveQuarantinedStockForm) => pharmacyActions.actionQuarantinedStock(form);
        actionQuarantinedStock.type = pharmacyActions.actionQuarantinedStock.type;

        const asyncSave = async (pharmacy?: Dtos.Pharmacy) => {
            const savedPharmacy = await unboundAsyncSave(pharmacy);

            return savedPharmacy;
        };

        const asyncQuarantineStock = async (form: IQuarantinePharmacyStockForm) => {
            const returnForm = await unboundAsyncQuarantineStock(form);

            return returnForm;
        };

        const asyncActionQuarantinedStock = async (form: IQuarantineRemoveQuarantinedStockForm) => {
            const returnForm = await unboundAsyncActionQuarantinedStock(form);

            return returnForm;
        };

        return {
            ...bindActionCreatorsWithType({
                loadById,
                loadByInstCode,
                clear,
                save,
                quarantineStock,
                actionQuarantinedStock
            }, dispatch),
            asyncSave,
            asyncQuarantineStock,
            asyncActionQuarantinedStock
        };
    }, [pharmacyActions, dispatch, unboundAsyncSave, unboundAsyncQuarantineStock, unboundAsyncActionQuarantinedStock]);

    const pharmacySelector = React.useCallback((state: IPharmacyStore) => {
        return pharmacySelectors.selectPharmacy(state)
    }, [pharmacySelectors.selectPharmacy]);

    const loadStateSelector = React.useCallback((state: IPharmacyStore) => {
        return pharmacySelectors.selectLoadState(state)
    }, [pharmacySelectors.selectLoadState]);

    const saveStateSelector = React.useCallback((state: IPharmacyStore) => {
        return pharmacySelectors.selectSaveState(state)
    }, [pharmacySelectors.selectSaveState]);

    const quarantineStockStateSelector = React.useCallback((state: IPharmacyStore) => {
        return pharmacySelectors.selectQuarantineStockState(state)
    }, [pharmacySelectors.selectQuarantineStockState]);

    const actionQuarantinedStockStateSelector = React.useCallback((state: IPharmacyStore) => {
        return pharmacySelectors.selectActionQuarantinedStockState(state)
    }, [pharmacySelectors.selectActionQuarantinedStockState]);

    const pharmacy = usePharmacySelector(pharmacySelector);

    const loadState = usePharmacySelector(loadStateSelector);

    const saveState = usePharmacySelector(saveStateSelector);

    const quarantineStockState = usePharmacySelector(quarantineStockStateSelector);

    const actionQuarantinedStockState = usePharmacySelector(actionQuarantinedStockStateSelector);

    return [
        pharmacy,
        loadState,
        saveState,
        quarantineStockState,
        actionQuarantinedStockState,
        actions
    ];
}