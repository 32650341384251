/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the drug shipment status modal component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, Theme, makeStyles } from '@material-ui/core';

import * as Yup from 'yup';

import { Numeric, Field, SubmitButton, Form } from '@ngt/opms';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { IUseDrugShipmentActions } from '../hooks/useDrugShipment';

import { getShipmentStatusText } from '../utilities/getShipmentStatusText';
import useModal from '../hooks/useModal';


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogContent: {
        padding: '0'
    },
    hidden: {
        display: 'none'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugShipmentStatusModalProps {
    drugShipment: Dtos.DrugShipment | null;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    newStatus?: Dtos.ShipmentStatus | null;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    drugShipmentActions: IUseDrugShipmentActions;
}

interface IDrugShipmentStatusFormProps {
    drugShipment: Dtos.DrugShipment | null;
    setModalOpen: (open: boolean) => void;
    newStatus: Dtos.ShipmentStatus;
}

interface IDrugShipmentStatusForm extends Partial<Dtos.DrugShipment> {
}

const validation = Yup.object().shape<IDrugShipmentStatusForm>({
});

const DrugShipmentStatusForm: React.FunctionComponent<IDrugShipmentStatusFormProps> = ({
    newStatus,
    setModalOpen
}) => {

    const classes = useStyles();

    const onCloseClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setModalOpen(false);
    }, [setModalOpen]);

    const dialogText = React.useMemo(() => {
        const statusDesc = getShipmentStatusText(newStatus);

        switch (newStatus) {
            case Dtos.ShipmentStatus.Ordered:
                return `This will set the drug shipment's status to ${statusDesc} and send the drug shipment form to the depot. Do you wish to continue?`;
            case Dtos.ShipmentStatus.InTransit:
                return `This will set the drug shipment's status to ${statusDesc}. Do you wish to continue?`;
            case Dtos.ShipmentStatus.Available:
                return `This will set the drug shipment's status to ${statusDesc} and add the drugs to the institution's stock. Do you wish to continue?`;
            case Dtos.ShipmentStatus.Lost:
                return `This will set the drug shipment's status to ${statusDesc}. Do you wish to continue?`;
            case Dtos.ShipmentStatus.QuarantinedFromTransit:
                return `This will set the drug shipment's status to ${statusDesc}. The drugs will not be added to the pharmacy's stock. Do you wish to continue?`;
            default:
                return undefined;
        }
    }, [newStatus]);

    return (
        <>
            <DialogContent>
                {dialogText}
                <div
                    className={classes.hidden}
                >
                    <Field
                        name="shipmentStatus"
                        component={Numeric}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary">
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const DrugShipmentStatusModal: React.FunctionComponent<IDrugShipmentStatusModalProps> = ({
    drugShipment,
    newStatus,
    modalOpen,
    setModalOpen,
    onCloseClick,
    onFormSave,
    drugShipmentActions
}) => {
    if (!newStatus || !drugShipment) {
        return null;
    }

    const initialValues = Object.assign({}, drugShipment, { shipmentStatus: newStatus });

    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'drug shipment',
        validation: validation,
        asyncSave: drugShipmentActions.asyncSetStatus,
        onFormSave: onFormSave
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Change Drug Shipment Status
                </DialogTitle>
                <Form
                    initialValues={initialValues}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onValidate={debouncedValidate}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <DrugShipmentStatusForm
                        drugShipment={initialValues}
                        newStatus={newStatus}
                        setModalOpen={setModalOpen}
                    />
                </Form>
            </Dialog>
        </>
    );
}

export default DrugShipmentStatusModal;