/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving patientsDrugs.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';




/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IPatientsDrugsState {
    patientsDrugs: Dtos.PatientDrug[] | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IPatientsDrugsStore {
    patientsDrugs: IPatientsDrugsState;
};

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

const initialPatientsDrugsState: IPatientsDrugsState = {
    patientsDrugs: null,
    loadState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class PatientsDrugsReducer extends ImmerReducer<IPatientsDrugsState> {
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(patientsDrugs?: Dtos.PatientDrug[]) {
        this.draftState.patientsDrugs = patientsDrugs ?? null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialPatientsDrugsState };
    }
};

export const patientsDrugsActions = createActionCreators(PatientsDrugsReducer);
export const patientsDrugsReducer = createReducerFunction(PatientsDrugsReducer, initialPatientsDrugsState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createPatientsDrugsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.GetPatientDrugs());
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createPatientsDrugsLogic = (api: ReturnType<typeof createPatientsDrugsApi>) => {
    const logic = {
        load: createLogic<IPatientsDrugsStore, {}, undefined, string, ReturnType<typeof patientsDrugsActions.load>>({
            type: patientsDrugsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(patientsDrugsActions.loadSuccess(
                        response.patientDrugs
                    ));
                }
                catch (error: any) {
                    dispatch(patientsDrugsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }
    return [
        logic.load
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const usePatientsDrugsSelector: TypedUseSelectorHook<IPatientsDrugsStore> = useSelector;

export const patientsDrugsSelectors = {
    patientsDrugs: (state: IPatientsDrugsStore) => state.patientsDrugs.patientsDrugs,
    loadState: (state: IPatientsDrugsStore) => state.patientsDrugs.loadState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerPatientsDrugsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPatientsDrugsApi(client);

    const logic = createPatientsDrugsLogic(api);

    reducerRegistry.register('patientsDrugs', patientsDrugsReducer as Reducer, logic as any);
};

export default registerPatientsDrugsReducer;