/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an saes by institution codes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    ResponseStatus,
    IPatient, 
    TypedFunction,
    useAsyncFunction,
    bindActionCreatorsWithType,
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { ScreeningSummaryReducer, screeningSummaryActions, useScreeningSummarySelector, screeningSummarySelectors, IScreeningSummaryStore } from '../store/screeningSummary';
import { ScreeningSummary } from '../api/screeningSummary';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseScreeningSummaryActions {
    load: BoundActionCreator<OmitFirstArg<typeof screeningSummaryActions.load>>;
    clear: BoundActionCreator<OmitFirstArg<typeof screeningSummaryActions.clear>>;
    save: BoundActionCreator<OmitFirstArg<typeof screeningSummaryActions.save>>;
    asyncSave: TypedFunction<Parameters<BoundActionCreator<OmitFirstArg<typeof screeningSummaryActions.save>>>, Promise<ScreeningSummary | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useScreeningSummary = <TScreeningSummary extends ScreeningSummary = ScreeningSummary>(id?: number, autoLoad?: boolean): [
    TScreeningSummary | null,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IUseScreeningSummaryActions
] => {
    const dispatch = useDispatch();
    const unboundAsyncSave = useAsyncFunction(screeningSummaryActions.save, screeningSummaryActions.saveSuccess, screeningSummaryActions.saveFailure);
    const actions: IUseScreeningSummaryActions = React.useMemo(() =>{
        const load = () => screeningSummaryActions.load(id!!);
        load.type = screeningSummaryActions.load.type;

        const clear = () => screeningSummaryActions.clear(id!!);
        clear.type = screeningSummaryActions.clear.type;

        const save = (screeningSummary?: ScreeningSummary) => screeningSummaryActions.save(id, screeningSummary);
        save.type = screeningSummaryActions.save.type;

        const asyncSave = async (screeningSummary?: ScreeningSummary) => {
            const [, savedScreeningSummary] = await unboundAsyncSave([id, screeningSummary]);

            return savedScreeningSummary;
        };

        return {
            ...bindActionCreatorsWithType({
                load,
                clear,
                save,
            }, dispatch),
            asyncSave
        };
        }, [screeningSummaryActions, id, dispatch, unboundAsyncSave]);

    const screeningSummarySelector = React.useCallback((state: IScreeningSummaryStore) => {
        return screeningSummarySelectors.screeningSummary(state, id)
    }, [screeningSummarySelectors.screeningSummary, id]);

    const loadStateSelector = React.useCallback((state: IScreeningSummaryStore) => {
        return screeningSummarySelectors.loadState(state, id)
    }, [screeningSummarySelectors.loadState, id]);

    const saveStateSelector = React.useCallback((state: IScreeningSummaryStore) => {
        return screeningSummarySelectors.saveState(state, id)
    }, [screeningSummarySelectors.saveState, id]);

    const screeningSummary = useScreeningSummarySelector(screeningSummarySelector);

    const loadState = useScreeningSummarySelector(loadStateSelector);

    const saveState = useScreeningSummarySelector(saveStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();
            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, id]);

    return [
        screeningSummary as TScreeningSummary | null,
        loadState,
        saveState,
        actions
    ];
};

