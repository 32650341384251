/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the masterGroup reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualMasterGroupState {
    masterGroup: Dtos.IMasterGroup | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IMasterGroupState {
    byId: Record<number, IIndividualMasterGroupState>;
    byCode: Record<string, IIndividualMasterGroupState>;
}

export interface IMasterGroupStore {
    masterGroup: IMasterGroupState;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all masterGroup reducer state changes and actions. 
 */
export class MasterGroupReducer extends ImmerReducer<IMasterGroupState>
{
    public loadById(id: number) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualMasterGroupState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number, masterGroup?: Dtos.IMasterGroup) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualMasterGroupState };
        }

        this.draftState.byId[id].masterGroup = masterGroup ? masterGroup : null;

        this.draftState.byId[id].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualMasterGroupState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public loadByCode(code: string) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualMasterGroupState };
        }

        this.draftState.byCode[code].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByCodeSuccess(code: string, masterGroup?: Dtos.IMasterGroup) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualMasterGroupState };
        }

        this.draftState.byCode[code].masterGroup = masterGroup ? masterGroup : null;

        this.draftState.byCode[code].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodeFailure(code: string, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualMasterGroupState };
        }

        this.draftState.byCode[code].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number) {
        if (this.draftState.byId[id]) {
            delete this.draftState.byId[id];
        }
    }

    public clearByCode(code: string) {
        if (this.draftState.byCode[code]) {
            delete this.draftState.byCode[code];
        }
    }

    public clearAll() {
        this.draftState = { ...initialMasterGroupState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualMasterGroupState: IIndividualMasterGroupState = {
    masterGroup: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialMasterGroupState: IMasterGroupState = {
    byCode: {},
    byId: {}
}

export const masterGroupActions = createActionCreators(MasterGroupReducer);
export const masterGroupReducer = createReducerFunction(MasterGroupReducer, initialMasterGroupState);

const createMasterGroupApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.MasterGroupGetSingleById({ id }));
    },
    loadByCode: (code: string) => {
        return client.get(new Dtos.MasterGroupGetSingleByCode({ code }));
    }
});

export const createMasterGroupLogic = (api: ReturnType<typeof createMasterGroupApi>) => {
    const logics = {
        loadById: createLogic<IMasterGroupStore, {}, undefined, string, ReturnType<typeof masterGroupActions.loadById>>({
            type: masterGroupActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadById(action.payload);

                    dispatch(masterGroupActions.loadByIdSuccess(
                        action.payload,
                        response.masterGroup
                    ));
                }
                catch (error: any) {
                    dispatch(masterGroupActions.loadByIdFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCode: createLogic<any, {}, undefined, string, ReturnType<typeof masterGroupActions.loadByCode>>({
            type: masterGroupActions.loadByCode.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadByCode(action.payload);
                    dispatch(masterGroupActions.loadByCodeSuccess(
                        action.payload,
                        response.masterGroup
                    ));
                }
                catch (error: any) {
                    dispatch(masterGroupActions.loadByCodeFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadByCode,
        logics.loadById
    ]
}

export const useMasterGroupSelector: TypedUseSelectorHook<IMasterGroupStore> = useSelector;

export const masterGroupSelectors = {
    masterGroupById: (state: IMasterGroupStore, id: number) => state.masterGroup.byId[id]?.masterGroup ?? null,
    loadStateById: (state: IMasterGroupStore, id: number) => state.masterGroup.byId[id]?.loadState ?? initialIndividualMasterGroupState.loadState,
    masterGroupByCode: (state: IMasterGroupStore, code: string) => state.masterGroup.byCode[code]?.masterGroup ?? null,
    loadStateByCode: (state: IMasterGroupStore, code: string) => state.masterGroup.byCode[code]?.loadState ?? initialIndividualMasterGroupState.loadState,
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerMasterGroupReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createMasterGroupApi(client);

    const logic = createMasterGroupLogic(api);

    reducerRegistry.register('masterGroup', masterGroupReducer as Reducer, logic as any);
};

export default registerMasterGroupReducer;