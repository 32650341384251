/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the institutions reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IIndividualInstitutionsState {
    institutions: Dtos.IInstitution[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IInstitutionsState {
    byIds: Record<string, IIndividualInstitutionsState>;
    byCodes: Record<string, IIndividualInstitutionsState>;
}

export interface IInstitutionsStore {
    institutions: IInstitutionsState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null) => {
    return `${(masterGroupId ?? 'null')}-${(collaboratingGroupId ?? 'null')}-${(countryId ?? 'null')}`
}

const createCodesContext = (masterGroupId?: string | null, collaboratingGroupId?: string | null, countryId?: string | null) => {
    return `${(masterGroupId ?? 'null')}-${(collaboratingGroupId ?? 'null')}-${(countryId ?? 'null')}`
}


/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all institutions reducer state changes and actions. 
 */
export class InstitutionsReducer extends ImmerReducer<IInstitutionsState>
{
    public loadByIds(masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualInstitutionsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByIdsSuccess(masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null, institutions?: Dtos.IInstitution[]) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualInstitutionsState };
        }

        this.draftState.byIds[context].institutions = institutions ? institutions : null;

        this.draftState.byIds[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdsFailure(masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualInstitutionsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByIds(masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId);

        this.draftState.byIds[context] = { ...initialIndividualInstitutionsState }
    }

    public loadByCodes(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualInstitutionsState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByCodesSuccess(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, institutions?: Dtos.IInstitution[]) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualInstitutionsState };
        }

        this.draftState.byCodes[context].institutions = institutions ? institutions : null;

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodesFailure(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualInstitutionsState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByCodes(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode);

        this.draftState.byCodes[context] = { ...initialIndividualInstitutionsState }
    }

    public clearAll() {
        this.draftState = { ...initialInstitutionsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialInstitutionsState: IInstitutionsState = {
    byIds: {},
    byCodes: {}
}

export const initialIndividualInstitutionsState: IIndividualInstitutionsState = {
    institutions: null,
    loadState: {
        state: RequestState.None
    }
}

export const institutionsActions = createActionCreators(InstitutionsReducer);
export const institutionsReducer = createReducerFunction(InstitutionsReducer, initialInstitutionsState);

const createInstitutionsApi = (client: JsonServiceClient) => ({
    loadByIds: (masterGroupId?: number, collaboratingGroupId?: number, countryId?: number) => {
        if (collaboratingGroupId) {
            return client.get(new Dtos.InstitutionGetCollectionByCollaboratingGroupId({ collaboratingGroupId, countryId }));
        }
        else if (masterGroupId) {
            return client.get(new Dtos.InstitutionGetCollectionByMasterGroupId({ masterGroupId, countryId }));
        }
        else if (countryId) {
            return client.get(new Dtos.InstitutionGetCollectionByCountryId({ countryId }));
        }
        else {
            return client.get(new Dtos.InstitutionGetCollection());
        }
    },
    loadByCodes: (masterGroupCode?: string, collaboratingGroupCode?: string, countryCode?: string) => {
        if (collaboratingGroupCode) {
            return client.get(new Dtos.InstitutionGetCollectionByCollaboratingGroupCode({ collaboratingGroupCode, countryCode }));
        }
        else if (masterGroupCode) {
            return client.get(new Dtos.InstitutionGetCollectionByMasterGroupCode({ masterGroupCode, countryCode }));
        }
        else if (countryCode) {
            return client.get(new Dtos.InstitutionGetCollectionByCountryCode({ countryCode }));
        }
        else {
            return client.get(new Dtos.InstitutionGetCollection());
        }
    }
});

const createInstitutionsLogic = (api: ReturnType<typeof createInstitutionsApi>) => {
    const logic = {
        loadByIds: createLogic<IInstitutionsStore, {}, undefined, string, ReturnType<typeof institutionsActions.loadByIds>>({
            type: institutionsActions.loadByIds.type,
            process: async ({ action }, dispatch, done) => {
                const [masterGroupId, collaboratingGroupId, countryId] = action.payload;

                try {
                    const response = await api.loadByIds(masterGroupId ?? undefined, collaboratingGroupId ?? undefined, countryId ?? undefined);

                    dispatch(institutionsActions.loadByIdsSuccess(
                        masterGroupId,
                        collaboratingGroupId,
                        countryId,
                        response.institutions
                    ));
                }
                catch (error: any) {
                    dispatch(institutionsActions.loadByIdsFailure(masterGroupId, collaboratingGroupId, countryId, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCodes: createLogic<IInstitutionsStore, {}, undefined, string, ReturnType<typeof institutionsActions.loadByCodes>>({
            type: institutionsActions.loadByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const [masterGroupCode, collaboratingGroupCode, countryCode] = action.payload;

                try {
                    const response = await api.loadByCodes(masterGroupCode ?? undefined, collaboratingGroupCode ?? undefined, countryCode ?? undefined);

                    dispatch(institutionsActions.loadByCodesSuccess(
                        masterGroupCode,
                        collaboratingGroupCode,
                        countryCode,
                        response.institutions
                    ));
                }
                catch (error: any) {
                    dispatch(institutionsActions.loadByCodesFailure(masterGroupCode, collaboratingGroupCode, countryCode, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadByIds,
        logic.loadByCodes
    ]
};

export const useInstitutionsSelector: TypedUseSelectorHook<IInstitutionsStore> = useSelector;

export const institutionsSelectors = {
    institutionsByIds: (state: IInstitutionsStore, masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null) => {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId);

        if (!state.institutions.byIds[context]) {
            return initialIndividualInstitutionsState.institutions;
        }

        return state.institutions.byIds[context].institutions;
    },
    loadStateByIds: (state: IInstitutionsStore, masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null) => {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId);

        if (!state.institutions.byIds[context]) {
            return initialIndividualInstitutionsState.loadState;
        }

        return state.institutions.byIds[context].loadState;
    },
    institutionsByCodes: (state: IInstitutionsStore, masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null) => {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode);

        if (!state.institutions.byCodes[context]) {
            return initialIndividualInstitutionsState.institutions;
        }

        return state.institutions.byCodes[context].institutions;
    },
    loadStateByCodes: (state: IInstitutionsStore, masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null) => {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode);

        if (!state.institutions.byCodes[context]) {
            return initialIndividualInstitutionsState.loadState;
        }

        return state.institutions.byCodes[context].loadState;
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerInstitutionsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createInstitutionsApi(client);

    const logic = createInstitutionsLogic(api);

    reducerRegistry.register('institutions', institutionsReducer as Reducer, logic as any);
};

export default registerInstitutionsReducer;