/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected event.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';


/*
 * Used to show loading view.
 */
import { RouteLoading, useInstitutionById, InstitutionContext } from '@ngt/opms';

import ScreeningSummaryContext from '../context/ScreeningSummaryContext';

import { useScreeningSummary } from '../hooks/useScreeningSummary';
import { initialInstitutionState } from '@ngt/opms/dist/store/modules/data/institution';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IScreeningSummaryResolverProps {
    id?: number;
    institutionId?: number;
    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const ScreeningSummaryResolver: React.FunctionComponent<IScreeningSummaryResolverProps> = ({
    id,
    institutionId,
    resolveBeforeLoad,
    children
}) => {

    let conditionalContexts: React.ReactNode = children;

    // Load ScreeningSummary
    const [screeningSummary, screeningSummaryLoadState, screeningSummarySaveState, screeningSummaryActions] =  useScreeningSummary(id, true);

    const screeningSummaryContext = React.useMemo(() => {
        return {
            screeningSummary,
            loadState: screeningSummaryLoadState,
            saveState: screeningSummarySaveState,
            actions: screeningSummaryActions,
        };
    }, [screeningSummary, screeningSummaryLoadState, screeningSummaryActions]);

    //Load institution
    const [institution, institutionLoadState, institutionActions] = useInstitutionById(institutionId!!, !!institutionId ? true : false);

    const institutionContext = React.useMemo(() => {
        return {
            institution: institution,
            loadState: institutionLoadState,
            actions: institutionActions
        };
    }, [institution, institutionLoadState, institutionActions]);

    return (
        <InstitutionContext.Provider value={institutionContext} >
            <ScreeningSummaryContext.Provider value={screeningSummaryContext}>
                {
                    conditionalContexts
                }
            </ScreeningSummaryContext.Provider>
        </InstitutionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningSummaryResolver;