/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the drug shipment received modal component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { IRequestState } from '@ngt/request-utilities';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, MenuItem, makeStyles, Theme, Typography } from '@material-ui/core';

import * as Yup from 'yup';

import { Field, Form, Text, Select, Numeric, ResponseStatus, IFormGridCell, Input, FormGrid, SubmitButton, DatePicker, FileUpload } from '@ngt/opms';
import { DateTime } from 'luxon';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { getDrugUnitName } from '../utilities/getDrugUnitName';
import { IUseDrugShipmentVerifyActions } from '../hooks/useDrugShipmentVerify';
import useModal from '../hooks/useModal';
import { convertStringToDate } from '../utilities/convertStringToDate';
import DrugManagementContext from '../context/DrugManagementContext';
import DrugShipmentDocuments from '../components/DrugShipmentDocuments';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    captionContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    dialogContent: {
        padding: '0'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */



/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugShipmentReceivedModalProps {
    drugShipment: Dtos.DrugShipment | null;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    depotBatches: Dtos.DepotBatch[] | null;
    depotBatchesLoadState: IRequestState<ResponseStatus>;
    drugs: Dtos.Drug[] | null;
    drugLoadState: IRequestState<ResponseStatus>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    onExit?: (node: HTMLElement) => void;
    drugShipmentVerifyActions: IUseDrugShipmentVerifyActions;
}

interface IDrugShipmentReceivedFormProps {
    drugShipmentVerify: Dtos.DrugShipmentVerify | null;
    depotBatches: Dtos.DepotBatch[] | null;
    depotBatchesLoadState: IRequestState<ResponseStatus>;
    drugs: Dtos.Drug[] | null;
    drugLoadState: IRequestState<ResponseStatus>;
    setModalOpen: (open: boolean) => void;
}

interface IDrugShipmentReceivedForm extends Partial<Dtos.DrugShipmentVerify> {
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

//const drugUnitName = getDrugUnitName(true);

const validation = Yup.object().shape<IDrugShipmentReceivedForm>({
    consignmentNo: Yup.string(),
    received: Yup.string().required(),
    drugShipmentDepotBatches: Yup.array()
        .min(1, 'At least one batch of drugs must exist on the shipment')
        .of(Yup.object<Dtos.DrugShipmentDepotBatchVerify>().shape({
            drugUnits: Yup.number().required()
                .when(['drugUnitsReceived', 'drugUnitsLost', 'drugUnitsDamaged', 'drugUnitsDestroyed', 'drugUnitsQuarantined'],
                    (drugUnitsReceived: number, drugUnitsLost: number, drugUnitsDamaged: number, drugUnitsDestroyed: number, drugUnitsQuarantined: number, schema: Yup.NumberSchema) => {
                        const total: number = drugUnitsReceived + drugUnitsLost + drugUnitsDamaged + drugUnitsDestroyed + drugUnitsQuarantined;

                        if (drugUnitsReceived === 0 && drugUnitsLost === 0 && drugUnitsDamaged === 0 && drugUnitsDestroyed === 0 && drugUnitsQuarantined > 0) {
                            //the max 0 is a bit of a hack to make sure this always fails
                            return schema.max(0, 'If the entire shipment has been quarantined, please use the quarantine shipment button');
                        }

                        if (drugUnitsReceived === 0 && drugUnitsLost > 0 && drugUnitsDamaged === 0 && drugUnitsDestroyed === 0 && drugUnitsQuarantined === 0) {
                            //the max 0 is a bit of a hack to make sure this always fails
                            return schema.max(0, 'If the entire shipment has been lost, please get the trial staff to use the lost shipment button');
                        }

                        return schema.oneOf([total], `Total must be equal to the sum of the rest of the drug unit values`);
                    }),
            drugUnitsReceived: Yup.number().required().min(0),
            drugUnitsDamaged: Yup.number().required().min(0),
            drugUnitsDestroyed: Yup.number().required().min(0),
            drugUnitsQuarantined: Yup.number().required().min(0),
            drugUnitsLost: Yup.number().required().min(0)
        }))
});

const DrugShipmentReceivedForm: React.FunctionComponent<IDrugShipmentReceivedFormProps> = ({
    drugShipmentVerify,
    depotBatches,
    depotBatchesLoadState,
    setModalOpen
}) => {
    const classes = useStyles();
    const drugManagementContext = React.useContext(DrugManagementContext);
    const drugUnitName = getDrugUnitName(true);

    const onCloseClick = React.useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const allowDelete = React.useCallback(() => {
        return false;
    }, []);

    const depotBatchItems = React.useMemo(() => {
        if (!depotBatches || depotBatches.length === 0) {
            return [];
        }
        else {
            const depotBatchesForForm = depotBatches;

            return depotBatchesForForm.map(depotBatch => {
                return <MenuItem
                    key={depotBatch.id}
                    value={depotBatch.id}
                >
                    {`${depotBatch.batch.drug.drugName} (${depotBatch.batch.batchNo} (${depotBatch.depotBatchNo} Expires ${DateTime.fromISO(depotBatch.batch.dateExpiration!!).toFormat('dd/MM/yyyy')}))`}
                </MenuItem>;
            });
        }
    }, [depotBatches, depotBatchesLoadState]);

    const drugShipmentDepotBatches = React.useMemo(() => {
        if (drugShipmentVerify &&
            drugShipmentVerify.drugShipmentDepotBatches &&
            drugShipmentVerify.drugShipmentDepotBatches.length > 0) {

            const columns: Array<IFormGridCell<Dtos.DrugShipmentDepotBatchVerify>> = [
                {
                    name: 'depotBatchId',
                    content: (
                        <Input
                            component={Select}
                            disabled={true}
                        >
                            {depotBatchItems}
                        </Input>
                    )
                },
                {
                    name: 'drugUnits',
                    header: `Total ${drugUnitName} in Shipment`,
                    content: (
                        <Input
                            component={Numeric}
                            disabled={true}
                        />
                    )
                },
                {
                    name: 'drugUnitsReceived',
                    header: `${drugUnitName} received in good condition`,
                    content: (
                        <Input
                            component={Numeric}
                        />
                    )
                },
                {
                    name: 'drugUnitsDamaged',
                    header: `${drugUnitName} damaged *`,
                    content: (
                        <Input
                            component={Numeric}
                        />
                    )
                },
                {
                    name: 'drugUnitsDestroyed',
                    header: `${drugUnitName} destroyed *`,
                    content: (
                        <Input
                            component={Numeric}
                        />
                    )
                },
                {
                    name: 'drugUnitsQuarantined',
                    header: `${drugUnitName} quarantined *`,
                    content: (
                        <Input
                            component={Numeric}
                        />
                    )
                },
                {
                    name: 'drugUnitsLost',
                    header: `${drugUnitName} lost`,
                    content: (
                        <Input
                            component={Numeric}
                        />
                    )
                }
            ];

            return <FormGrid
                name="drugShipmentDepotBatches"
                columns={columns}
                type={Dtos.DrugShipmentDepotBatchVerify}
                allowAdd={false}
                allowDelete={allowDelete}
            />;
        }

        return null;
    }, [drugShipmentVerify, depotBatchesLoadState, depotBatchItems]);

    return (
        <>
            <DialogContent
                className={classes.dialogContent}
            >
                <Field
                    name="consignmentNo"
                    component={Text}
                    label={drugManagementContext.consignmentNumberLabel}
                    disabled={true}
                />
                <Field
                    name="received"
                    label="Received"
                    component={DatePicker}
                    parse={convertStringToDate}
                />
                {
                    drugShipmentDepotBatches
                }
                <div
                    className={classes.captionContainer}
                >
                    <Typography
                        variant="caption"
                    >
                        * requires authorisation
                    </Typography>
                </div>
                <Field
                    name="notes"
                    component={Text}
                    label="Notes"
                    multiline={true}
                    rows={4}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <DrugShipmentDocuments />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary">
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const DrugShipmentReceivedModal: React.FunctionComponent<IDrugShipmentReceivedModalProps> = ({
    drugShipment,
    modalOpen,
    setModalOpen,
    depotBatches,
    depotBatchesLoadState,
    drugs,
    drugLoadState,
    onCloseClick,
    onExit,
    onFormSave,
    drugShipmentVerifyActions
}) => {
    if (!drugShipment) {
        return null;
    }

    const drugShipmentVerify: Dtos.DrugShipmentVerify = Object.assign({},
        {
            id: drugShipment.id,
            consignmentNo: drugShipment.consignmentNo,
            received: DateTime.local().toISODate(),
            notes: drugShipment.notes,
            drugShipmentDocuments: []
        },
        {
            drugShipmentDepotBatches: Array.from(drugShipment.drugShipmentDepotBatches, (drugShipmentDepotBatch) => {
                return Object.assign({}, { ...drugShipmentDepotBatch }, { drugUnitsReceived: 0 }) as Dtos.DrugShipmentDepotBatchVerify;
            })
        });

    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'drug shipment',
        validation: validation,
        asyncSave: drugShipmentVerifyActions.asyncVerify,
        onFormSave: onFormSave
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="lg"
                onExit={onExit}
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Drug Shipment Received
                </DialogTitle>
                <Form
                    initialValues={drugShipmentVerify}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onValidate={debouncedValidate}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <DrugShipmentReceivedForm
                        drugShipmentVerify={drugShipmentVerify}
                        depotBatches={depotBatches}
                        depotBatchesLoadState={depotBatchesLoadState}
                        drugs={drugs}
                        drugLoadState={drugLoadState}
                        setModalOpen={setModalOpen}
                    />
                </Form>
            </Dialog>
        </>
    );
}

export default DrugShipmentReceivedModal;