/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use patientsDrugs.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    ResponseStatus
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { patientsDrugsActions, usePatientsDrugsSelector, patientsDrugsSelectors, IPatientsDrugsStore } from '../store/modules/patientsdrugs';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUsePatientsDrugsActions {
    load: BoundActionCreator<OmitFirstArg<typeof patientsDrugsActions.load>>;
    clear: BoundActionCreator<OmitFirstArg<typeof patientsDrugsActions.clear>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const usePatientsDrugs = (autoLoad?: boolean): [
    Dtos.PatientDrug[] | null,
    IRequestState<ResponseStatus>,
    IUsePatientsDrugsActions
] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {
        const load = () => patientsDrugsActions.load();
        load.type = patientsDrugsActions.load.type;

        const clear = () => patientsDrugsActions.clear();
        clear.type = patientsDrugsActions.clear.type;

        return bindActionCreators({
            load,
            clear
        }, dispatch);
    }, [patientsDrugsActions, dispatch]);

    const patientsDrugsSelector = React.useCallback((state: IPatientsDrugsStore) => {
        return patientsDrugsSelectors.patientsDrugs(state)
    }, [patientsDrugsSelectors.patientsDrugs]);

    const loadStateSelector = React.useCallback((state: IPatientsDrugsStore) => {
        return patientsDrugsSelectors.loadState(state)
    }, [patientsDrugsSelectors.loadState]);

    const patientsDrugs = usePatientsDrugsSelector(patientsDrugsSelector);

    const loadState = usePatientsDrugsSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad]);

    return [
        patientsDrugs as Dtos.PatientDrug[] | null,
        loadState,
        actions
    ];
};

