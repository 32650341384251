import { IFormState, IFormValidationError, IReturn, IValidationError, OnlinePatientManagementContext, pascalToCameCasePropertyPath } from "@ngt/opms";
import React, { useContext } from "react";
import { DrugManagementValidationResponse } from "../api/dtos";

export const useValidation = <TDto extends object, TValidationResponse extends DrugManagementValidationResponse = DrugManagementValidationResponse, TValidationRequest extends IReturn<TValidationResponse> = IReturn<TValidationResponse>>(
    createValidationRequest: (formValues: TDto) => TValidationRequest
) => {
    const onlinePatientManagement = useContext(OnlinePatientManagementContext);

    return React.useCallback(async (formState: IFormState<TDto, IValidationError>) => {
        // Send request to server.
        const response = await onlinePatientManagement
            .serviceStackClient
            .post((createValidationRequest(formState.values)));

        // parse errors into a format the form understands.
        const groupErrors = response.validationResult?.errors?.reduce((a: Record<string, IFormValidationError[]>, b: IFormValidationError) => {
            const propertyName = pascalToCameCasePropertyPath(b.property)

            if (!a[propertyName]) {
                a[propertyName] = [];
            }

            a[propertyName].push(b);

            return a;
        }, {}) ?? {};

        return groupErrors;

    }, [onlinePatientManagement.serviceStackClient,
        pascalToCameCasePropertyPath,
        createValidationRequest]);
};