/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the header component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Assets
 * ---------------------------------------------------------------------------------
 */

import { ALL_INSTITUTIONS, Breadcrumbs, IBreadcrumb } from '@ngt/opms';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugBreadcrumbsProps {
    institutionName?: string;
    institutionCode?: string;
    showInstitution?: boolean;
    depotCode?: string;
    depotName?: string;
    showDepot?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const DrugBreadcrumbs: React.FunctionComponent<IDrugBreadcrumbsProps> = ({
    institutionName,
    institutionCode,
    showInstitution,
    depotCode,
    depotName,
    showDepot
}) => {

    const breadcrumbs = React.useMemo(() => {
        const useInstitutionCode = institutionCode;

        const institution = institutionName ?
            institutionName :
            ALL_INSTITUTIONS;

        const institutionCompleteLink = `/drug-management/${useInstitutionCode}`;

        const institutionLink = useInstitutionCode ?
            institutionCompleteLink :
            '/drug-management';

        const depotLink = `/drug-management/depot/${depotCode}`;

        const crumbs: IBreadcrumb[] = [];

        if (!!showInstitution && !!institution) {
            crumbs.push({
                to: institutionLink,
                icon: faBuilding,
                label: institution
            });
        }

        if (!!showDepot) {
            crumbs.push({
                to: '/drug-management/depots',
                icon: faBuilding,
                label: 'All Depots'
            });
        }

        if (!!showDepot && depotCode && depotName) {
            crumbs.push({
                to: depotLink,
                icon: faBuilding,
                label: depotName
            });
        }

        return crumbs;
    }, [institutionName, institutionCode, depotCode, depotName, showInstitution, showDepot]);

    return (
        <Breadcrumbs
            breadcrumbs={breadcrumbs}
        />
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default DrugBreadcrumbs;