/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving and saving a drugShipmentDepotBatch.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { JsonServiceClient } from '@servicestack/client';

import { ResponseStatus } from '@ngt/opms';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../../api/dtos';



/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

interface IDrugShipmentDepotBatchState {
    drugShipmentDepotBatch: Dtos.DrugShipmentDepotBatch | null;
    loadState: IRequestState<ResponseStatus>;
    saveState: IRequestState<ResponseStatus>;
};

export interface IDrugShipmentDepotBatchStore {
    drugShipmentDepotBatch: IDrugShipmentDepotBatchState;
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

const initialDrugShipmentDepotBatchState: IDrugShipmentDepotBatchState = {
    drugShipmentDepotBatch: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

class DrugShipmentDepotBatchReducer extends ImmerReducer<IDrugShipmentDepotBatchState> {
    public loadById(id: number) {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(drugShipmentDepotBatch?: Dtos.DrugShipmentDepotBatch) {
        this.draftState.drugShipmentDepotBatch = drugShipmentDepotBatch ?? null;
        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus: ResponseStatus) {
        this.draftState.drugShipmentDepotBatch = null;
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public save(form: Dtos.DrugShipmentDepotBatch) {
        this.draftState.saveState = {
            state: RequestState.Pending
        };
    }

    public saveSuccess(drugShipmentDepotBatch?: Dtos.DrugShipmentDepotBatch) {
        this.draftState.drugShipmentDepotBatch = drugShipmentDepotBatch ?? null;
        this.draftState.saveState = {
            state: RequestState.Success
        };
    }

    public saveFailure(responseStatus: ResponseStatus) {
        this.draftState.drugShipmentDepotBatch = null;
        this.draftState.saveState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialDrugShipmentDepotBatchState };
    }
};

export const drugShipmentDepotBatchActions = createActionCreators(DrugShipmentDepotBatchReducer);
export const drugShipmentDepotBatchReducer = createReducerFunction(DrugShipmentDepotBatchReducer, initialDrugShipmentDepotBatchState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

export const createDrugShipmentDepotBatchApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.GetDrugShipmentDepotBatchById({
            id
        }));
    },
    save: (drugShipmentDepotBatch?: Dtos.DrugShipmentDepotBatch) => {
        return client.post(new Dtos.SetDrugShipmentDepotBatch({
            drugShipmentDepotBatch
        }));
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createDrugShipmentDepotBatchLogic = (api: ReturnType<typeof createDrugShipmentDepotBatchApi>) => {
    const logic = {
        loadById: createLogic<IDrugShipmentDepotBatchState, {}, undefined, string, ReturnType<typeof drugShipmentDepotBatchActions.loadById>>({
            type: drugShipmentDepotBatchActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload;

                try {
                    const response = await api.loadById(id);

                    dispatch(drugShipmentDepotBatchActions.loadSuccess(
                        response.drugShipmentDepotBatch
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentDepotBatchActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        save: createLogic<IDrugShipmentDepotBatchState, {}, undefined, string, ReturnType<typeof drugShipmentDepotBatchActions.save>>({
            type: drugShipmentDepotBatchActions.save.type,
            process: async ({ action }, dispatch, done) => {
                const drugShipmentDepotBatch = action.payload;

                try {
                    const response = await api.save(drugShipmentDepotBatch);

                    dispatch(drugShipmentDepotBatchActions.saveSuccess(
                        response.drugShipmentDepotBatch
                    ));
                }
                catch (error: any) {
                    dispatch(drugShipmentDepotBatchActions.saveFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadById,
        logic.save
    ];
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useDrugShipmentDepotBatchSelector: TypedUseSelectorHook<IDrugShipmentDepotBatchStore> = useSelector;

export const drugShipmentDepotBatchSelectors = {
    selectDrugShipmentDepotBatch: (state: IDrugShipmentDepotBatchStore) => state.drugShipmentDepotBatch.drugShipmentDepotBatch,
    selectLoadState: (state: IDrugShipmentDepotBatchStore) => state.drugShipmentDepotBatch.loadState,
    selectSaveState: (state: IDrugShipmentDepotBatchStore) => state.drugShipmentDepotBatch.saveState
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerDrugShipmentDepotBatchReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createDrugShipmentDepotBatchApi(client);

    const logic = createDrugShipmentDepotBatchLogic(api);

    reducerRegistry.register('drugShipmentDepotBatch', drugShipmentDepotBatchReducer as Reducer, logic as any);
};

export default registerDrugShipmentDepotBatchReducer;