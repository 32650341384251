/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the institution reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualInstitutionState {
    institution: Dtos.IInstitution | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IInstitutionState {
    byId: Record<number, IIndividualInstitutionState>;
    byCode: Record<string, IIndividualInstitutionState>;
}

export interface IInstitutionStore {
    institution: IInstitutionState;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all institution reducer state changes and actions. 
 */
export class InstitutionReducer extends ImmerReducer<IInstitutionState>
{
    public loadById(id: number) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualInstitutionState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number, institution?: Dtos.IInstitution) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualInstitutionState };
        }

        this.draftState.byId[id].institution = institution ? institution : null;

        this.draftState.byId[id].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualInstitutionState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public loadByCode(code: string) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualInstitutionState };
        }

        this.draftState.byCode[code].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByCodeSuccess(code: string, institution?: Dtos.IInstitution) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualInstitutionState };
        }

        this.draftState.byCode[code].institution = institution ? institution : null;

        this.draftState.byCode[code].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodeFailure(code: string, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualInstitutionState };
        }

        this.draftState.byCode[code].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number) {
        if (this.draftState.byId[id]) {
            delete this.draftState.byId[id];
        }
    }

    public clearByCode(code: string) {
        if (this.draftState.byCode[code]) {
            delete this.draftState.byCode[code];
        }
    }

    public clearAll() {
        this.draftState = { ...initialInstitutionState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualInstitutionState: IIndividualInstitutionState = {
    institution: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialInstitutionState: IInstitutionState = {
    byCode: {},
    byId: {}
}

export const institutionActions = createActionCreators(InstitutionReducer);
export const institutionReducer = createReducerFunction(InstitutionReducer, initialInstitutionState);

const createInstitutionApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.InstitutionGetSingleById({ id }));
    },
    loadByCode: (code: string) => {
        return client.get(new Dtos.InstitutionGetSingleByCode({ code }));
    }
});

export const createInstitutionLogic = (api: ReturnType<typeof createInstitutionApi>) => {
    const logics = {
        loadById: createLogic<IInstitutionStore, {}, undefined, string, ReturnType<typeof institutionActions.loadById>>({
            type: institutionActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadById(action.payload);

                    dispatch(institutionActions.loadByIdSuccess(
                        action.payload,
                        response.institution
                    ));
                }
                catch (error: any) {
                    dispatch(institutionActions.loadByIdFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCode: createLogic<any, {}, undefined, string, ReturnType<typeof institutionActions.loadByCode>>({
            type: institutionActions.loadByCode.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadByCode(action.payload);
                    dispatch(institutionActions.loadByCodeSuccess(
                        action.payload,
                        response.institution
                    ));
                }
                catch (error: any) {
                    dispatch(institutionActions.loadByCodeFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadByCode,
        logics.loadById
    ]
}

export const useInstitutionSelector: TypedUseSelectorHook<IInstitutionStore> = useSelector;

export const institutionSelectors = {
    institutionById: (state: IInstitutionStore, id: number) => state.institution.byId[id]?.institution ?? null,
    loadStateById: (state: IInstitutionStore, id: number) => state.institution.byId[id]?.loadState ?? initialIndividualInstitutionState.loadState,
    institutionByCode: (state: IInstitutionStore, code: string) => state.institution.byCode[code]?.institution ?? null,
    loadStateByCode: (state: IInstitutionStore, code: string) => state.institution.byCode[code]?.loadState ?? initialIndividualInstitutionState.loadState,
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerInstitutionReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createInstitutionApi(client);

    const logic = createInstitutionLogic(api);

    reducerRegistry.register('institution', institutionReducer as Reducer, logic as any);
};

export default registerInstitutionReducer;