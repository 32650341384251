/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use a batch by the id
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    ResponseStatus,
    useAsyncFunction,
    BoundActionCreator,
    TypedFunction,
    bindActionCreatorsWithType
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

import {
    batchActions,
    useBatchSelector,
    batchSelectors,
    IBatchStore
} from '../store/modules/batch';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseBatchActions {
    load: BoundActionCreator<typeof batchActions.loadById>;
    clear: BoundActionCreator<typeof batchActions.clear>;
    save: BoundActionCreator<typeof batchActions.save>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<typeof batchActions.save>>, Promise<Dtos.Batch | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useBatch = (): [
    Dtos.Batch | null,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IUseBatchActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncSave = useAsyncFunction(batchActions.save, batchActions.saveSuccess, batchActions.saveFailure);

    const actions: IUseBatchActions = React.useMemo(() => {
        const load = (id: number) => batchActions.loadById(id);
        load.type = batchActions.loadById.type;

        const clear = () => batchActions.clear();
        clear.type = batchActions.clear.type;

        const save = (batch: Dtos.Batch) => batchActions.save(batch);
        save.type = batchActions.save.type;

        const asyncSave = async (batch?: Dtos.Batch) => {
            const savedBatch = await unboundAsyncSave(batch);

            return savedBatch;
        };

        return {
            ...bindActionCreatorsWithType({
                load,
                clear,
                save,
            }, dispatch),
            asyncSave
        };
    }, [batchActions, dispatch, unboundAsyncSave]);

    const batchSelector = React.useCallback((state: IBatchStore) => {
        return batchSelectors.selectBatch(state)
    }, [batchSelectors.selectBatch]);

    const loadStateSelector = React.useCallback((state: IBatchStore) => {
        return batchSelectors.selectLoadState(state)
    }, [batchSelectors.selectLoadState]);

    const saveStateSelector = React.useCallback((state: IBatchStore) => {
        return batchSelectors.selectSaveState(state)
    }, [batchSelectors.selectSaveState]);

    const batch = useBatchSelector(batchSelector);

    const loadState = useBatchSelector(loadStateSelector);

    const saveState = useBatchSelector(saveStateSelector);

    return [
        batch,
        loadState,
        saveState,
        actions
    ];
}