/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the administer pharmacy page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useParams } from 'react-router-dom';

import { Theme, makeStyles } from '@material-ui/core/styles';

/**
 * Used to format text.
 */
import Typography from '@material-ui/core/Typography';

import { RequestState } from '@ngt/request-utilities';

import { Button } from '@material-ui/core';

import { Column } from 'material-table';

import { CollapsibleTable, useInstitutionsByCodes } from '@ngt/opms'

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import * as Dtos from '../api/dtos';
import PharmacyModal from '../modals/PharmacyModal';
import DrugShipmentDepotBatchModal from '../modals/DrugShipmentDepotBatchModal';
import { usePharmacy } from '../hooks/usePharmacy';
import { usePharmacies } from '../hooks/usePharmacies';
import { useDepots } from '../hooks/useDepots';
import { useDrugs } from '../hooks/useDrugs';
import PharmacyInformation from '../components/PharmacyInformation';
import { useDrugShipmentDepotBatches } from '../hooks/useDrugShipmentDepotBatches';
import { useDrugShipmentDepotBatch } from '../hooks/useDrugShipmentDepotBatch';
import DrugBreadcrumbs from '../components/DrugBreadcrumbs';
import { usePharmacists } from '../hooks/usePharmacists';
import { getDrugRemaining, sumDrugShipmentDepotBatches, IDrugShipmentDepotBatchSummary, createDrugShipmentDepotBatchSummary, getDrugQuarantined, getDrugDestroyed } from '../utilities/drugShipmentDepotBatchCalculators';
import QuarantinePharmacyStockModal from '../modals/QuarantinePharmacyStockModal';
import EditQuarantinedStockModal from '../modals/EditQuarantinedStockModal';
import { useLoading } from '../hooks/useLoading';
import DrugManagementContext from '../context/DrugManagementContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IAdministerPharmacyParams {
    pharmacyId?: string;
}

interface IAdministerPharmacyProps {
    canAdministerDrugManagement: boolean;
    canAdministerPharmacyStock: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(4)
    },
    buttonSet: {
        paddingBottom: theme.spacing(1),
        textAlign: 'right'
    },
    button: {
        marginLeft: theme.spacing(3),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const useAvailableDepotBatchColumns = (drugShipmentDepotBatches: Dtos.DrugShipmentDepotBatch[] | null,
    consignmentNumberLabel: string) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.DrugShipmentDepotBatch>> = [
            {
                title: consignmentNumberLabel,
                render: (d) => d.drugShipment.consignmentNo
            },
            {
                title: 'Depot Batch Number',
                render: (d) => d.depotBatch.batch.batchNo
            },
            {
                title: 'Total',
                field: 'drugUnits'
            },
            {
                title: 'Used',
                field: 'drugUnitsUsed'
            },
            {
                title: 'Damaged',
                field: 'drugUnitsDamaged'
            },
            {
                title: 'Destroyed',
                field: 'drugUnitsDestroyed'
            },
            {
                title: 'Lost',
                field: 'drugUnitsLost'
            },
            {
                title: 'Quarantined',
                field: 'drugUnitsQuarantined'
            },
            {
                title: 'Transferred to another site',
                field: 'drugUnitsTransferred'
            },
            {
                title: 'Available',
                render: (dsb) => getDrugRemaining(dsb)
            },
        ];

        return cols;
    }, [drugShipmentDepotBatches]);

    return columns as any;
};

const useSummedAvailableDepotBatchColumns = (drugShipmentDepotBatches: IDrugShipmentDepotBatchSummary[] | null) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<IDrugShipmentDepotBatchSummary>> = [
            {
                title: 'Batch Number',
                field: 'batchNo'
            },
            {
                title: 'Total',
                field: 'drugUnits'
            },
            {
                title: 'Used',
                field: 'drugUnitsUsed'
            },
            {
                title: 'Damaged',
                field: 'drugUnitsDamaged'
            },
            {
                title: 'Destroyed',
                field: 'drugUnitsDestroyed'
            },
            {
                title: 'Lost',
                field: 'drugUnitsLost'
            },
            {
                title: 'Quarantined',
                field: 'drugUnitsQuarantined'
            },
            {
                title: 'Transferred to another site',
                field: 'drugUnitsTransferred'
            },
            {
                title: 'Available',
                field: 'drugRemaining'
            },
        ];

        return cols;
    }, [drugShipmentDepotBatches]);

    return columns as any;
};

const useQuarantinedAtSiteDepotBatchColumns = (drugShipmentDepotBatches: Dtos.DrugShipmentDepotBatch[] | null,
    consignmentNumberLabel: string) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.DrugShipmentDepotBatch>> = [
            {
                title: consignmentNumberLabel,
                render: (d) => d.drugShipment.consignmentNo
            },
            {
                title: 'Depot Batch Number',
                render: (d) => d.depotBatch.batch.batchNo
            },
            {
                title: 'Total',
                field: 'drugUnits'
            },
            {
                title: 'Used',
                field: 'drugUnitsUsed'
            },
            {
                title: 'Damaged',
                field: 'drugUnitsDamaged'
            },
            {
                title: 'Destroyed',
                field: 'drugUnitsDestroyed'
            },
            {
                title: 'Lost',
                field: 'drugUnitsLost'
            },
            {
                title: 'Quarantined',
                field: 'drugUnitsQuarantined'
            },
            {
                title: 'Transferred to another site',
                field: 'drugUnitsTransferred'
            },
        ];

        return cols;
    }, [drugShipmentDepotBatches]);

    return columns as any;
};

const useDestroyedDepotBatchColumns = (drugShipmentDepotBatches: Dtos.DrugShipmentDepotBatch[] | null,
    consignmentNumberLabel: string) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.DrugShipmentDepotBatch>> = [
            {
                title: consignmentNumberLabel,
                render: (d) => d.drugShipment.consignmentNo
            },
            {
                title: 'Depot Batch Number',
                render: (d) => d.depotBatch.batch.batchNo
            },
            {
                title: 'Total',
                field: 'drugUnits'
            },
            {
                title: 'Used',
                field: 'drugUnitsUsed'
            },
            {
                title: 'Damaged',
                field: 'drugUnitsDamaged'
            },
            {
                title: 'Destroyed',
                field: 'drugUnitsDestroyed'
            },
            {
                title: 'Lost',
                field: 'drugUnitsLost'
            },
            {
                title: 'Quarantined',
                field: 'drugUnitsQuarantined'
            },
            {
                title: 'Transferred to another site',
                field: 'drugUnitsTransferred'
            },
        ];

        return cols;
    }, [drugShipmentDepotBatches]);

    return columns as any;
};

/*
 * ---------------------------------------------------------------------------------
 * Hooks
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const AdministerPharmacy: React.FunctionComponent<IAdministerPharmacyProps> = ({
    canAdministerDrugManagement,
    canAdministerPharmacyStock
}) => {
    const classes = useStyles();
    const drugManagementContext = React.useContext(DrugManagementContext);
    const params = useParams<IAdministerPharmacyParams>();

    const [institutions, institutionsLoadState, institutionsActions] = useInstitutionsByCodes(null, null, null, true);

    const [pharmacy, , , , , pharmacyActions] = usePharmacy();

    const [, , pharmaciesActions] = usePharmacies();

    const [depots, depotsLoadState, depotsActions] = useDepots();

    const [drugShipmentDepotBatches, drugShipmentDepotBatchesLoadState, drugShipmentDepotBatchesActions] = useDrugShipmentDepotBatches();

    const [drugShipmentDepotBatch, drugShipmentDepotBatchLoadState,, drugShipmentDepotBatchActions] = useDrugShipmentDepotBatch();

    const [pharmacists, pharmacistsLoadState, pharmacistsActions] = usePharmacists();

    const [pharmacyForm, setPharmacyForm] = React.useState<Dtos.Pharmacy | null>(null);

    const [pharmacyModalOpen, setPharmacyModalOpen] = React.useState(false);

    const [quarantinePharmacyStockModalOpen, setQuarantinePharmacyStockModalOpen] = React.useState(false);

    const [actionQuarantinedStockModalOpen, setActionQuarantinedStockModalOpen] = React.useState(false);

    const [drugShipmentDepotBatchModalOpen, setDrugShipmentDepotBatchModalOpen] = React.useState(false);

    const [drugShipmentDepotBatchForm, setDrugShipmentDepotBatchForm] = React.useState<Dtos.DrugShipmentDepotBatch | null>(null);

    const [drugs, drugLoadState, drugActions] = useDrugs();

    const availableDepotBatches = React.useMemo(() => {
        return drugShipmentDepotBatches ?
            drugShipmentDepotBatches.filter(dsdb => dsdb.drugShipment.shipmentStatus === Dtos.ShipmentStatus.Available) :
            null;
    }, [drugShipmentDepotBatches]);

    const withAvailableDepotBatches = React.useMemo(() => {
        return availableDepotBatches ?
            availableDepotBatches.filter(dsdb => getDrugRemaining(dsdb) !== 0) :
            null;
    }, [availableDepotBatches]);

    const quarantinedAtSiteDepotBatches = React.useMemo(() => {
        return drugShipmentDepotBatches ?
            drugShipmentDepotBatches.filter(dsdb => dsdb.drugUnitsQuarantined && dsdb.drugUnitsQuarantined > 0) : 
            null;
    }, [drugShipmentDepotBatches]);

    const destroyedDepotBatches = React.useMemo(() => {
        return drugShipmentDepotBatches ?
            drugShipmentDepotBatches.filter(dsdb => dsdb.drugUnitsDestroyed && dsdb.drugUnitsDestroyed > 0) :
            null;
    }, [drugShipmentDepotBatches]);

    const drugsAvailable = React.useMemo(() => {

        let drugs: Array<string> = Array.from(new Set(availableDepotBatches?.map(db => db.depotBatch.batch.drug.drugName)));

        let drugsAvailable = "";

        drugs.forEach(drug => {
            let totalAvailable = 0;

            availableDepotBatches?.filter(db => db.depotBatch.batch.drug.drugName == drug)?.forEach(db => totalAvailable += getDrugRemaining(db));

            drugsAvailable += `${drug} - ${totalAvailable}\n`
        })

        return drugsAvailable;
    }, [availableDepotBatches]);

    const batchSummary = React.useMemo(() => {
        if (availableDepotBatches &&
            availableDepotBatches.length > 0) {
            const groupedBatches: Map<number, IDrugShipmentDepotBatchSummary> = new Map();

            availableDepotBatches.forEach((dsdb) => {
                if (groupedBatches.has(dsdb.depotBatchId!!)) {
                    let existingShipmentBatch = groupedBatches.get(dsdb.depotBatchId!!);

                    if (existingShipmentBatch) {
                        existingShipmentBatch = sumDrugShipmentDepotBatches(existingShipmentBatch,
                            createDrugShipmentDepotBatchSummary(dsdb));

                        groupedBatches.set(dsdb.depotBatchId!!, existingShipmentBatch);
                    }
                }
                else {
                    groupedBatches.set(dsdb.depotBatchId!!, createDrugShipmentDepotBatchSummary(dsdb));
                }
            });

            return Array.from(groupedBatches.values());
        }
        else {
            return null;
        }
    }, [availableDepotBatches]);

    const availableDepotBatchColumns = useAvailableDepotBatchColumns(availableDepotBatches, drugManagementContext.consignmentNumberLabel);
    const summedAvailableDepotBatchColumns = useSummedAvailableDepotBatchColumns(batchSummary);
    const quarantinedAtSiteDepotBatchColumns = useQuarantinedAtSiteDepotBatchColumns(quarantinedAtSiteDepotBatches, drugManagementContext.consignmentNumberLabel);
    const destroyedDepotBatchColumns = useDestroyedDepotBatchColumns(destroyedDepotBatches, drugManagementContext.consignmentNumberLabel);

    const drugShipmentDepotBatchesLoading = useLoading(drugShipmentDepotBatches,
        drugShipmentDepotBatchesLoadState);

    React.useEffect(() => {
        if (params.pharmacyId) {
            pharmacyActions.loadById(parseInt(params.pharmacyId, 10));
        }

        return () => {
            pharmacyActions.clear();
        };
    }, []);

    React.useEffect(() => {
        institutionsActions.load();

        return () => {
            institutionsActions.clear();
        };
    }, []);

    React.useEffect(() => {
        drugActions.load();

        return () => {
            drugActions.clear();
        };
    }, []);

    React.useEffect(() => {
        depotsActions.load();

        return () => {
            depotsActions.clear();
        };
    }, []);

    React.useEffect(() => {
        pharmaciesActions.load();

        return () => {
            pharmaciesActions.clear();
        }
    }, []);

    React.useEffect(() => {
        if (params.pharmacyId) {
            drugShipmentDepotBatchesActions.loadByPharmacyId(parseInt(params.pharmacyId, 10));
        }

        return () => {
            drugShipmentDepotBatchesActions.clear();
        }
    }, [params.pharmacyId]);

    React.useEffect(() => {
        if (pharmacy &&
            pharmacy.institutionCode) {
            pharmacistsActions.loadByInstCode(pharmacy.institutionCode);
        }

        return () => {
            pharmacistsActions.clear();
        }
    }, [pharmacy]);

    React.useEffect(() => {
        if (drugShipmentDepotBatchLoadState.state === RequestState.Success) {
            setDrugShipmentDepotBatchForm(drugShipmentDepotBatch);

            setDrugShipmentDepotBatchModalOpen(true);
        }
    }, [drugShipmentDepotBatchLoadState.state]);

    const onPharmacyFormSave = React.useCallback(() => {
        setPharmacyModalOpen(false);

        if (params.pharmacyId) {
            pharmacyActions.loadById(parseInt(params.pharmacyId, 10));
        }
    }, []);

    const onDrugShipmentDepotBatchFormSave = React.useCallback(() => {
        drugShipmentDepotBatchActions.clear();
        setDrugShipmentDepotBatchModalOpen(false);

        if (params.pharmacyId) {
            drugShipmentDepotBatchesActions.loadByPharmacyId(parseInt(params.pharmacyId, 10));
        }
    }, []);

    const onQuarantinePharmacyStockSave = React.useCallback(() => {
        setQuarantinePharmacyStockModalOpen(false);

        pharmacyActions.clear();

        if (params.pharmacyId) {
            pharmacyActions.loadById(parseInt(params.pharmacyId, 10));
            drugShipmentDepotBatchesActions.loadByPharmacyId(parseInt(params.pharmacyId, 10));
        }
    }, []);

    const onActionQuarantinedStockSave = React.useCallback(() => {
        setActionQuarantinedStockModalOpen(false);

        pharmacyActions.clear();

        if (params.pharmacyId) {
            pharmacyActions.loadById(parseInt(params.pharmacyId, 10));
            drugShipmentDepotBatchesActions.loadByPharmacyId(parseInt(params.pharmacyId, 10));
        }
    }, []);

    const editPharmacyClick = React.useCallback(() => {
        setPharmacyForm(pharmacy);

        setPharmacyModalOpen(true);
    }, [pharmacy, setPharmacyModalOpen]);

    const quarantineStockClick = React.useCallback(() => {
        setQuarantinePharmacyStockModalOpen(true);
    }, [setQuarantinePharmacyStockModalOpen]);

    const actionQuarantinedStockClick = React.useCallback(() => {
        setActionQuarantinedStockModalOpen(true);
    }, [setActionQuarantinedStockModalOpen]);

    const onDrugShipmentDepotRowClick = React.useCallback((event?: React.MouseEvent,
        rowData?: Dtos.DrugShipmentDepotBatch) => {
        if (!!canAdministerDrugManagement &&
            rowData?.id) {
            drugShipmentDepotBatchActions.clear();
            drugShipmentDepotBatchActions.load(rowData.id);
        }
    }, [canAdministerDrugManagement]);

    //this is here for general cleanup in case something is loaded into redux and never reset
    React.useEffect(() => {
        return () => {
            drugShipmentDepotBatchActions.clear();
            pharmacyActions.clear();
        }
    }, []);

    return (
        <>
            <PharmacyModal
                pharmacists={pharmacists}
                pharmacistsLoadState={pharmacistsLoadState}
                depots={depots}
                depotsLoadState={depotsLoadState}
                drugs={drugs}
                drugLoadState={drugLoadState}
                institutionLoadState={institutionsLoadState}
                institutions={institutions}
                pharmacy={pharmacyForm}
                modalOpen={pharmacyModalOpen}
                setModalOpen={setPharmacyModalOpen}
                onFormSave={onPharmacyFormSave}
                pharmacyActions={pharmacyActions}
            />
            <DrugShipmentDepotBatchModal
                drugShipmentDepotBatch={drugShipmentDepotBatchForm}
                modalOpen={drugShipmentDepotBatchModalOpen}
                setModalOpen={setDrugShipmentDepotBatchModalOpen}
                onFormSave={onDrugShipmentDepotBatchFormSave}
                drugShipmentDepotBatchActions={drugShipmentDepotBatchActions}
            />
            {
                params.pharmacyId && (
                    <>
                        <QuarantinePharmacyStockModal
                            pharmacyId={parseInt(params.pharmacyId, 10)}
                            modalOpen={quarantinePharmacyStockModalOpen}
                            setModalOpen={setQuarantinePharmacyStockModalOpen}
                            onFormSave={onQuarantinePharmacyStockSave}
                            pharmacyActions={pharmacyActions}
                            availableDepotBatches={withAvailableDepotBatches}
                        />
                        <EditQuarantinedStockModal
                            pharmacyId={parseInt(params.pharmacyId, 10)}
                            modalOpen={actionQuarantinedStockModalOpen}
                            setModalOpen={setActionQuarantinedStockModalOpen}
                            onFormSave={onActionQuarantinedStockSave}
                            pharmacyActions={pharmacyActions}
                            quarantinedDepotBatches={quarantinedAtSiteDepotBatches ?? []}
                        />
                    </>
                )
            }
            <DrugBreadcrumbs
                institutionName={institutions?.find(i => i.code === pharmacy?.institutionCode)?.name}
                institutionCode={pharmacy?.institutionCode}
                showInstitution={true}
            />
            <div
                className={classes.container}
            >
                <PharmacyInformation
                    pharmacyName={pharmacy?.pharmacyName}
                    pharmacyStatus={pharmacy?.pharmacyStatus}
                    drugsAvailable={drugsAvailable}
                />
                <Typography
                    variant="h1"
                    color="secondary"
                    className={classes.heading}
                >
                    Administer Pharmacy
                </Typography>
                <div
                    className={classes.buttonSet}
                >
                    {
                        !!canAdministerPharmacyStock && (
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={quarantineStockClick}
                                disabled={!withAvailableDepotBatches || withAvailableDepotBatches.length === 0}
                            >
                                Quarantine Stock
                            </Button>
                        )
                    }
                    {
                        !!canAdministerPharmacyStock && (
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={actionQuarantinedStockClick}
                                disabled={!quarantinedAtSiteDepotBatches || quarantinedAtSiteDepotBatches.length === 0}
                            >
                                Edit Stock in Quarantine
                            </Button>
                        )
                    }
                    {
                        !!canAdministerDrugManagement && (
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={editPharmacyClick}
                            >
                                Edit Pharmacy
                            </Button>
                        )
                    }
                </div>
                {
                    batchSummary ?
                        <CollapsibleTable
                            key="available-batches-table"
                            title="Available Batch"
                            loading={drugShipmentDepotBatchesLoading}
                            data={batchSummary}
                            columns={summedAvailableDepotBatchColumns}
                            entityName="Batch"
                        /> :
                        null
                }
                {
                    quarantinedAtSiteDepotBatches ?
                        <CollapsibleTable
                            key="quarantined-batches-table"
                            title="Quarantined at Site Stock"
                            loading={drugShipmentDepotBatchesLoading}
                            data={quarantinedAtSiteDepotBatches}
                            columns={quarantinedAtSiteDepotBatchColumns}
                            entityName="Batch"
                        /> :
                        null
                }
                {
                    availableDepotBatches ?
                        <CollapsibleTable
                            key="received-shipments-table"
                            title="Received Shipment"
                            loading={drugShipmentDepotBatchesLoading}
                            data={availableDepotBatches}
                            columns={availableDepotBatchColumns}
                            onRowClick={onDrugShipmentDepotRowClick}
                            entityName="Batch"
                        /> :
                        null
                }
                {
                    destroyedDepotBatches ?
                        <CollapsibleTable
                            key="destroyed-batches-table"
                            title="Destroyed Batch"
                            loading={drugShipmentDepotBatchesLoading}
                            data={destroyedDepotBatches}
                            columns={destroyedDepotBatchColumns}
                            entityName="Batch"
                        /> :
                        null
                }
            </div>
        </>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default AdministerPharmacy;