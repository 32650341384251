/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an saes by institution codes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    OmitFirstTwoArgs,
    ResponseStatus,
    IPatient 
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { ScreeningSummariesReducer, screeningSummariesActions, useScreeningSummariesSelector, screeningSummariesSelectors, IScreeningSummariesStore } from '../store/screeningSummaries';
import { ScreeningSummary } from '../api/screeningSummary';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseScreeningSummariesActions {
    load: BoundActionCreator<OmitFirstTwoArgs<typeof screeningSummariesActions.load>>;
    clear: BoundActionCreator<OmitFirstTwoArgs<typeof screeningSummariesActions.clear>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useScreeningSummaries = <TScreeningSummary extends ScreeningSummary = ScreeningSummary>(institutionId?: number, year?: number, autoLoad?: boolean): [
    Array<TScreeningSummary> | null,
    IRequestState<ResponseStatus>,
    IUseScreeningSummariesActions
] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {
        const load = () => screeningSummariesActions.load(institutionId, year);
        load.type = screeningSummariesActions.load.type;

        const clear = () => screeningSummariesActions.clear(institutionId!!, year);
        clear.type = screeningSummariesActions.clear.type;

        return bindActionCreators({
            load,
            clear
        }, dispatch);
    }, [screeningSummariesActions, institutionId, year, dispatch]);

    const screeningSummarySelector = React.useCallback((state: IScreeningSummariesStore) => {
        return screeningSummariesSelectors.screeningSummaries(state, institutionId)
    }, [screeningSummariesSelectors.screeningSummaries, institutionId]);

    const loadStateSelector = React.useCallback((state: IScreeningSummariesStore) => {
        return screeningSummariesSelectors.loadState(state, institutionId)
    }, [screeningSummariesSelectors.loadState, institutionId]);

    const screeningSummary = useScreeningSummariesSelector(screeningSummarySelector);

    const loadState = useScreeningSummariesSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();
            
            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, actions]);

    return [
        screeningSummary as Array<TScreeningSummary> | null,
        loadState,
        actions
    ];
};

