
/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

import React from "react";

export interface ISaeReviewContext {
    hasMedicalReview: boolean;
    investigatorRoles: Array<number>;
    medicalReviewerRoles: Array<number>;
    studyChairRoles: Array<number>;
    studySponsor?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const SaeReviewContext = React.createContext<ISaeReviewContext>({
    hasMedicalReview: true,
    investigatorRoles: [],
    medicalReviewerRoles: [],
    studyChairRoles: [],
    studySponsor: undefined
})

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeReviewContext;