/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */

import * as React from 'react';
import { Form, IFormLabel, IFormContext, IValidationError, IFormState, SubmitButton, RouteLoading } from '@ngt/opms';
import { Button, DialogActions, makeStyles, Theme } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ScreeningSummary } from '../api/screeningSummary';
import ScreeningSummaryContext from '../context/ScreeningSummaryContext';
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IScreeningSummaryFormProps<TScreeningSummary extends ScreeningSummary = ScreeningSummary> {
    formFieldGroup?: any;
    formDisable?: boolean;
    formLabels?: IFormLabel[];
    handleSubmit?: ({ values, errors }: IFormState<TScreeningSummary, IValidationError>) => Promise<void>;
    onFormSubmit?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TScreeningSummary, IValidationError>) => void; 
    onFormCancel?: ()  => void;
    validate?: any;
    onSubmitFailed?: any;
    onSubmitValidationFailure?: any;
}

const useStyles = makeStyles<Theme>(theme => ({

}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ScreeningSummaryForm = ({
    formFieldGroup,
    formDisable,
    formLabels,
    onFormSubmit,
    handleSubmit,
    onFormCancel,
    onSubmitFailed,
    onSubmitValidationFailure,
    validate
}: IScreeningSummaryFormProps) => {

    const classes = useStyles();

    const screeningSummaryContext = React.useContext(ScreeningSummaryContext);

    const notLoading = screeningSummaryContext.loadState.state !== RequestState.Pending;

    return (
        <>
            {
                notLoading ? <Form
                    initialValues={screeningSummaryContext?.screeningSummary}
                    onValidate={validate}
                    validateOn="onChange"
                    allowSubmit={undefined}
                    fieldErrors={'default'}
                    onSubmit={handleSubmit}
                    labels={formLabels}
                    lookups={undefined}
                    readOnly={formDisable ?? false}
                    onSubmitFailed={onSubmitFailed}
                    onSubmitValidationFailed={onSubmitValidationFailure}
                >
                    {formFieldGroup}

                    {
                        <DialogActions>
                            <Button onClick={onFormCancel} color="secondary" disabled={false}>
                                Cancel
                            </Button>
                            <SubmitButton onClick={onFormSubmit} color="primary" disabled={false}>
                                Save
                            </SubmitButton> 
                        </DialogActions>
                    }
                </Form>
                : <RouteLoading />
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningSummaryForm;

