/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use a drugShipmentDepotBatch by the id
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    ResponseStatus,
    useAsyncFunction,
    BoundActionCreator,
    TypedFunction,
    bindActionCreatorsWithType
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

import {
    drugShipmentDepotBatchActions,
    useDrugShipmentDepotBatchSelector,
    drugShipmentDepotBatchSelectors,
    IDrugShipmentDepotBatchStore
} from '../store/modules/drugshipmentdepotbatch';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseDrugShipmentDepotBatchActions {
    load: BoundActionCreator<typeof drugShipmentDepotBatchActions.loadById>;
    clear: BoundActionCreator<typeof drugShipmentDepotBatchActions.clear>;
    save: BoundActionCreator<typeof drugShipmentDepotBatchActions.save>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<typeof drugShipmentDepotBatchActions.save>>, Promise<Dtos.DrugShipmentDepotBatch | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useDrugShipmentDepotBatch = (): [
    Dtos.DrugShipmentDepotBatch | null,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IUseDrugShipmentDepotBatchActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncSave = useAsyncFunction(drugShipmentDepotBatchActions.save, drugShipmentDepotBatchActions.saveSuccess, drugShipmentDepotBatchActions.saveFailure);

    const actions: IUseDrugShipmentDepotBatchActions = React.useMemo(() => {
        const load = (id: number) => drugShipmentDepotBatchActions.loadById(id);
        load.type = drugShipmentDepotBatchActions.loadById.type;

        const clear = () => drugShipmentDepotBatchActions.clear();
        clear.type = drugShipmentDepotBatchActions.clear.type;

        const save = (drugShipmentDepotBatch: Dtos.DrugShipmentDepotBatch) => drugShipmentDepotBatchActions.save(drugShipmentDepotBatch);
        save.type = drugShipmentDepotBatchActions.save.type;

        const asyncSave = async (drugShipmentDepotBatch?: Dtos.DrugShipmentDepotBatch) => {
            const savedBatch = await unboundAsyncSave(drugShipmentDepotBatch);

            return savedBatch;
        };

        return {
            ...bindActionCreatorsWithType({
                load,
                clear,
                save,
            }, dispatch),
            asyncSave
        };
    }, [drugShipmentDepotBatchActions, dispatch, unboundAsyncSave]);

    const drugShipmentDepotBatchSelector = React.useCallback((state: IDrugShipmentDepotBatchStore) => {
        return drugShipmentDepotBatchSelectors.selectDrugShipmentDepotBatch(state)
    }, [drugShipmentDepotBatchSelectors.selectDrugShipmentDepotBatch]);

    const loadStateSelector = React.useCallback((state: IDrugShipmentDepotBatchStore) => {
        return drugShipmentDepotBatchSelectors.selectLoadState(state)
    }, [drugShipmentDepotBatchSelectors.selectLoadState]);

    const saveStateSelector = React.useCallback((state: IDrugShipmentDepotBatchStore) => {
        return drugShipmentDepotBatchSelectors.selectSaveState(state)
    }, [drugShipmentDepotBatchSelectors.selectSaveState]);

    const drugShipmentDepotBatch = useDrugShipmentDepotBatchSelector(drugShipmentDepotBatchSelector);

    const loadState = useDrugShipmentDepotBatchSelector(loadStateSelector);

    const saveState = useDrugShipmentDepotBatchSelector(saveStateSelector);

    return [
        drugShipmentDepotBatch,
        loadState,
        saveState,
        actions
    ];
}