/* Options:
Date: 2022-10-07 13:00:33
Version: 6.02
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44367

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: NewtonGreen.ServiceStack.Opms.ScreeningLog/*,__ScreeningLog.ScreeningLog/*
//ExcludeTypes: 
DefaultImports: IGet:@ngt/opms,IPost:@ngt/opms,IDelete:@ngt/opms,IDataModel:@ngt/opms,IModel:@ngt/opms,IHasAudit:@ngt/opms,ScreeningLog:./screeningLog,IPatient:@ngt/opms,ResponseError:@ngt/opms,ResponseStatus:@ngt/opms,IReturn:@ngt/opms,IValidationError:@ngt/opms,IValidationResult:@ngt/opms,ValidationResultType:@ngt/opms,ValidationErrorType:@ngt/opms
*/

import { IGet } from "@ngt/opms";
import { IPost } from "@ngt/opms";
import { IDelete } from "@ngt/opms";
import { IDataModel } from "@ngt/opms";
import { IModel } from "@ngt/opms";
import { IHasAudit } from "@ngt/opms";
import { ScreeningLog } from "./screeningLog";
import { ScreeningSummary } from "./screeningSummary";
import { IPatient } from "@ngt/opms";
import { ResponseError } from "@ngt/opms";
import { ResponseStatus } from "@ngt/opms";
import { IReturn } from "@ngt/opms";
import { IValidationError } from "@ngt/opms";
import { IValidationResult } from "@ngt/opms";
import { ValidationResultType } from "@ngt/opms";
import { ValidationErrorType } from "@ngt/opms";


export interface IScreeningSummaryGetSingleById extends IGet
{
    id?: number;
}
export interface IScreeningSummaryGetCollection extends IGet
{
    institutionId?: number;
    year?: number;
}
export interface IScreeningSummaryGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
}

export interface IScreeningSummaryGetCollectionByYear extends IGet
{
    institutionId?: number;
    year?: number;
}

export interface IScreeningLogGetSingleById extends IGet
{
    id?: number;
}

export interface IScreeningLogGetCollection extends IGet
{
}

export interface IScreeningLogGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
}

export interface IScreeningLogDelete extends IDelete
{
    screeningLogId?: number;
}

export class ScreeningLogFormValidationError implements IScreeningLogFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ScreeningLogFormValidationError>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationError implements IScreeningSummaryFormValidationError, IValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ScreeningSummaryFormValidationError>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationResult
{
    public result: ValidationResultType;
    public errors: ScreeningLogFormValidationError[];

    public constructor(init?: Partial<ScreeningLogFormValidationResult>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationResult implements IScreeningSummaryFormValidationResult, IValidationResult {
    public result: ValidationResultType;
    public errors: ScreeningSummaryFormValidationError[];

    public constructor(init?: Partial<ScreeningSummaryFormValidationResult>) { (Object as any).assign(this, init); }
}

export enum ScreeningStatus
{
    Pending = 1,
    Successful = 2,
    Failed = 3,
}

export enum ScreeningOutcome
{
    ProceedToRegistration = 1,
    ScreenFail = 2,
}

export interface IScreeningLog extends IDataModel
{
    institutionId?: number;
    patientId?: number;
    screeningDate?: string;
    outcome?: ScreeningOutcome;
    status?: ScreeningStatus;
    initials: string;
    dateOfBirth?: string;
    reason?: number;
    reasonSpecify: string;
}

export interface IScreeningSummary extends IDataModel, IModel, IHasAudit
{
    institutionId?: number;
    institutionName?: string;
    summaryDate?: string;
}

export interface IScreeningLogFormValidationError extends IValidationError
{
    property: string;
}

export interface IScreeningSummaryFormValidationError extends IValidationError
{
    property: string;
}

export interface IScreeningLogFormValidationResult extends IValidationResult
{
    errors: IScreeningLogFormValidationError[];
}

export interface IScreeningSummaryFormValidationResult extends IValidationResult
{
    errors: IScreeningSummaryFormValidationError[];
}

// @DataContract
export class ScreeningSummaryResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeningSummary.
    */
    // @DataMember(Order=2)
    public screeningSummary: ScreeningSummary[];

    public constructor(init?: Partial<ScreeningSummaryResponse>) { (Object as any).assign(this, init); }
}

export class ScreeningSummarySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeningSummary.
    */
    // @DataMember(Order=2)

    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummarySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeninglog.
    */
    // @DataMember(Order=2)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeninglogs.
    */
    // @DataMember(Order=2)
    public screeningLogs: ScreeningLog[];

    public constructor(init?: Partial<ScreeningLogCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ScreeningLogDeleteResponse>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: ScreeningLogFormValidationResult;

    public constructor(init?: Partial<ScreeningLogFormValidationResponse>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: ScreeningSummaryFormValidationResult;

    public constructor(init?: Partial<ScreeningSummaryFormValidationResponse>) { (Object as any).assign(this, init); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeninglog/single/id/{Id}", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetSingleById implements IReturn<ScreeningSummarySingleResponse>, IScreeningSummaryGetSingleById, IGet
{
    /**
    * The ID of the screeninglog to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ScreeningSummaryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningSummarySingleResponse(); }
    public getTypeName() { return 'ScreeningSummaryGetSingleById'; }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/collection", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetCollection implements IReturn<ScreeningSummaryResponse>, IScreeningSummaryGetCollection, IGet
{

    public institutionId?: number;
    public year?: number;

    public constructor(init?: Partial<ScreeningSummaryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningSummaryResponse(); }
    public getTypeName() { return 'ScreeningSummaryGetCollection'; }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetCollectionByInstitutionId implements IReturn<ScreeningSummaryResponse>, IScreeningSummaryGetCollectionByInstitutionId, IGet
{
    /**
    * The ID of the Institution associated with the ScreeningSummary to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;

    public constructor(init?: Partial<ScreeningSummaryGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningSummaryResponse(); }
    public getTypeName() { return 'ScreeningSummaryGetCollectionByInstitutionId'; }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetCollectionByYear implements IReturn<ScreeningSummaryResponse>, IScreeningSummaryGetCollectionByYear, IGet
{
    /**
    * The ID of the Institution associated with the ScreeningSummary to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;
    public year?: number;

    public constructor(init?: Partial<ScreeningSummaryGetCollectionByYear>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningSummaryResponse(); }
    public getTypeName() { return 'ScreeningSummaryGetCollectionByYear'; }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/save", "POST")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningSummary.ScreeningSummary.ScreeningSummaryResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryPostSave implements IReturn<ScreeningSummarySingleResponse>, IPost
{
    /**
    * The ScreeningSummary to save.
    */
    // @DataMember(Order=1)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummaryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningSummarySingleResponse(); }
    public getTypeName() { return 'ScreeningSummaryPostSave'; }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/single/id/{Id}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetSingleById implements IReturn<ScreeningLogSingleResponse>, IScreeningLogGetSingleById
{
    /**
    * The ID of the screeninglog to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ScreeningLogGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogSingleResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/collection", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetCollection implements IReturn<ScreeningLogCollectionResponse>, IScreeningLogGetCollection
{

    public constructor(init?: Partial<ScreeningLogGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogCollectionResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetCollectionByInstitutionId implements IReturn<ScreeningLogCollectionResponse>, IScreeningLogGetCollectionByInstitutionId
{
    /**
    * The ID of the Institution associated with the screeninglog to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;

    public constructor(init?: Partial<ScreeningLogGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogCollectionResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/save", "POST")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogPostSave implements IReturn<ScreeningLogSingleResponse>
{
    /**
    * The screeninglog to save.
    */
    // @DataMember(Order=1)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningLogSingleResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/delete/{ScreeningLogId}", "DELETE")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogDelete implements IReturn<ScreeningLogDeleteResponse>, IScreeningLogDelete
{
    /**
    * The screeninglog ID to delete.
    */
    // @DataMember(Order=1)
    public screeningLogId: number;

    public constructor(init?: Partial<ScreeningLogDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ScreeningLogDeleteResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/validate", "POST")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogPostValidate implements IReturn<ScreeningLogFormValidationResponse>
{
    /**
    * The screeninglog to validate.
    */
    // @DataMember(Order=1)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningLogFormValidationResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/validate", "POST")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningSummary.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningSummary.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningSummary.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningSummary.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryPostValidate implements IReturn<ScreeningSummaryFormValidationResponse>, IPost
{
    /**
    * The screeninglog to validate.
    */
    // @DataMember(Order=1)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummaryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningSummaryFormValidationResponse(); }
    public getTypeName() { return 'ScreeningSummaryPostValidate'; }
}

