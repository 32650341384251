/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the countries reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IIndividualCountriesState {
    countries: Dtos.ICountry[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface ICountriesState {
    byIds: Record<string, IIndividualCountriesState>;
    byCodes: Record<string, IIndividualCountriesState>;
}

export interface ICountriesStore {
    countries: ICountriesState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (masterGroupId?: number | null, collaboratingGroupId?: number | null) => {
    return `${(masterGroupId ?? 'null')}-${(collaboratingGroupId ?? 'null')}`
}

const createCodesContext = (masterGroupCode?: string | null, collaboratingGroupCode?: string | null) => {
    return `${(masterGroupCode ?? 'null')}-${(collaboratingGroupCode ?? 'null')}`
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all countries reducer state changes and actions. 
 */
export class CountriesReducer extends ImmerReducer<ICountriesState>
{
    public loadByIds(masterGroupId?: number | null, collaboratingGroupId?: number | null) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualCountriesState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByIdsSuccess(masterGroupId?: number | null, collaboratingGroupId?: number | null, countries?: Dtos.ICountry[]) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualCountriesState };
        }

        this.draftState.byIds[context].countries = countries ? countries : null;

        this.draftState.byIds[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdsFailure(masterGroupId?: number | null, collaboratingGroupId?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualCountriesState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByIds(masterGroupId?: number | null, collaboratingGroupId?: number | null) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId);

        this.draftState.byIds[context] = { ...initialIndividualCountriesState }
    }

    public loadByCodes(masterGroupCode?: string | null, collaboratingGroupCode?: string | null) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualCountriesState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByCodesSuccess(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countries?: Dtos.ICountry[]) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualCountriesState };
        }

        this.draftState.byCodes[context].countries = countries ? countries : null;

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodesFailure(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualCountriesState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByCodes(masterGroupCode?: string | null, collaboratingGroupCode?: string | null) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode);

        this.draftState.byCodes[context] = { ...initialIndividualCountriesState }
    }

    public clearAll() {
        this.draftState = { ...initialCountriesState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialCountriesState: ICountriesState = {
    byIds: {},
    byCodes: {}
}

export const initialIndividualCountriesState: IIndividualCountriesState = {
    countries: null,
    loadState: {
        state: RequestState.None
    }
}

export const countriesActions = createActionCreators(CountriesReducer);
export const countriesReducer = createReducerFunction(CountriesReducer, initialCountriesState);

const createCountriesApi = (client: JsonServiceClient) => ({
    loadByIds: (masterGroupId?: number, collaboratingGroupId?: number) => {
        if (collaboratingGroupId) {
            return client.get(new Dtos.CountryGetCollectionByCollaboratingGroupId({ collaboratingGroupId }));
        }
        else if (masterGroupId) {
            return client.get(new Dtos.CountryGetCollectionByMasterGroupId({ masterGroupId }));
        }
        else {
            return client.get(new Dtos.CountryGetCollection());
        }
    },
    loadByCodes: (masterGroupCode?: string, collaboratingGroupCode?: string) => {
        if (collaboratingGroupCode) {
            return client.get(new Dtos.CountryGetCollectionByCollaboratingGroupCode({ collaboratingGroupCode }));
        }
        else if (masterGroupCode) {
            return client.get(new Dtos.CountryGetCollectionByMasterGroupCode({ masterGroupCode }));
        }
        else {
            return client.get(new Dtos.CountryGetCollection());
        }
    }
});

const createCountriesLogic = (api: ReturnType<typeof createCountriesApi>) => {
    const logic = {
        loadByIds: createLogic<ICountriesStore, {}, undefined, string, ReturnType<typeof countriesActions.loadByIds>>({
            type: countriesActions.loadByIds.type,
            process: async ({ action }, dispatch, done) => {
                const [masterGroupId, collaboratingGroupId] = action.payload;

                try {
                    const response = await api.loadByIds(masterGroupId ?? undefined, collaboratingGroupId ?? undefined ?? undefined);

                    dispatch(countriesActions.loadByIdsSuccess(
                        masterGroupId,
                        collaboratingGroupId,
                        response.countries
                    ));
                }
                catch (error: any) {
                    dispatch(countriesActions.loadByIdsFailure(masterGroupId, collaboratingGroupId, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCodes: createLogic<ICountriesStore, {}, undefined, string, ReturnType<typeof countriesActions.loadByCodes>>({
            type: countriesActions.loadByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const [masterGroupCode, collaboratingGroupCode] = action.payload;

                try {
                    const response = await api.loadByCodes(masterGroupCode ?? undefined, collaboratingGroupCode ?? undefined ?? undefined);

                    dispatch(countriesActions.loadByCodesSuccess(
                        masterGroupCode,
                        collaboratingGroupCode,
                        response.countries
                    ));
                }
                catch (error: any) {
                    dispatch(countriesActions.loadByCodesFailure(masterGroupCode, collaboratingGroupCode, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadByIds,
        logic.loadByCodes
    ]
};

export const useCountriesSelector: TypedUseSelectorHook<ICountriesStore> = useSelector;

export const countriesSelectors = {
    countriesByIds: (state: ICountriesStore, masterGroupId?: number | null, collaboratingGroupId?: number | null) => {
        const context = createIdsContext(masterGroupId, collaboratingGroupId);

        if (!state.countries.byIds[context]) {
            return initialIndividualCountriesState.countries;
        }

        return state.countries.byIds[context].countries;
    },
    loadStateByIds: (state: ICountriesStore, masterGroupId?: number | null, collaboratingGroupId?: number | null) => {
        const context = createIdsContext(masterGroupId, collaboratingGroupId);

        if (!state.countries.byIds[context]) {
            return initialIndividualCountriesState.loadState;
        }

        return state.countries.byIds[context].loadState;
    },
    countriesByCodes: (state: ICountriesStore, masterGroupCode?: string | null, collaboratingGroupCode?: string | null) => {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode);

        if (!state.countries.byCodes[context]) {
            return initialIndividualCountriesState.countries;
        }

        return state.countries.byCodes[context].countries;
    },
    loadStateByCodes: (state: ICountriesStore, masterGroupCode?: string | null, collaboratingGroupCode?: string | null) => {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode);

        if (!state.countries.byCodes[context]) {
            return initialIndividualCountriesState.loadState;
        }

        return state.countries.byCodes[context].loadState;
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerCountriesReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createCountriesApi(client);

    const logic = createCountriesLogic(api);

    reducerRegistry.register('countries', countriesReducer as Reducer, logic as any);
};

export default registerCountriesReducer;