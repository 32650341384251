/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the permissions reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IIndividualPermissionsState {
    hasPermission: boolean | null;
    loadState: IRequestState<ResponseStatus>;
}

export interface IPermissionsState {
    byIds: Record<string, Record<number, IIndividualPermissionsState>>;
    byCodes: Record<string, Record<number, IIndividualPermissionsState>>;
}

export interface IPermissionsStore {
    permissions: IPermissionsState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (masterGroupId?: number | null, collaboratingGroupId?: number | null, institutionId?: number | null, patientId?: number | null) => {
    return `${(masterGroupId ?? 'null')}-${(collaboratingGroupId ?? 'null')}-${(institutionId ?? 'null')}-${(patientId ?? 'null')}`
}

const createCodesContext = (masterGroupCode?: string | null, collaboratingGroupCode?: string | null, institutionCode?: string | null, patientStudyNumber?: string | null) => {
    return `${(masterGroupCode ?? 'null')}-${(collaboratingGroupCode ?? 'null')}-${(institutionCode ?? 'null')}-${(patientStudyNumber ?? 'null')}`
}

const parseIdContext = (context?: string) => {
    if (!context) {
        return {};
    }

    const parts = context.split('-');

    if (parts.length !== 4) {
        return {};
    }

    const masterGroupIdStr = parts[0] !== 'null' ? parts[0] : undefined;
    const collaboratingGroupIdStr = parts[1] !== 'null' ? parts[1] : undefined;
    const institutionIdStr = parts[2] !== 'null' ? parts[2] : undefined;
    const patientIdStr = parts[3] !== 'null' ? parts[3] : undefined;


    return {
        masterGroupId: toInt(masterGroupIdStr),
        collaboratingGroupId: toInt(collaboratingGroupIdStr),
        institutionId: toInt(institutionIdStr),
        patientId: toInt(patientIdStr)
    }
}

const toInt = (value?: string) => {
    if (!value) {
        return undefined;
    }

    try {
        return parseInt(value);
    }
    catch
    {
        return undefined;
    }
}

const parseCodeContext = (context?: string) => {
    if (!context) {
        return {};
    }

    const parts = context.split('-');

    if (parts.length !== 4) {
        return {};
    }

    const masterGroupCode = parts[0] !== 'null' ? parts[0] : undefined;
    const collaboratingGroupCode = parts[1] !== 'null' ? parts[1] : undefined;
    const institutionCode = parts[2] !== 'null' ? parts[2] : undefined;
    const patientStudyNumber = parts[3] !== 'null' ? parts[3] : undefined;

    return {
        masterGroupCode,
        collaboratingGroupCode,
        institutionCode,
        patientStudyNumber
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all permissions reducer state changes and actions. 
 */
export class PermissionsReducer extends ImmerReducer<IPermissionsState>
{
    public loadByIds(masterGroupId?: number | null, collaboratingGroupId?: number | null, institutionId?: number | null, patientId?: number | null, permissions?: number[]) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, institutionId, patientId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { };
        }

        permissions?.forEach(permission => {
            if (!this.draftState.byIds[context][permission]) {
                this.draftState.byIds[context][permission] = { ...initialIndividualPermissionState };
            }

            this.draftState.byIds[context][permission].loadState = {
                state: RequestState.Pending
            };
        });
    }

    public loadByIdsSuccess(masterGroupId?: number | null, collaboratingGroupId?: number | null, institutionId?: number | null, patientId?: number | null, hasPermissions?: Record<number, boolean>) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, institutionId, patientId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { };
        }

        if (hasPermissions) {
            const keys = Object.keys(hasPermissions);

            keys.forEach(key => {

                const intKey = parseInt(key, 10);

                if (!this.draftState.byIds[context][intKey]) {
                    this.draftState.byIds[context][intKey] = { ...initialIndividualPermissionState };
                }

                this.draftState.byIds[context][intKey].hasPermission = hasPermissions[intKey];

                this.draftState.byIds[context][intKey].loadState = {
                    state: RequestState.Success
                }
            });
        }
    }

    public loadByIdsFailure(masterGroupId?: number | null, collaboratingGroupId?: number | null, institutionId?: number | null, patientId?: number | null, responseStatus?: ResponseStatus, permissions?: number[]) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, institutionId, patientId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = {};
        }

        permissions?.forEach(permission => {
            if (!this.draftState.byIds[context][permission]) {
                this.draftState.byIds[context][permission] = { ...initialIndividualPermissionState };
            }

            this.draftState.byIds[context][permission].loadState = {
                state: RequestState.Failure,
                responseStatus: responseStatus
            };
        });
    }

    public clearByIds(masterGroupId?: number | null, collaboratingGroupId?: number | null, institutionId?: number | null, patientId?: number | null, permissions?: number[]) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, institutionId, patientId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = {};
        }

        permissions?.forEach(permission => {
            if (this.draftState.byIds[context][permission]) {
                delete this.draftState.byIds[context][permission];
            }
        });
    }

    public loadByCodes(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, institutionCode?: string | null, patientStudyNumber?: string | null, permissions?: number[]) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, institutionCode, patientStudyNumber);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = {};
        }

        permissions?.forEach(permission => {
            if (!this.draftState.byCodes[context][permission]) {
                this.draftState.byCodes[context][permission] = { ...initialIndividualPermissionState };
            }

            this.draftState.byCodes[context][permission].loadState = {
                state: RequestState.Pending
            };
        });
    }

    public loadByCodesSuccess(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, institutionCode?: string | null, patientStudyNumber?: string | null, hasPermissions?: Record<number, boolean>) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, institutionCode, patientStudyNumber);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = {};
        }

        if (hasPermissions) {
            const keys = Object.keys(hasPermissions);

            keys.forEach(key => {

                const intKey = parseInt(key, 10);

                if (!this.draftState.byCodes[context][intKey]) {
                    this.draftState.byCodes[context][intKey] = { ...initialIndividualPermissionState };
                }

                this.draftState.byCodes[context][intKey].hasPermission = hasPermissions[intKey];

                this.draftState.byCodes[context][intKey].loadState = {
                    state: RequestState.Success
                }
            });
        }
    }

    public loadByCodesFailure(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, institutionCode?: string | null, patientStudyNumber?: string | null, responseStatus?: ResponseStatus, permissions?: number[]) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, institutionCode, patientStudyNumber);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = {};
        }

        permissions?.forEach(permission => {
            if (!this.draftState.byCodes[context][permission]) {
                this.draftState.byCodes[context][permission] = { ...initialIndividualPermissionState };
            }

            this.draftState.byCodes[context][permission].loadState = {
                state: RequestState.Failure,
                responseStatus: responseStatus
            };
        });
    }

    public clearByCodes(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, institutionCode?: string | null, patientStudyNumber?: string | null, permissions?: number[]) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, institutionCode, patientStudyNumber);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = {};
        }

        permissions?.forEach(permission => {
            if (this.draftState.byCodes[context][permission]) {
                delete this.draftState.byCodes[context][permission];
            }
        });
    }

    public refresh() {

    }

    public clearAll() {
        this.draftState = { ...initialPermissionState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialPermissionState: IPermissionsState = {
    byIds: {},
    byCodes: {}
}

export const initialIndividualPermissionState: IIndividualPermissionsState = {
    hasPermission: null,
    loadState: {
        state: RequestState.None
    }
}

export const permissionsActions = createActionCreators(PermissionsReducer);
export const permissionsReducer = createReducerFunction(PermissionsReducer, initialPermissionState);

const createPermissionApi = (client: JsonServiceClient) => ({
    loadByIds: (masterGroupId?: number, collaboratingGroupId?: number, institutionId?: number, patientId?: number, permissions?: number[]) => {
        return client.post(new Dtos.PostHasOpmsPermissionsByIds({ masterGroupId, coordinatingGroupId: collaboratingGroupId, institutionId, patientId, permissions }));
    },
    loadByCodes: (masterGroupCode?: string, collaboratingGroupCode?: string, institutionCode?: string, patientStudyNumber?: string, permissions?: number[]) => {
        return client.post(new Dtos.PostHasOpmsPermissionsByCodes({ masterGroupCode, coordinatingGroupCode: collaboratingGroupCode, institutionCode, patientStudyNumber, permissions }));
    }
});

const createPermissionLogic = (api: ReturnType<typeof createPermissionApi>) => {
    const logic = {
        loadByIds: createLogic<IPermissionsStore, {}, undefined, string, ReturnType<typeof permissionsActions.loadByIds>>({
            type: permissionsActions.loadByIds.type,
            process: async ({ action }, dispatch, done) => {
                const [masterGroupId, collaboratingGroupId, institutionId, patientId, permissions ] = action.payload;

                try {
                    const response = await api.loadByIds(masterGroupId ?? undefined, collaboratingGroupId ?? undefined, institutionId ?? undefined, patientId ?? undefined, permissions);

                    dispatch(permissionsActions.loadByIdsSuccess(
                        masterGroupId,
                        collaboratingGroupId,
                        institutionId,
                        patientId,
                        response.hasPermission
                    ));
                }
                catch (error: any) {
                    dispatch(permissionsActions.loadByIdsFailure(masterGroupId, collaboratingGroupId, institutionId, patientId, error ? error.responseStatus : undefined, permissions));
                }

                done();
            }
        }),

        loadByCodes: createLogic<IPermissionsStore, {}, undefined, string, ReturnType<typeof permissionsActions.loadByCodes>>({
            type: permissionsActions.loadByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const [masterGroupCode, collaboratingGroupCode, institutionCode, patientStudyNumber, permissions] = action.payload;

                try {
                    const response = await api.loadByCodes(masterGroupCode ?? undefined, collaboratingGroupCode ?? undefined, institutionCode ?? undefined, patientStudyNumber ?? undefined, permissions);

                    dispatch(permissionsActions.loadByCodesSuccess(
                        masterGroupCode,
                        collaboratingGroupCode,
                        institutionCode,
                        patientStudyNumber,
                        response.hasPermission
                    ));
                }
                catch (error: any) {
                    dispatch(permissionsActions.loadByCodesFailure(masterGroupCode, collaboratingGroupCode, institutionCode, patientStudyNumber, error ? error.responseStatus : undefined, permissions));
                }

                done();
            }
        }),
        refresh: createLogic<IPermissionsStore, {}, undefined, string, ReturnType<typeof permissionsActions.refresh>>({
            type: permissionsActions.loadByCodes.type,
            latest: true,
            process: async ({ getState, action }, dispatch, done) => {

                const state = getState().permissions;

                const idContexts = Object.keys(state.byIds);
                const codeContexts = Object.keys(state.byCodes);

                idContexts.forEach(context => {
                    const { masterGroupId, collaboratingGroupId, institutionId, patientId } = parseIdContext(context);

                    const permissions: number[] = Object
                        .keys(state.byIds[context])
                        .map(permission => {
                            try {
                                return parseInt(permission);
                            }
                            catch {
                                return undefined as unknown as number;
                            }
                        })
                        .filter(permission => !!permission);

                    dispatch(permissionsActions.loadByIds(
                        masterGroupId,
                        collaboratingGroupId,
                        institutionId,
                        patientId,
                        permissions
                    ));
                });

                codeContexts.forEach(context => {
                    const { masterGroupCode, collaboratingGroupCode, institutionCode, patientStudyNumber } = parseCodeContext(context);

                    const permissions: number[] = Object
                        .keys(state.byCodes[context])
                        .map(permission => {
                            try {
                                return parseInt(permission);
                            }
                            catch {
                                return undefined as unknown as number;
                            }
                        })
                        .filter(permission => !!permission);

                    dispatch(permissionsActions.loadByCodes(
                        masterGroupCode,
                        collaboratingGroupCode,
                        institutionCode,
                        patientStudyNumber,
                        permissions
                    ));
                });

                done();
            }
        })
    }

    return [
        logic.loadByIds,
        logic.loadByCodes
    ]
};

export const usePermissionsSelector: TypedUseSelectorHook<IPermissionsStore> = useSelector;

const successState: IRequestState<ResponseStatus> = {
    state: RequestState.Success
}

export const permissionsSelectors = {
    permissionsByIds: (state: IPermissionsStore, masterGroupId?: number | null, collaboratingGroupId?: number | null, institutionId?: number | null, patientId?: number | null, permissions?: number[]) => {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, institutionId, patientId);

        const permissionState = !state.permissions.byIds[context] ? {} : state.permissions.byIds[context];

        return permissions?.map(permission => permissionState[permission]?.hasPermission ?? false) ?? [];
    },
    loadStateByIds: (state: IPermissionsStore, masterGroupId?: number | null, collaboratingGroupId?: number | null, institutionId?: number | null, patientId?: number | null, permissions?: number[]) => {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, institutionId, patientId);

        const permissionState = !state.permissions.byIds[context] ? {} : state.permissions.byIds[context];

        return (permissions?.map(permission => permissionState[permission]?.loadState ?? initialIndividualPermissionState.loadState) ?? []).reduce((a, b) => a.state < b.state ? a : b, successState);
    },
    permissionsByCodes: (state: IPermissionsStore, masterGroupCode?: string | null, collaboratingGroupCode?: string | null, institutionCode?: string | null, patientStudyNumber?: string | null, permissions?: number[]) => {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, institutionCode, patientStudyNumber);

        const permissionState = !state.permissions.byCodes[context] ? {} : state.permissions.byCodes[context];

        return permissions?.map(permission => permissionState[permission]?.hasPermission ?? false) ?? [];
    },
    loadStateByCodes: (state: IPermissionsStore, masterGroupCode?: string | null, collaboratingGroupCode?: string | null, institutionCode?: string | null, patientStudyNumber?: string | null, permissions?: number[]) => {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, institutionCode, patientStudyNumber);

        const permissionState = !state.permissions.byCodes[context] ? {} : state.permissions.byCodes[context];

        return (permissions?.map(permission => permissionState[permission]?.loadState ?? initialIndividualPermissionState.loadState) ?? []).reduce((a, b) => a.state < b.state ? a : b, successState);
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerPermissionsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPermissionApi(client);

    const logic = createPermissionLogic(api);

    reducerRegistry.register('permissions', permissionsReducer as Reducer, logic as any);
};

export default registerPermissionsReducer;