/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains types to help create and mutate function types.
 * --------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import pluralize from 'pluralize';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
    asyncDebounce,
    IForm,
    IFormContext,
    IFormState,
    IFormLabel,
    IInstitution,
    useAuthenticatedUser,
    useSnackbar,
    InstitutionContext,
    IValidationError,
    ValidationErrorType,
    IPatient,
    IFormValidationError,
    OnlinePatientManagementContext,
    pascalToCameCasePropertyPath,
    SnackbarVariant,
    IDtoClass
} from '@ngt/opms';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext';

/*
* ---------------------------------------------------------------------------------
* Interfaces
* ---------------------------------------------------------------------------------
*/

export interface IUseSaeNotificationFormOptions<Type extends Dtos.SaeNotificationForm> {
    formType?: IDtoClass<Type>;
    afterFormSave?: (sae?: Dtos.Sae<Dtos.ISaeForm, IPatient> | null, form?: Type | null) => void;
    onCancel?: () => void;
    readOnly?: boolean;
}

const errorVariantMapping: Record<ValidationErrorType, SnackbarVariant> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Ineligible]: 'ineligible',
    [ValidationErrorType.Normal]: 'error',
    [ValidationErrorType.StratificationFailure]: 'stratification-failure',
    [ValidationErrorType.Critical]: 'critical'
}

const errorTextMapping: Record<ValidationErrorType, string> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Ineligible]: 'ineligibility warning',
    [ValidationErrorType.Normal]: 'error',
    [ValidationErrorType.StratificationFailure]: 'stratification failure',
    [ValidationErrorType.Critical]: 'critical error'
}

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/

const useSaeNotificationForm = <Type extends Dtos.SaeNotificationForm>({
    formType,
    afterFormSave,
    onCancel,
    readOnly
}: IUseSaeNotificationFormOptions<Type>) => {
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const { institution } = React.useContext(InstitutionContext);

    const { sae, loadState, actions: saeActions } = React.useContext(SaeContext);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const onFormSubmitValidationFailure = React.useCallback(async ({ errors }: IFormState<Type, IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Notification Not Sent
                    </AlertTitle>
                    An error occurred while attempting to validate the form.
                </>,
                { variant: 'critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e.type === ValidationErrorType.Normal) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Notification Not Sent
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit the form again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);

    const onFormSubmitFailure = React.useCallback(async (formState: IFormState<Type, IValidationError>) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    Notification Not Sent
                    </AlertTitle>
                    An error occurred while attempting to send the notification.
            </>,
            { variant: 'critical' }
        );
    }, []);

    const onFormCancel = React.useCallback(() => {
        if (onCancel) {
            onCancel();
        }
        else {
            const institutionCode = institution?.code;

            if (sae) {
                history.push(`/sae/${institutionCode}/${sae?.patient?.studyNumber}/${sae?.form?.saeNumber}`)
            }
            else if (institutionCode) {
                history.push(`/sae/${institutionCode}`)
            }
            else {
                history.push(`/sae`);
            }
        }
    }, [history, sae, institution, onCancel]);

    const onFormSubmit = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<Type, IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'save');
        }
    }, []);

    const onValidate = React.useCallback(async (formState: IFormState<Type, IValidationError>) => {

        // Send request to server.
        const response = await onlinePatientManagement
            .serviceStackClient
            .post(new Dtos.SaeValidateNotificationForm({ form: formState.values }));

        // parse errors into a format the form understands.
        const groupErrors = response.validationResult?.errors?.reduce((a: Record<string, IFormValidationError[]>, b: IFormValidationError) => {
            const propertyName = pascalToCameCasePropertyPath(b.property)

            if (!a[propertyName]) {
                a[propertyName] = [];
            }

            a[propertyName].push(b);

            return a;
        }, {}) ?? {};

        return groupErrors;

    }, [onlinePatientManagement.serviceStackClient, pascalToCameCasePropertyPath]);

    //// debounce validation functions to reduce calls to the server and associate lag.
    const debouncedValidate = React.useMemo(() => {
        return asyncDebounce(onValidate, 500);
    }, [onValidate]);

    const handleSubmit = React.useCallback(async ({ values, errors }: IFormState<Type, IValidationError>) => {
        const { submitType, ...form } = values as any;

        const result = await saeActions?.asyncNotify(values as Type);

        const allErrors = Object
            .keys(errors)
            .reduce((array: IValidationError[], key: string) => {
                const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                    return [...propertyArray, e]
                }, [])

                return [...array, ...propertyErrors]
            }, []);

        const maxErrorType = allErrors.reduce((maxError: ValidationErrorType | undefined, error) => (error.type ?? 0) > (maxError ?? 0) ? error.type : maxError, undefined);

        if (maxErrorType) {

            const scopedErrors = allErrors.filter(e => e.type === maxErrorType);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Notification sent
                    </AlertTitle>
                    The form was successfully saved but contained {scopedErrors.length} {pluralize(errorTextMapping[maxErrorType], scopedErrors.length)}.
                </>,
                { variant: errorVariantMapping[maxErrorType] }
            );
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Notification Sent
                    </AlertTitle>
                    The notification was successfully sent.
                </>,
                { variant: 'success' }
            );
        }

        if (submitType === 'save') {
            if (afterFormSave) {
                afterFormSave(sae, result as unknown as Type);
            }

            history.push(`/sae/${institution?.code}/${sae?.patient?.studyNumber}/${sae?.form?.saeNumber}`)
        }
    }, [saeActions?.asyncNotify, institution, sae, afterFormSave, history])

    const labels: IFormLabel[] = [
        {
            name: 'recipients',
            label: 'Recipients',
            detailedLabel: ''
        },
        {
            name: 'ccRecipients',
            label: 'CC Recipients',
            detailedLabel: ''
        },
        {
            name: 'notes',
            label: 'Notes',
            detailedLabel: ''
        },
        {
            name: 'notifyStudyChair',
            label: 'Notify Study Chair & Deputy Study Chair',
            detailedLabel: ''
        },
        {
            name: 'notifySponsor',
            label: 'Notify Sponsor',
            detailedLabel: ''
        },
        {
            name: 'attachments.name',
            label: 'Name',
            detailedLabel: 'Name'
        },
        {
            name: 'attachments.file',
            label: 'File',
            detailedLabel: 'File'
        }
    ]; 

    return {
        labels,
        handleSubmit,
        onFormCancel,
        onFormSubmit,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate: debouncedValidate
    }
}

/*
* ---------------------------------------------------------------------------------
* Default Exports
* ---------------------------------------------------------------------------------
*/
export default useSaeNotificationForm;

