import React from "react";
import { IRequestState, RequestState } from "@ngt/request-utilities";
import { ResponseStatus } from "@ngt/opms";

export const useLoading = (data: any,
    loadState: IRequestState<ResponseStatus>): boolean => {
    return React.useMemo(() => {
        return data === undefined ?
            loadState.state === RequestState.None || loadState.state === RequestState.Pending :
            false;
    }, [data, loadState]);
}